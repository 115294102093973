import * as React from "react";
import GymRequestForm from "../../components/GymRequestForm/GymRequestForm";
import { Header, Grid, Segment } from "semantic-ui-react";
import { Elements } from "react-stripe-elements";

class GymRequest extends React.Component {
    public render() {
        return (
            <Segment>
                <Header as="h1">Buy an Entralpi system</Header>
                <Grid>
                    <Grid.Column width={16}>
                        <Elements>
                            <GymRequestForm />
                        </Elements>
                    </Grid.Column>
                </Grid>
            </Segment>
        );
    }
}

export default GymRequest;