export interface Workout{
    id:number
    type:string
    subtype:string
    date: string
    title: string
    subtitle: string
    author: string
    description: string
    img: string
    content: WorkoutContent
    tada:any

    getRepByIndex(index:number)
}
export interface WorkoutContent{
    repNb: number
    duration: number
    difficulty: number
    hangboard: string
    type: string
    reps: [Rep]
}
export interface Rep{
    time: number
    intensity?: number
    guideline?: string
    fingers?:[[], []]
    img?: string
    audio?: string
    grip?: number
    expectedStrength: number
}
export class Rep implements Rep{
    constructor(){
        this.time=0;
        this.intensity=0;
        this.guideline='';
        this.fingers =[[],[]];
        this.expectedStrength=0;
    }
}
export class Workout implements Workout{
    constructor(props){
        this.id = props.id;
        this.type = props.type;
        this.subtype = props.subtype;
        this.subtitle = props.subtitle;
        this.title = props.title;
        this.author = props.author;
        this.description = props.description;
        this.img = props.img;
        this.content = props.content;

        this.getRepByIndex=(index:number):Rep=>{
            return this.content.reps[index];
        }
    }
    
}