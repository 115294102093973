import { userConstants } from "../constants/user.constants";
import { HeatmapData, CommonAthleteStatistics, StrengthGraphData } from "common";

export interface UserProfileState {
    requesting: boolean;
    received: boolean;
    failed: boolean;
    firstName: string;
    lastName: string;
    imageUrl: string;
    polarChartData: any;
    strengthGraphData: StrengthGraphData;
    heatmapData: Array<HeatmapData>;
    athleteStatistics: CommonAthleteStatistics;
}

const defaultState: UserProfileState = {
    requesting: false,
    received: false,
    failed: false,
    firstName: "",
    lastName: "",
    imageUrl: "",
    polarChartData: {
        datasets: [{
            data: [1, 1],
            backgroundColor: [],
            label: "My dataset"
        }],
        labels: []
    },
    heatmapData: [],
    athleteStatistics: {
        level: "N/A",
        strength: 0,
        endurance: 0,
        resistance: 0,
        totalTrainingTime: 0
    },
    strengthGraphData: {
        data: []
    }
};

export const userProfile = (state: UserProfileState = defaultState, action: any) => {
    switch (action.type) {
        case userConstants.USER_PROFILE_REQUEST:
            return {
                ...state,
                requesting: true,
                received: false,
                failed: false,
            };

        case userConstants.USER_PROFILE_SUCCESS:
            return {
                ...state,
                requesting: false,
                received: true,
                failed: false,
                firstName: action.data.firstName,
                lastName: action.data.lastName,
                imageUrl: action.data.imageUrl,
                polarChartData: action.data.polarChartData,
                heatmapData: action.data.heatmapData,
                athleteStatistics: action.data.athleteStatistics,
                strengthGraphData: action.data.strengthGraphData
            };

        case userConstants.USER_PROFILE_FAILURE:
            return { state, requesting: false, received: false, failed: true };
        default:
            return { ...state };
    }
};
