import * as React from "react";
import { Card, Header, Form, Divider, Message } from "semantic-ui-react";
import { api } from "../../config/axiosConfig";
import { withRouter, RouteComponentProps } from "react-router";

interface ForgotPasswordFormState {
    email: string;
    requesting: boolean;
    success: boolean;
    errors: any;
}

class ForgotPasswordForm extends React.Component<RouteComponentProps<void>, ForgotPasswordFormState> {
    constructor(props: RouteComponentProps<void>) {
        super(props);
        this.state = {
            email: "",
            requesting: false,
            success: false,
            errors: {}
        };
    }

    public handleInputChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { name, value } = event.target as HTMLInputElement;
        this.setState({ ...this.state, [name]: value });
    }

    public handleSubmit = async () => {
        if (!this.state.requesting) {
            const requestURL = "/v1/auth/password/recovery";
            this.setState({ requesting: true });
            await api()
                .post(requestURL, { email: this.state.email })
                .then(response => {
                    this.setState({ requesting: false, success: true, errors: {} });
                    setTimeout(this.goBacktoLogin, 3000);
                })
                .catch(error => {
                    this.setState({ requesting: false, success: false, errors: error.response.data.errors });
                });
        }
    }

    public render() {
        return (
            <Card centered={true} className="signup-panel">
                <Card.Content>
                    <Header as="h1" textAlign="center">Recover Your Account</Header>
                    <Divider hidden={true} />
                    <Form onSubmit={this.handleSubmit} success={this.state.success}>
                        <Form.Input
                            name="email"
                            label="Email address"
                            placeholder="Email Address"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            error={"email" in this.state.errors}
                        />
                        <Form.Button>Submit</Form.Button>
                        <Message
                            success={true}
                            header="Request Completed!"
                            content="An email have been sent to your address!"
                        />
                    </Form>
                </Card.Content>
            </Card>
        );
    }

    private goBacktoLogin = () => {
        this.props.history.push("/login");
    }
}

export default withRouter(ForgotPasswordForm);