import { adminConstants } from "./../../../constants/admin.constants";
import { capitalize } from "lodash";

export interface AddAdminState { 
    requesting: boolean;
    received: boolean;
    added: boolean;
    error: string;
}

const defaultState = {
    requesting: false,
    received: false,
    added: false,
    error: "",
};

export const addAdmin = (state: AddAdminState = defaultState, action: any) => {
    switch (action.type) {
        case adminConstants.ADD_ADMIN_REQUEST:
            return {...state, requesting: true, added: false};
        case adminConstants.ADD_ADMIN_SUCCESS:
            return {
                ...state,
                requesting: false,
                added: true,
                error: "",
            };
        case adminConstants.ADD_ADMIN_FAILURE:
            return {
                ...state,
                requesting: false,
                added: false,
                error: "E-mail is not associated to a user",
            };
        case adminConstants.ADD_ADMIN_ERROR:
            return {
                ...state,
                error: capitalize(action.error),
            };
        case adminConstants.RESET_ADD_ADMIN:
            return {
                requesting: false,
                received: false,
                added: false,
                error: "",
            };
        default:
            return {...state};
    }
};
