import * as React from "react";
import { Form, Header, Button, Card, Divider } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import { expect, validateForm } from "../../utils/Validator";
import { WithTranslation, withTranslation } from 'react-i18next';

// Redux
import { connect } from "react-redux";
import {Dispatch} from "redux"
import { AuthenticationState } from "../../reducers/authentication.reducer";
import { State } from "../../reducers";
import { updateSignUpErrors, SignUpFormErrors } from "../../actions/signup.actions";
import { api } from "../../config/axiosConfig";
import "./ConfirmEmailPanel.css";


interface ConfirmEmailPanelProps extends WithTranslation{
    dispatch: Dispatch<void>;
    authentication: AuthenticationState;
    errors: SignUpFormErrors;
}

const mapStateToProps = (state: State) => {
    return {
        authentication: state.authentication,
    };
};

class ConfirmEmailPanel extends React.Component<ConfirmEmailPanelProps> {


    private validations = [
        expect("email").required().isEmail(),
    ];

    // Constructor
    constructor(props: ConfirmEmailPanelProps) {
        super(props);
        this.state = { 
            email: "",
            errors: null,
            emailSent:false
        };
    }

    // Functions
    public handleInputChange = (event: any) => {
        const {name, value} = event.target as HTMLInputElement;
        this.setState({...this.state, [name]: value});
    }

    public handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        // Validate field
        const errors = validateForm(this.state, this.validations);
        this.setState({errors: errors.email});

        const requestURL = '/v1/auth/email/confirm';
        if (isEmpty(errors)&& !this.state.emailSent){
            api()
            .post(requestURL, {email: this.state.email})
            .then(()=>{
                window.localStorage.setItem('emailForSignIn', this.state.email);
                this.setState({emailSent: true});
            })
            .catch((error) => { this.setState({errors: error.response.data}); });
        }
    }
    emailSentMessage = () => {
        if (this.state.emailSent) return (
            <div className='ui positive message'>
                <div className="header">{this.props.t('signUp.emailSent')}</div>
            </div>
        )
    }
    public render() {
        // Redirect if already logged in
        if (this.props.authentication.login) {
            return <Redirect to="/"/>;
        }
        return (
            <Card centered={true} className="confirm-email-panel">
                <Card.Content>
                    <Header as="h1" textAlign="center">{this.props.t('signUp.header')}</Header>
                    <Divider hidden={true}/>
                    { this.emailSentToast}
                    <Form>
                        {this.emailSentMessage()}
                        <Form.Input
                            name="email"
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.handleInputChange}
                            error={this.state.errors}
                        />
                        <Button type="submit" fluid={true} onClick={this.handleSubmit}>{this.props.t('signUp.header')}</Button>
                    </Form>
                    <Divider/>
                    <p>{this.props.t('signUp.alreadyHaveAccountQuestion')} <Link to="/login">{this.props.t('signUp.login')}</Link></p>
                    <p>{this.props.t('signUp.byClickingYouAccept')} <Link to="/privacy">{this.props.t('signUp.privacyPolicyLink')}</Link>.</p>
                </Card.Content>
            </Card>
        );
    }
}

export default withTranslation('account')(connect(mapStateToProps)(ConfirmEmailPanel));
