import * as React from "react";
import { Dimmer, Loader } from "semantic-ui-react";

class LoadingOverlay extends React.Component {

    public render() {
        return (
            <React.Fragment>
                <Dimmer active={true} inverted={true}>
                    <Loader size="large">Loading</Loader>
                </Dimmer>
            </React.Fragment>
        );
    }
}

export default LoadingOverlay;
