export const adminConstants = {
    // Admin managements constants
    ADMIN_LIST_REQUEST: "ADMIN_LIST_REQUEST",
    ADMIN_LIST_SUCCESS: "ADMIN_LIST_SUCCESS",
    ADMIN_LIST_FAILURE: "ADMIN_LIST_FAILURE",

    REMOVE_ADMIN_REQUEST: "REMOVE_ADMIN_REQUEST",
    REMOVE_ADMIN_SUCCESS: "REMOVE_ADMIN_SUCCESS",
    REMOVE_ADMIN_FAILURE: "REMOVE_ADMIN_FAILURE",

    ADD_ADMIN_REQUEST: "ADD_ADMIN_REQUEST",
    ADD_ADMIN_SUCCESS: "ADD_ADMIN_SUCCESS",
    ADD_ADMIN_FAILURE: "ADD_ADMIN_FAILURE",
    ADD_ADMIN_ERROR: "ADD_ADMIN_ERROR",
    RESET_ADD_ADMIN: "RESET_ADD_ADMIN",

    ORDERS_REQUEST: "ADMIN_ORDERS_REQUEST",
    ORDERS_SUCCESS: "ADMIN_ORDERS_SUCCESS",
    ORDERS_FAILURE: "ADMIN_ORDERS_FAILURE",

    UPDATE_ORDER_STATUS_REQUEST: "UPDATE_ORDER_STATUS_REQUEST",
    UPDATE_ORDER_STATUS_SUCCESS: "UPDATE_ORDER_STATUS_SUCCESS",
    UPDATE_ORDER_STATUS_FAILURE: "UPDATE_ORDER_STATUS_FAILURE",

    SELECT_ORDER: "ADMIN_SELECT_ORDER",
    OPEN_STATUS_CONFIRMATION_MODAL: "OPEN_STATUS_CONFIRMATION_MODAL",
    CLOSE_STATUS_CONFIRMATION_MODAL: "CLOSE_STATUS_CONFIRMATION_MODAL"
};