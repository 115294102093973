import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Container, Header, Segment } from "semantic-ui-react";
import ViewSettingsAccount from "../../containers/SettingsAccount/ViewSettingsAccount";
import EditSettingsAccount from "../../containers/SettingsAccount/EditSettingsAccount";
import EditableSection from "../EditableSection/EditableSection";
import { WithTranslation, withTranslation } from "react-i18next";

interface SettingsAccountState {
    editingDisplaySettings: boolean;
}
interface SettingsAccountProps extends RouteComponentProps<void>, WithTranslation{}
class SettingsAccount extends React.Component<SettingsAccountProps, SettingsAccountState> {
    constructor(props: any) {
        super(props);
        this.state = { editingDisplaySettings: false };
    }

    render() {
        const editingDisplaySettings = this.state.editingDisplaySettings;
        return (
            <Segment>
                <EditableSection editing={editingDisplaySettings} onClick={this.editButtonCallback} iconOffset={{right: 0, top: 8}}>
                    <EditableSection.Content>
                        <Container>
                            <Header as="h1">{this.props.t('account.header')}</Header>
                            <ViewSettingsAccount />
                        </Container>
                    </EditableSection.Content>
                    <EditableSection.Form>
                        <Container>
                            <Header as="h1">{this.props.t('account.header')}</Header>
                            <EditSettingsAccount endEditingCallback={this.endEditingCallback} />
                        </Container>
                    </EditableSection.Form>
                </EditableSection >
            </Segment>
        );
    }

    editButtonCallback = (e: Event) => {
        this.setState({ editingDisplaySettings: !this.state.editingDisplaySettings });
    }

    endEditingCallback = () => {
        this.setState({ editingDisplaySettings: false });
    }
}

export default withTranslation('settings')(SettingsAccount);