import React from 'react';

interface HandsProps {
    height: number;
    fingers: number[][];
}

class Hands extends React.Component<HandsProps> {

    render() {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={`${(this.props.height) * 12 / 7}px`} height={`${(this.props.height)}px`} fill="none" version="1.1" viewBox="0 0 226.77 132.28">
                <defs>
                    <filter id="filter0_d-1" x=".86841" y=".5" width="103" height="129" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                    <filter id="filter0_d-7-3" x=".87275" y=".5" width="103" height="129" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow"/>
                        <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                    </filter>
                </defs>
                <g transform="translate(-.38957 -29.182)">
                    <g transform="translate(6.5998,32.052)" filter="url(#filter0_d-1)">
                        <path d="m74.609 74.614v-1.086c-0.0596-18.496-0.1257-36.992-0.1985-55.488-0.0119-2.0311-0.0456-4.08-1.5539-5.7855-1.8396-2.0747-4.1675-2.6902-6.7176-2.2197-2.6573 0.4904-4.4275 2.2813-5.0903 4.8504-0.3969 1.5049-0.2778 3.1548-0.2818 4.7391-0.0298 10.39-0.0258 20.779-0.0456 31.171 0.0112 0.5258-0.0557 1.0504-0.1985 1.5566-0.3433 1.0959-1.4328 1.8027-2.4152 1.6518-1.3197-0.1985-2.1393-1.0979-2.1433-2.3825-0.0582-14.593-0.1118-29.185-0.1607-43.776-0.0159-4.1832-2.2108-6.7007-6.3624-7.2963-3.0264-0.45466-6.6759 2.3249-7.0391 5.3427-0.0773 0.65896-0.1158 1.3219-0.1151 1.9854-0.0754 14.525-0.1416 29.05-0.1985 43.573 0 1.1019-0.4862 1.8385-1.3891 2.305-0.8673 0.4468-1.8298 0.3832-2.4648-0.3355-0.4624-0.5202-0.7065-1.3004-0.8613-2.0052-0.1449-0.6651-0.0516-1.3898-0.0556-2.0787-0.0535-12.341-0.2341-24.684-0.0932-37.024 0.0476-4.2765-3.858-7.743-8.1108-6.9707-2.2028 0.39708-4.6974 2.3388-5.1598 4.3857-0.2194 1.0671-0.3232 2.1548-0.3096 3.2441-0.0873 13.6-0.1614 27.199-0.2222 40.798 0 0.9927-0.0238 1.9854-0.127 2.9781-0.1151 1.098-0.8454 1.7869-1.8377 1.8762-1.2344 0.1132-2.2723-0.4665-2.57-1.5386-0.1506-0.6084-0.2213-1.2337-0.2103-1.8604-0.0119-9.5299-0.0119-19.06 0-28.59 0-3.1131-1.2681-5.4955-4.0683-6.792-0.8305-0.3959-1.7329-0.6184-2.6522-0.654s-1.8362 0.1165-2.6948 0.447c-0.85862 0.3305-1.641 0.8324-2.2993 1.4753-0.65837 0.6428-1.179 1.413-1.5301 2.2637-0.35126 0.818-0.4346 1.8047-0.4346 2.7141-0.02382 21.804-0.33738 43.615 0.10715 65.411 0.25006 12.224 7.3308 24.524 22.598 28.435 5.3522 1.348 10.886 1.83 16.39 1.427 4.9891-0.333 9.871-1.147 14.574-2.873 7.2356-2.652 13.068-7.191 17.351-13.56 7.4519-11.112 14.759-22.316 22.128-33.478 1.7126-2.5969 2.1413-5.3745 1.3316-8.4022-0.7204-2.7021-4.2926-5.3209-7.0471-5.3229-3.4253 0-5.9933 1.3025-7.9738 3.9708-3.0006 4.0284-5.9675 8.0786-8.9502 12.111-0.2263 0.3058-0.4565 0.6096-0.6847 0.9133z" fill="#fff"/>
                    </g>
                    <g transform="translate(115.25,32.052)" fill="none">
                        <path d="m30.136 74.614v-1.086c0.0596-18.496 0.1257-36.992 0.1985-55.488 0.0119-2.0311 0.0456-4.08 1.5539-5.7855 1.8396-2.0747 4.1675-2.6902 6.7176-2.2197 2.6573 0.4904 4.4275 2.2813 5.0903 4.8504 0.3969 1.5049 0.2778 3.1548 0.2818 4.7391 0.0298 10.39 0.0258 20.779 0.0456 31.171-0.0112 0.5258 0.0557 1.0504 0.1985 1.5566 0.3433 1.0959 1.4328 1.8027 2.4152 1.6518 1.3197-0.1985 2.1393-1.0979 2.1433-2.3825 0.0582-14.593 0.1117-29.185 0.1607-43.776 0.0159-4.1832 2.2108-6.7007 6.3624-7.2963 3.0264-0.45466 6.6759 2.3249 7.0391 5.3427 0.0773 0.65896 0.1158 1.3219 0.1151 1.9854 0.0754 14.525 0.1416 29.05 0.1985 43.573 0 1.1019 0.4862 1.8385 1.3891 2.305 0.8673 0.4468 1.8298 0.3832 2.4648-0.3355 0.4624-0.5202 0.7065-1.3004 0.8613-2.0052 0.1449-0.6651 0.0516-1.3898 0.0556-2.0787 0.0535-12.341 0.2341-24.684 0.0932-37.024-0.0476-4.2765 3.858-7.743 8.1108-6.9707 2.2028 0.39708 4.6974 2.3388 5.1598 4.3857 0.2194 1.0671 0.3232 2.1548 0.3096 3.2441 0.0873 13.6 0.1614 27.199 0.2222 40.798 0 0.9927 0.0238 1.9854 0.127 2.9781 0.1151 1.098 0.8454 1.7869 1.8377 1.8762 1.2344 0.1132 2.2723-0.4665 2.57-1.5386 0.1506-0.6084 0.2213-1.2337 0.2103-1.8604 0.0119-9.5299 0.0119-19.06 0-28.59 0-3.1131 1.2681-5.4955 4.0683-6.792 0.8305-0.3959 1.7329-0.6184 2.6522-0.654s1.8362 0.1165 2.6948 0.447 1.6409 0.8324 2.2993 1.4753c0.6584 0.6428 1.179 1.413 1.5302 2.2637 0.3512 0.818 0.4346 1.8047 0.4346 2.7141 0.0238 21.804 0.3374 43.615-0.1072 65.411-0.25 12.224-7.3308 24.524-22.598 28.435-5.3522 1.348-10.886 1.83-16.39 1.427-4.9891-0.333-9.871-1.147-14.574-2.873-7.2356-2.652-13.068-7.191-17.351-13.56-7.4519-11.112-14.759-22.316-22.127-33.478-1.7126-2.5969-2.1413-5.3745-1.3316-8.4022 0.72038-2.7021 4.2925-5.3209 7.0471-5.3229 3.4253 0 5.9933 1.3025 7.9738 3.9708 3.0006 4.0284 5.9675 8.0786 8.9502 12.111 0.2263 0.3058 0.4565 0.6096 0.6847 0.9133z" fill="#fff" filter="url(#filter0_d-7-3)"/>
                    </g>
                </g>
                <g fill="#e74c3c" fill-rule="evenodd">
                    <path d={this.props.fingers[0][0] ? "m19.08 63.619c1.0992-0.11319 2.1459-0.33172 3.082-0.6435 0.91576-0.30502 1.4284-0.52236 2.5127-1.0654l0.9574-0.47948-0.0148-10.761c-0.0159-11.482-0.0708-19.646-0.1438-21.375-0.11015-2.6089-0.28369-3.7221-0.7082-4.5429-0.22479-0.43462-0.74352-1.1438-1.0935-1.4951-1.1116-1.1155-2.9441-2.0605-4.5604-2.3519-1.8306-0.32997-3.721 0.05907-5.4297 1.1174-1.4144 0.87605-2.7466 2.5014-2.9332 3.5787-0.17005 0.98187-0.23285 3.141-0.29761 10.232-0.14281 15.639 0.41057 24.684 0.55279 25.878 0.0819 0.68833 0 0 0 0 1.07 0.6533 2.9838 1.3447 4.5691 1.6505 1.3226 0.2552 2.6076 0.34917 3.5076 0.25648z" : null} stroke-width=".044029"/>
                    <path d={this.props.fingers[0][1] ? "m37.979 60.476c2.1763-0.43271 4.1108-1.412 5.6856-2.8781l1.2898-1.2009-0.1073-12.849c-0.0591-7.0671-0.108-17.01-0.10892-22.096l-1e-3 -9.2467-0.73487-1.3696c-1.9267-3.591-6.4181-5.2474-10.041-3.7033-1.7144 0.73066-3.1472 1.8914-3.8621 3.1288-0.55088 0.9535-0.57355 1.3234-0.74484 12.151-0.0972 6.1424-0.18879 16.921-0.20361 23.952l-0.027 12.784 1.0414 0.48626c1.2592 0.58794 3.9961 1.2034 5.2566 1.1821 0.50515-0.0084 1.6564-0.16226 2.5583-0.34157z" : null} stroke-width=".25105"/>
                    <path d={this.props.fingers[0][2] ? "m56.887 58.52c1.6317-0.33768 3.4734-1.0848 4.7502-1.927l1.196-0.78893-0.17188-24.166c-0.19303-27.138-0.0957-25.79-2.0081-27.829-1.4999-1.5994-3.1963-2.3156-5.5198-2.3304-2.085-0.013302-3.5655 0.58162-5.0939 2.047-1.9349 1.8551-1.9014 1.6652-2.0784 11.752-0.0869 4.9536-0.17148 16.066-0.18789 24.694-0.019 10.042-0.12084 15.85-0.28275 16.14-0.21547 0.386-0.0949 0.54041 0.81415 1.043 0.58689 0.32448 1.6279 0.76893 2.3134 0.98767 1.4691 0.46882 4.8471 0.6728 6.2689 0.37856z" : null} stroke-width=".24528"/>
                    <path d={this.props.fingers[0][3] ? "m75.952 59.523c1.5652-0.35315 3.7906-1.3666 4.8051-2.1882l0.64534-20.701-0.0717-20.12-0.59408-1.2009c-0.75488-1.5259-1.8988-2.6362-3.5365-3.4326-1.0775-0.52396-1.6321-0.6279-3.3561-0.6289-3.3535-0.0019-5.4399 1.2034-6.8198 3.9399l-0.66682 1.3224-0.12909 19.574c-0.10908 16.542-0.18762 19.681-0.50703 20.263-0.35589 0.64847-0.34096 0.71656 0.2557 1.166 2.563 1.9308 6.6589 2.7548 9.9749 2.0066z" : null} stroke-width=".249"/>
                    <path d={this.props.fingers[0][4] ? "m95.824 88.732c0.6208-0.92779 3.5542-5.2293 6.5187-9.559 2.9645-4.3296 5.5994-8.3219 5.8554-8.8717 1.1894-2.5543 1.1686-6.1316-0.049-8.4311-0.71775-1.3555-2.4113-2.857-4.241-3.7601-1.3086-0.6459-1.8606-0.77194-3.3798-0.77162-2.3316 4.7e-4 -4.3189 0.57942-6.1025 1.7777-1.1644 0.78235-2.5275 2.3916-7.5754 8.9435l-6.1554 7.9895 1e-3 1.6244c2e-3 3.2461 1.2089 6.0988 3.6372 8.5998 1.8218 1.8763 4.3423 3.2528 6.9197 3.779 3.1492 0.64295 3.2832 0.60424 4.571-1.3203z" : null} stroke-width=".25461"/>
                    <path d={this.props.fingers[1][0] ? "m130.18 88.732c-0.61463-0.92779-3.5188-5.2293-6.4538-9.559-2.935-4.3296-5.5437-8.3219-5.7971-8.8717-1.1776-2.5543-1.157-6.1316 0.0485-8.4311 0.71061-1.3555 2.3873-2.857 4.1988-3.7601 1.2955-0.6459 1.8421-0.77195 3.3462-0.77164 2.3084 4.81e-4 4.276 0.57942 6.0418 1.7778 1.1528 0.78235 2.5024 2.3916 7.5 8.9435l6.0942 7.9895-1e-3 1.6244c-2e-3 3.2461-1.1968 6.0988-3.601 8.5998-1.8037 1.8763-4.2991 3.2528-6.8509 3.779-3.1179 0.64295-3.2505 0.60424-4.5255-1.3203z" : null} stroke-width=".25334"/>
                    <path d={this.props.fingers[1][1] ? "m149.7 59.523c-1.5652-0.35314-3.7906-1.3666-4.8051-2.1882l-0.64534-20.701 0.0717-20.12 0.59408-1.2009c0.75488-1.5259 1.8988-2.6362 3.5365-3.4326 1.0775-0.52396 1.6321-0.6279 3.3561-0.6289 3.3535-0.0019 5.4399 1.2034 6.8198 3.9399l0.66682 1.3224 0.12909 19.574c0.10908 16.542 0.18762 19.681 0.50703 20.263 0.35589 0.64847 0.34096 0.71657-0.2557 1.166-2.563 1.9308-6.6589 2.7548-9.9749 2.0066z" : null} stroke-width=".249"/>
                    <path d={this.props.fingers[1][2] ? "m168.77 58.52c-1.6317-0.33768-3.4734-1.0848-4.7502-1.927l-1.196-0.78893 0.17188-24.166c0.19303-27.138 0.0957-25.79 2.0081-27.829 1.4999-1.5994 3.1963-2.3156 5.5198-2.3304 2.085-0.013302 3.5655 0.58162 5.0939 2.047 1.9349 1.8551 1.9014 1.6652 2.0784 11.752 0.0869 4.9536 0.17148 16.066 0.18789 24.694 0.019 10.042 0.12084 15.85 0.28275 16.14 0.21547 0.38601 0.0949 0.54042-0.81416 1.043-0.58688 0.32448-1.6279 0.76892-2.3134 0.98767-1.4691 0.46882-4.8471 0.6728-6.2689 0.37856z" : null} stroke-width=".24528"/>
                    <path d={this.props.fingers[1][3] ? "m187.68 60.475c-2.1763-0.4327-4.1108-1.412-5.6856-2.8781l-1.2898-1.2009 0.1073-12.849c0.0591-7.0671 0.108-17.01 0.10892-22.096l1e-3 -9.2467 0.73487-1.3696c1.9267-3.591 6.4181-5.2474 10.041-3.7033 1.7144 0.73066 3.1472 1.8914 3.8621 3.1288 0.55088 0.9535 0.57355 1.3234 0.74485 12.151 0.0972 6.1424 0.18878 16.921 0.2036 23.952l0.027 12.784-1.0414 0.48625c-1.2592 0.58795-3.9961 1.2034-5.2566 1.1821-0.50515-0.0084-1.6564-0.16226-2.5582-0.34158z" : null} stroke-width=".25105"/>
                    <path d={this.props.fingers[1][4] ? "m206.57 63.619c-1.0992-0.1132-2.1459-0.33174-3.082-0.64351-0.91576-0.305-1.4284-0.52236-2.5127-1.0654l-0.9574-0.47948 0.0148-10.761c0.0159-11.482 0.0708-19.646 0.1438-21.375 0.11015-2.6089 0.28368-3.7221 0.7082-4.5429 0.22479-0.43463 0.74353-1.1438 1.0935-1.4951 1.1116-1.1155 2.9441-2.0605 4.5604-2.3519 1.8306-0.32998 3.721 0.05907 5.4297 1.1174 1.4144 0.87605 2.7466 2.5014 2.9332 3.5787 0.17005 0.98187 0.23285 3.141 0.29761 10.232 0.14281 15.639-0.29595 24.613-0.43817 25.806l-0.11462 0.0718c-1.0624 0.6655-2.9838 1.3447-4.5691 1.6505-1.3226 0.2552-2.6076 0.34916-3.5076 0.25648z" : null} stroke-width=".044029"/>
                </g>
            </svg>
        );
    }
}

export default Hands;

