import * as React from "react";
import { Omit } from "common";
import Formatter from "../../utils/Formatter";

interface PhoneInputProps extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "onChange"> {
    value?: string;
    onChange?(event: React.ChangeEvent<HTMLInputElement>, value: string): void;
}

interface PhoneInputState {
    value: string;
}

class PhoneInput extends React.Component<PhoneInputProps, PhoneInputState> {
    constructor(props: PhoneInputProps) {
        super(props);
        const value = props.value || "";
        this.state = {
            value: Formatter.formatPhone(value)
        };
    }

    public componentWillReceiveProps(nextProps: PhoneInputProps) {
        if (this.props.value !== nextProps.value) {
            const value = nextProps.value || "";
            this.setState({ value: Formatter.formatPhone(value) });
        }
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value: string = event.target.value.replace(/[ \D()]/g, "").slice(0, 10);
        let formattedValue = Formatter.formatPhone(value);
        event.target.value = value;

        this.setState({ value: formattedValue });

        if (this.props.onChange && this.state.value !== formattedValue) {
            this.props.onChange(event, value);
        }
    }

    public render() {
        const { onChange, ...props } = this.props;
        return <input {...props} onChange={this.handleChange} value={this.state.value} />;
    }
}

export default PhoneInput;