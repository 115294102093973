import * as React from "react";
import { Menu } from "semantic-ui-react";
import SearchBar from "../../../containers/SearchBar/SearchBar";
import { Dispatch } from "redux";
import { navBarConstants } from "../../../constants/navBar.constants";

interface MobileTopNavBarProps {
    dispatch: Dispatch<void>;
}
export class MobileTopNavBar extends React.Component<MobileTopNavBarProps> {
    toggleNavBar = () => {
        this.props.dispatch({ type: navBarConstants.NAV_BAR_TOGGLE_SIDEBAR });
    }
    render() {
        return (
            <Menu
                animation="overlay"
                inverted={true}
                vertical={false}
                borderless={true}
                fixed="top"
                compact={true}
                className="top-nav-bar-menu"
            >
                <Menu.Item icon="bars" onClick={this.toggleNavBar} />
                <Menu.Menu position="right">
                    <Menu.Item   >
                        <SearchBar />
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
        );
    }
}