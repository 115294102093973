import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Table, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { archiveActivity } from "../../actions/activities.actions";
import { withRouter, Link } from "react-router-dom";
import "./ActivityTableRow.css";

interface ActivityTableRowProps extends RouteComponentProps<void> {
    activity: any;
    showActions?: boolean;
    dispatch: Dispatch<void>;
}

class ActivityTableRow extends React.Component<ActivityTableRowProps, {}> {
    private WeekDay: Array<string> = [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
    ];

    private PreventNavigate: boolean = false;

    public render() {
        const { date, type, title, tag } = this.props.activity;
        return (
            <Table.Row className="hoverable clickable" onClick={this.navigate}>
                <Table.Cell>{this.formatDate(date)}</Table.Cell>
                <Table.Cell>{type}</Table.Cell>
                <Table.Cell>{title}</Table.Cell>
                <Table.Cell>{tag}</Table.Cell>
                {this.showActions()}
            </Table.Row>
        );
    }

    private navigate = (event) => {
        if (!this.PreventNavigate) {
            if(event.ctrlKey)
                window.open(`/activities/${this.props.activity.id}`, '_blank');
            else
                this.props.history.push(`/activities/${this.props.activity.id}`);
        }
        this.PreventNavigate = false;
    }

    private onArchive = () => {
        this.props.dispatch(archiveActivity(this.props.activity.id));
        // Clicking the Archive button also clicks on the row,
        // which triggers a navigate we do not want at this time.
        this.PreventNavigate = true;
    }

    private formatDate(date: string): string {
        let formattedDate = new Date(date);
        return `${this.WeekDay[formattedDate.getDay()]}, ${formattedDate.toLocaleDateString()}`;
    }

    private showActions() {
        const actions = (
            <Table.Cell>
                <Button size="tiny" content="Archive" onClick={this.onArchive} />
            </Table.Cell>
        );
        return this.props.showActions ? actions : null;
    }
}

export default connect()(withRouter(ActivityTableRow));
