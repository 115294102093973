import * as React from "react";
import { CommonOrder } from "common";
import { Table } from "semantic-ui-react";
import OrderTableRow from "./OrderTableRow";

interface OrderTableProps {
    orders: CommonOrder[];
    editableStatus?: boolean;
}

class OrderTable extends React.Component<OrderTableProps> {
    public render() {
        const rows = this.props.orders.map(
            (order) => <OrderTableRow key={order.id} order={order} editableStatus={this.props.editableStatus}/>
        );
        return (
            <Table color="orange" size="small" singleLine={true} unstackable={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Creation Date</Table.HeaderCell>
                        <Table.HeaderCell>Gym Name</Table.HeaderCell>
                        <Table.HeaderCell>Phone</Table.HeaderCell>
                        <Table.HeaderCell>Address</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Count</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows}
                </Table.Body>
            </Table>
        );
    }
}

export default OrderTable;