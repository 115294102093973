import * as React from "react";
import { Form, Header, Button, Card, Divider } from "semantic-ui-react";
import { Link, Redirect } from "react-router-dom";
import { isEmpty } from "lodash";
import { expect, validateForm } from "../../utils/Validator";
import firebase from 'firebase';
import { WithTranslation, withTranslation } from 'react-i18next';

// Redux
import { connect } from "react-redux";
import {Dispatch} from "redux"
import { AuthenticationState } from "../../reducers/authentication.reducer";
import { State } from "../../reducers";
import { signup, SignUpInfos } from "../../actions/user.actions";
import { updateSignUpErrors, SignUpFormErrors } from "../../actions/signup.actions";

interface SignupPanelProps extends WithTranslation {
    dispatch: Dispatch<void>;
    authentication: AuthenticationState;
    errors: SignUpFormErrors;
}

const mapStateToProps = (state: State) => {
    return {
        authentication: state.authentication,
        errors: state.signup.errors
    };
};

class SignUpPanel extends React.Component<SignupPanelProps, SignUpInfos> {

    // Properties
    /*private selectOptions = [
        { key: "m", text: "Male", value: "Male"},
        { key: "f", text: "Female", value: "Female"},
        { key: "o", text: "Other", value: "Other"}
    ];*/

    private validations = [
        expect("email").required().isEmail(),
        expect("password").required(),
        expect("firstName").required(),
        expect("lastName").required(),
    ];

    // Constructor
    constructor(props: SignupPanelProps) {
        super(props);
        this.state = { email: "", password: "", firstName: "", lastName: "", comeFromEmail: false};
    }

    componentDidMount(){
        if (firebase.auth().isSignInWithEmailLink(window.location.href)){
            this.setState({
                email : window.localStorage.getItem('emailForSignIn'),
                comeFromEmail: true
            });
        }
    }
    async verifyUserEmail(){
        return firebase.auth().signInWithEmailLink(this.state.email, window.location.href)
            .then(function () {
                // Clear email from storage.
                window.localStorage.removeItem('emailForSignIn');
                return null;
            })
            .catch(function (error) {
                console.log(error.message);
                return error.message;
            });
    }
    // Functions
    public handleInputChange = (event: any) => {
        const {name, value} = event.target as HTMLInputElement;
        this.setState({...this.state, [name]: value});
    }

    public handleSubmit = async(event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        let errors = validateForm(this.state, this.validations);
        const emailError = await this.verifyUserEmail();
        errors = !emailError ? errors : {
            ...errors,
            email: emailError
        }
        this.props.dispatch(updateSignUpErrors(errors));
        if (isEmpty(errors) && this.state.comeFromEmail) {
            // Send Request
            const credentials: SignUpInfos = this.state;
            this.props.dispatch(signup(credentials));
        }
    }
    unauthaurizedAccess=()=>{
        return(
        <div>
            <h1>{this.props.t('signUp.error')}</h1>
            <p>{this.props.t('signUp.errorReason')}</p>
        </div>
        );
    }
    authorizedAccess = () => {
        const errors = this.props.errors;
        return (
            <Form>
                <Form.Group widths="equal">
                    <Form.Input
                        name="firstName"
                        placeholder={this.props.t('signUp.firstName')}
                        value={this.state.firstName}
                        onChange={this.handleInputChange}
                        width={8}
                        error={"firstName" in errors}
                    />
                    <Form.Input
                        name="lastName"
                        placeholder={this.props.t('signUp.lastName')}
                        value={this.state.lastName}
                        onChange={this.handleInputChange}
                        width={8}
                        error={"lastName" in errors}
                    />
                </Form.Group>
                <Form.Input
                    name="email"
                    placeholder={this.props.t('login.email')}
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    error={"email" in errors}
                />
                <Form.Input
                    name="password"
                    type="password"
                    placeholder={this.props.t('login.password')}
                    value={this.state.password}
                    onChange={this.handleInputChange}
                    error={"password" in errors}
                />
                <Button type="submit" fluid={true} onClick={this.handleSubmit}>{this.props.t('login.signUp')}</Button>
            </Form>
        )
    }
    public render() {
        // Redirect if already logged in
        if (this.props.authentication.login) {
            return <Redirect to="/"/>;
        }

        return (
            <Card centered={true} className="signup-panel">
                <Card.Content>
                    <Header as="h1" textAlign="center">{this.props.t('signUp.header')}</Header>
                    <Divider hidden={true}/>
                        {this.state.comeFromEmail?
                            <this.authorizedAccess/>:
                            <this.unauthaurizedAccess/>}
                    <Divider/>
                    <p>{this.props.t('signUp.alreadyHaveAccountQuestion')} <Link to="/login">{this.props.t('signUp.login')}</Link></p>
                </Card.Content>
            </Card>
        );
    }
}

export default withTranslation('account')(connect(mapStateToProps)(SignUpPanel));
