import { api } from "../config/axiosConfig";
import { CreateOrderBody, OrderStatus, GetOrdersQuery } from "common";

export class OrderService {

    public static create = (gymId: number, count: number) => {
        let requestURL = "/v1/orders/";
        const body: CreateOrderBody = { gymId, count };
        return api().post(requestURL, body);
    }
    
    public static getMany = (query?: GetOrdersQuery) => {
        let requestURL = "/v1/orders";
        return api().get(requestURL, { params: query });
    }
    
    public static updateStatus = (id: number, status: OrderStatus) => {
        let requestURL = `/v1/orders/${id}/status`;
        return api().patch(requestURL, { status });
    }

    public static remove = (id: number) => {
        let requestURL = `/v1/orders/${id}`;
        return api().delete(requestURL);
    }
}