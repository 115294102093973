import { CommonOrder, OrderStatus } from "common";
import { adminConstants } from "../../constants/admin.constants";
import { cloneDeep } from "lodash";

export interface AdminOrdersState {
    requesting: boolean;
    success: boolean;
    selectedOrder: CommonOrder | null;
    isModalOpen: boolean;
    awaitingOrders: CommonOrder[];
    doneOrders: CommonOrder[];
}

const defaultState = {
    requesting: false,
    success: false,
    selectedOrder: null,
    isModalOpen: false,
    awaitingOrders: [],
    doneOrders: [],
};

export const orders = (state: AdminOrdersState = defaultState, action: any) => {
    switch (action.type) {
        case adminConstants.ORDERS_REQUEST:
            return { ...state, requesting: true };
        case adminConstants.ORDERS_SUCCESS:
            return {
                ...state,
                requesting: false,
                success: true,
                awaitingOrders: action.data.awaitingOrders,
                doneOrders: action.data.doneOrders,
            };
        case adminConstants.ORDERS_FAILURE:
            return {
                ...state,
                requesting: false,
                success: false,
                awaitingOrders: [],
                doneOrders: [],
            };
        case adminConstants.SELECT_ORDER:
            return { ...state, selectedOrder: action.data };
        case adminConstants.OPEN_STATUS_CONFIRMATION_MODAL:
            return { ...state, isModalOpen: true };
        case adminConstants.CLOSE_STATUS_CONFIRMATION_MODAL:
            return { ...state, isModalOpen: false };
        case adminConstants.UPDATE_ORDER_STATUS_SUCCESS:
            const order: CommonOrder = action.data;
            const awaitingOrders = cloneDeep(state.awaitingOrders);
            const doneOrders = cloneDeep(state.doneOrders);
            const index = awaitingOrders.findIndex((o) => o.id === order.id);
            if (order.status === OrderStatus.DONE || order.status === OrderStatus.REJECTED) {
                awaitingOrders.splice(index, 1);
                if (doneOrders.length > 0) {
                    for (let i = 0; i < doneOrders.length; ++i) {
                        if (doneOrders[i].createdDate < order.createdDate) {
                            doneOrders.splice(i, 0, order);
                            break;
                        }
                    }
                } else {
                    doneOrders.push(order);
                }
            } else {
                awaitingOrders[index] = order;
            }
            return { ...state, awaitingOrders, doneOrders };
        default:
            return { ...state };
    }
};