class Formatter {
    public static formatPrice = (price: number, currency: string) => {
        return `${price.toFixed(2)} ${currency.toLocaleUpperCase()}`;
    }

    public static formatPhone = (phoneNumber: string) => {
        phoneNumber = phoneNumber.replace(/[ \D()]/g, "").slice(0, 10);
        let result = "";

        const part1 = phoneNumber.slice(0, 3);
        const part2 = phoneNumber.slice(3, 6);
        const part3 = phoneNumber.slice(6, 10);

        if (part1) {
            result = "(" + part1;
        }

        if (part2) {
            result += ") " + part2;
        }

        if (part3) {
            result += "-" + part3;
        }

        return result;
    }
    
}
export const formatTime=(numberofseconds)=>{    
    var zero = '0', minutes, seconds, time;

    time = new Date(0, 0, 0, 0, 0, numberofseconds, 0);
    let mm = time.getMinutes();
    let ss = time.getSeconds() 

    // Pad zero values to 00
    mm = (zero+mm).slice(-2);
    ss = (zero+ss).slice(-2);

    time = mm + ':' + ss;
    return time; 
}
export default Formatter;