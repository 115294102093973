import * as React from "react";
import EditableSectionContent from "./EditableSectionContent";
import EditableSectionForm from "./EditableSettingForm";
import { findOneByType } from "../../utils/reactChildrenUtils";
import { ReactNode } from "react";
import "./EditableSection.css";
import { Icon } from "semantic-ui-react";

interface Offset {
    right: number;
    top: number;
}

interface EditableSectionProps {
    children: ReactNode[];
    editing?: boolean;
    onClick?: (event: any) => void;
    iconOffset?: Offset;
    iconHidden?: boolean;
}

class EditableSection extends React.Component<EditableSectionProps> {
    public static defaultProps: Partial<EditableSectionProps> = {
        editing: false,
        iconOffset: { right: 0, top: 0 }
    };

    public static Content = EditableSectionContent;
    public static Form = EditableSectionForm;

    render() {
        const content = findOneByType(this.props.children, EditableSection.Content) as React.ReactElement<any>;
        const form = findOneByType(this.props.children, EditableSection.Form) as React.ReactElement<any>;
        return (
            <div className="editable-section">
                <i 
                    link={true} 
                    className={"material-icon icon " + this.getIconClasses()} 
                    size="large" 
                    style={this.props.iconOffset} 
                    onClick={this.props.onClick} 
                >edit</i>
                {this.props.editing ? form : content}
            </div>
        );
    }

    private getIconClasses() {
        const classes: string[] = [];
        if (this.props.editing) {
            classes.push("active");
        }

        if (this.props.iconHidden) {
            classes.push("hidden");
        }

        return classes.join(" ");
    }
}

export default EditableSection;