import * as React from 'react';
import {
  Segment, Image, Form, Message,
} from 'semantic-ui-react';
import { CommonGym, StripeBillingInfo, CommonCard } from 'common';
import { injectStripe, ReactStripeElements } from 'react-stripe-elements';
import SettingRow from '../SettingRow/SettingRow';
import EditableSection from '../EditableSection/EditableSection';
import GymFormPayment from '../GymFormComponents/GymFormPayment';
import poweredByStripe from '../../assets/images/powered_by_stripe.png';
import { GymService } from '../../services/gyms';
import { PromiseManager } from '../../utils/PromiseUtils';
import SegmentHeader from '../SegmentHeader/SegmentHeader';

interface GymBillinSectionProps extends ReactStripeElements.InjectedStripeProps {
    gym: CommonGym;
    billing: StripeBillingInfo;
    onEditSuccess: (card: CommonCard) => void;
}

interface GymBillingSectionState {
    editing: boolean;
    requesting: boolean;
    error: boolean;
}

class GymBillingSection extends React.Component<GymBillinSectionProps, GymBillingSectionState> {
    private promises: PromiseManager = new PromiseManager();

    constructor(props: GymBillinSectionProps) {
      super(props);
      this.state = {
        editing: false,
        requesting: false,
        error: false,
      };
    }

    public componentWillUnmount() {
      this.promises.cancelAll();
    }

    public handleEditClick = () => {
      this.setState({ editing: !this.state.editing });
    }

    public handleSubmit = async (event: any) => {
      event.preventDefault();
      const token = await this.getStripeToken();
      if (token) {
        this.updateCardRequest(token.id);
      }
    }

    public async getStripeToken() {
      const stripe = this.props.stripe as ReactStripeElements.StripeProps;
      const result = await stripe.createToken();
      return result.token;
    }

    public render() {
      const { currentPeriodStart, currentPeriodEnd } = this.props.billing.subscription;
      return (
        <EditableSection editing={this.state.editing} onClick={this.handleEditClick} iconOffset={{ right: 10, top: 10 }}>
          <EditableSection.Content>
            <Segment className="gym-page-section with-header">
              <SegmentHeader>Billing Infos</SegmentHeader>
              <SettingRow title="Card" content={this.formatCard()} />
              <SettingRow title="Current Billing Start" content={this.formatDate(currentPeriodStart)} />
              <SettingRow title="Current Billing End" content={this.formatDate(currentPeriodEnd)} />
            </Segment>
          </EditableSection.Content>
          <EditableSection.Form>
            <Segment className="gym-page-section with-header">
              <SegmentHeader>Billing Infos</SegmentHeader>
              <Form error={this.state.error}>
                <GymFormPayment />
                <Message
                  error
                  header="Error"
                  content="We couldn't apply the changes, because an error occurred while treating your request"
                />
                <Image src={poweredByStripe} size="small" />
                <Form.Group className="right">
                  <Form.Button onClick={this.handleEditClick}>Cancel</Form.Button>
                  <Form.Button color="orange" onClick={this.handleSubmit}>Save</Form.Button>
                </Form.Group>
              </Form>
            </Segment>
          </EditableSection.Form>
        </EditableSection>
      );
    }

    public formatDate(timestamp: number) {
      const dateOptions = {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
      };
      return new Date(timestamp * 1000).toLocaleDateString('en-US', dateOptions);
    }

    public formatCard() {
      const {
        brand, last4, expMonth, expYear,
      } = this.props.billing.card;
      return `${brand} **** **** **** ${last4}, expiration : ${expMonth}/${expYear}`;
    }

    private updateCardRequest = (stripeTokenId: string) => {
      if (!this.state.requesting) {
        this.setState({ requesting: true, error: false });
        const promise = this.promises.add(GymService.updateDefaultCard(this.props.gym.id, stripeTokenId));
        promise.then((response) => {
          this.setState({ requesting: false, editing: false, error: false });
          if (this.props.onEditSuccess) {
            this.props.onEditSuccess(response.data);
          }
        }).catch((error) => {
          if (!error.isCanceled) {
            this.setState({ requesting: false, error: true });
          }
        });
      }
    }
}

export default injectStripe(GymBillingSection);
