import * as React from "react";
import { OrderStatus, CommonOrder } from "common";
import { Dropdown } from "semantic-ui-react";
import { SyntheticEvent } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { updateOrderStatus, selectOrder, openStatusModal } from "../../actions/admin.actions";

interface OrderStatusSelectProps {
    dispatch: Dispatch<void>;
    order: CommonOrder;
}

class OrderStatusSelect extends React.Component<OrderStatusSelectProps> {
    private readonly options = [
        { text: "Pending", value: OrderStatus.PENDING },
        { text: "Shipping", value: OrderStatus.SHIPPING },
        { text: "Done", value: OrderStatus.DONE },
        { text: "Rejected", value: OrderStatus.REJECTED },
    ];

    public handleChange = (event: any) => {
        if (this.props.order.status !== event.target.value) {
            if (event.target.value === OrderStatus.DONE) {
                this.props.dispatch(openStatusModal());
            } else {
                this.updateStatus(event.target.value);
            }
        }
    }

    public render() {
        return (
            <React.Fragment>
                <Dropdown 
                    inline={true} 
                    options={this.options} 
                    value={this.props.order.status} 
                    onChange={this.handleChange} 
                    onOpen={this.selectOrder}
                />
            </React.Fragment>
        );
    }

    public selectOrder = () => {
        this.props.dispatch(selectOrder(this.props.order));
    }

    public updateStatus(status: OrderStatus) {
        this.props.dispatch(updateOrderStatus(this.props.order.id, status));
    }
}

export default connect()(OrderStatusSelect);