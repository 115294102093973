import * as React from "react";
import { Table } from "semantic-ui-react";
import { CommonInterval } from "common";

interface ActivityTableRowProps {
    interval: CommonInterval;
}

export class IntervalTableRow extends React.Component<ActivityTableRowProps, {}> {
    public render() {
        const { start, end, maxStrength, stdDeviation, strain, strengthGeneration } = this.props.interval;
        return (
            <Table.Row>
                <Table.Cell>{start.toFixed(2)}</Table.Cell>
                <Table.Cell>{(end-start).toFixed(2)}</Table.Cell>
                <Table.Cell>{maxStrength.toFixed(1)}</Table.Cell>
                <Table.Cell>{(strain/(end-start)*60).toFixed(1)}</Table.Cell>
                <Table.Cell>{stdDeviation.toFixed(1)}</Table.Cell>
                <Table.Cell>{strain.toFixed(1)}</Table.Cell>
                <Table.Cell>{strengthGeneration.toFixed(1)}</Table.Cell>
            </Table.Row>
        );
    }
}
