/* eslint-disable react/jsx-filename-extension */
// <reference path='./index.d.ts'/>
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { StripeProvider } from 'react-stripe-elements';
import registerServiceWorker, {unregister} from './registerServiceWorker';
import App from './App';
import './index.css';
import './semantic/dist/semantic.min.css';
// Redux
import store from './config/store';


ReactDOM.render(
  //<StripeProvider apiKey="pk_test_2SOQz7DdQRsnZ61XKwBZOBFi">
    <Provider store={store}>
      <Router>
          <App/> 
      </Router>
    </Provider>,
  //</StripeProvider>,
  document.getElementById('root') as HTMLElement,
);

unregister();
