import * as bluetooth from '../../utils/bluetooth';
import * as React from 'react';
import * as sensorDataAnalysis from "../../utils/sensorDataAnalysis"
import { Modal, Button, Header } from 'semantic-ui-react';
import "./Bluetooth.css"
import {connect} from 'react-redux';
import { State } from '../../reducers';
import { bluetoothConnection } from '../../actions/bluetooth.actions';
import { Dispatch } from 'redux';
import { handleActivityStatus, handleCalibrationStatus } from '../../actions/activities.actions';
import { withTranslation, WithTranslation } from 'react-i18next';

interface BluetoothProps extends WithTranslation{    
    dispatch: Dispatch<void>,
    callback: any,
    activityStatus:string
  }
const mapStateToProps = (state: State) => ({
    bluetoothStatus: state.bluetooth.bluetoothStatus,
    bluetoothConnected: state.bluetooth.bluetoothConnected,
    activityStatus: state.activities.activityStatus
});
class Bluetooth extends React.Component<BluetoothProps>{
    constructor(props: BluetoothProps) {
        super(props);
        this.state = {
            eventListener: false,
            requestConnection: false
        };
        this.bluetoothConnect = this.bluetoothConnect.bind(this);
        this.addEventListener = this.addEventListener.bind(this);
    }
    bluetoothConnect() {
        if(!this.state.requestConnection){
            this.setState({ requestConnection: true });
            bluetooth.discoverDevicesOrDisconnect().then(() => {
                if (bluetooth._connected == true) {
                    this.props.dispatch(bluetoothConnection(true));
                    this.props.dispatch(handleActivityStatus('calibrate'));
                }
            }).catch((error)=>{
                this.setState({ requestConnection: false });
            });     
        }
        
    }

    addEventListener(){
        bluetooth._weightDataStreamUuid.addEventListener(
            'characteristicvaluechanged',
            this.onBluetoothDataReceived,
          );
    }

    removeEventListener(){
        bluetooth._weightDataStreamUuid.removeEventListener(
            'characteristicvaluechanged',
            this.onBluetoothDataReceived
          );
    }
    onBluetoothDataReceived = () => {
        if(this.props.activityStatus!=='rest')
            this.props.callback(bluetooth.weightData, sensorDataAnalysis.timeSinceBeginning());
    }
    componentDidUpdate(prevProps) {
        if (this.props.bluetoothStatus!== prevProps.bluetoothStatus) {
            switch(this.props.bluetoothStatus){
                case 'on':
                    if(!this.state.eventListener){
                        this.addEventListener();
                        this.setState({
                            eventListener: true
                        });                
                    }
                    break;
                case 'off':
                    if(this.state.eventListener){
                        this.removeEventListener();
                        this.setState({
                            eventListener: false
                        })
                    }
                    break;
            }
        }
    }
    render(){
        return(
            <Modal className='overlay fullscreen ui modal' open={!bluetooth._connected}>
                <Modal.Content>
                    <h1 className='title'>{this.props.t('bluetooth.header')}</h1>
                    <div
                        className="ui primary exercise-bottom  button"
                        style={{ width: "65vw", height: 40}}
                        onClick={this.bluetoothConnect}>
                        {this.props.t('common:btn.connect')}
                    </div>
                </Modal.Content>
            </Modal>
            
        )
    }



}

export default withTranslation('workout')(connect(mapStateToProps)(Bluetooth))