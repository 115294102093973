import * as React from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { capitalize } from 'lodash';
import { CommonUser, CommonRole, RoleEnum } from 'common';

import { State } from '../reducers/index';

interface RestrictedRenderProps {
    user: CommonUser;
    requiredRole: RoleEnum;
}

const mapStateToProps = (state: State) => (
  {
    user: state.authentication.user,
  }
);

class RestrictedRender extends React.Component<RestrictedRenderProps> {
  render() {
    if (this.hasRole(this.props.requiredRole)) return <Menu.Item as={Link} to="/admin" name="Admin" style={{ fontSize: 10 }} admin />;
    return null;
  }

  private hasRole(role: RoleEnum) {
    const key: string = this.getRoleKey(role);
    // @ts-ignore
    return this.props.user.role[key];
  }

  private getRoleKey(role: RoleEnum) {
    return `is${capitalize(role)}` as keyof CommonRole;
  }
}

export default connect(mapStateToProps)(RestrictedRender);
