import { navBarConstants } from "../constants/navBar.constants";

export const toggleSideBar = () => (
    { type: navBarConstants.NAV_BAR_TOGGLE_SIDEBAR }
);

export const closeSideBar = () => (
    { type: navBarConstants.NAV_BAR_CLOSE_SIDEBAR }
);

export const hideSideBar = () => (
    { type: navBarConstants.NAV_BAR_HIDE_BAR }
);

export const showSideBar = () => (
    { type: navBarConstants.NAV_BAR_SHOW_BAR }
);
