import * as React from "react";
import { Menu, Container, Dropdown, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import SearchBar from "../../../containers/SearchBar/SearchBar";
import RestrictedRender from "../../../containers/RestrictedRender";
import * as entralpiLogo from "../../../assets/images/entralpi_logo.png";
import "../NavBar.css";
import "./DesktopTopNavBar.css";
import { WithTranslation, withTranslation } from 'react-i18next';
import PrivacyPolicy from "../../../pages/PrivacyPolicy/PrivacyPolicy";

interface DesktopTopNavBarProps extends WithTranslation {
    handleLogOut: any;
}


  
class DesktopTopNavBar extends React.Component<DesktopTopNavBarProps> {
    render() {
        const profile = (
            <span>
              <Icon name='user' /> Profile
            </span>
        )

        const exercise = (
            <span>
               
            </span>
        )

        return (
            <Menu fixed="top" inverted={true} stackable={true} compact={true} fluid={true} borderless={true} className="top-nav-bar-menu">
                <Container>
                    <Menu.Item header={true} style={{width:"135px"}} as={Link} to="/profile"><img style={{position: "absolute",margin:0 ,zIndex:"3",width: "105px",background:"#C05939"}} src={entralpiLogo} /></Menu.Item>
                    {true ?  <div/> : <Menu.Item fitted={"vertically"}>
                        <SearchBar />
                    </Menu.Item>}
                    <Menu.Menu position="right">
                        <RestrictedRender requiredRole="admin"/>
                        <Menu.Item as={Link} to="/exercise-launcher" style={window.location.href.includes("exercise-launcher")?{ fontSize: "1em", fontWeight: 700,color: "#fff" }:{ fontSize: "1em" }}><Icon name="dumbbell"/> {this.props.t('navBar.exercise')}</Menu.Item>
                        <Dropdown item={true} trigger={profile} icon={null} >
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to="/profile">{this.props.t('navBar.myProfile')}</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/settings" >{this.props.t('navBar.settings')}</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item as={Link} to="/login" onClick={this.props.handleLogOut}>{this.props.t('navBar.logOut')}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Menu>
                </Container>
            </Menu >

        );
    }
}

export default withTranslation('common')(DesktopTopNavBar);