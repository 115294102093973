import * as React from "react";
import { Link, RouteComponentProps, Route, withRouter, Redirect } from "react-router-dom";
import { CommonWorkout } from "common";
import { Endurance } from "../../assets/images/workout/Endurance.jpg"
import "./WorkoutPreview.css";
import { withTranslation, WithTranslation } from "react-i18next";
import { api } from "../../config/axiosConfig";
import { Button, Card, Image } from "semantic-ui-react";
import WorkoutListing from "../../containers/WorkoutListing/WorkoutListing";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getAllWorkouts } from "../../actions/workouts.actions";
import { formatTime } from "../../utils/Formatter";

interface WorkoutPreviewProps extends RouteComponentProps, WithTranslation {
    workout: CommonWorkout;
    dispatch: Dispatch<void>;
}

class WorkoutPreview extends React.Component<WorkoutPreviewProps> {
    private navigate = () => {
            if(this.props.workout.id == 0){
                this.props.history.push(`/raw`);
            }
            else{
                this.props.history.push({
                    pathname:`/workouts/${this.props.workout}`,
                    state: {workout: this.props.workout}
                });
            }
            
    }
    delete = () => {
        const requestURL = '/v1/workout/delete';
        const dispatch = this.props.dispatch;
        api()
            .patch(requestURL, { id: this.props.workout.id })
            .then(() => {
                console.log('woohoo');
                this.props.dispatch(getAllWorkouts()); 
            })
            .catch((error) => { console.log(error) });
    }
    edit= ()=>{
        const params={
            blocGroupContainer: this.props.workout.blocGroupContainer,
            title: this.props.workout.title,
            desc: this.props.workout.desc,
            subtype: this.props.workout.subtype,
            id: this.props.workout.id,
        }
        this.props.history.push({pathname: `/exercise-creator/`, state: {...params}});
    }
    deleteButton =()=>{
        if(this.props.workout.type == "mine"){
            return(
                <Button onClick={this.delete}>Delete</Button>
            )
        }
    }
    editButton =()=>{
        if(this.props.workout.type == "mine"){
            return(
                <Button onClick={this.edit}>Edit</Button>
            )
        }
    }
    img=()=>{
        let img;
        try{
            img = require(`../../assets/images/workout/${this.props.workout.img}`);
        }catch{
            img= require(`../../assets/images/workout/Workout.png`);
        }
        return img;
    }
    time=()=>{
        if(this.props.workout.time)
            return(<div className="workoutDuration">{formatTime(this.props.workout.time)}</div>);
        else 
            return null
    }
    public render() {
        return (
            <div className='workout-preview'>
                    <Card onClick={this.navigate}>
                      <Image src={this.props.workout.img ? require(`../../assets/images/workout/${this.props.workout.img}`) : require(`../../assets/images/workout/Workout.png`) } wrapped ui={false}/>
                        {this.time()}
                        {/* {this.deleteButton()}
                        {this.editButton()} */}
                        <Card.Content>
                            <Card.Header>{this.props.t(this.props.workout.title)}</Card.Header>
                            <Card.Meta>
                                {this.props.t(this.props.workout.subtitle)}
                            </Card.Meta>
                        </Card.Content>
                    </Card>
            </div>
        );
    }
}

export default withTranslation("workout")(withRouter(WorkoutPreview));
