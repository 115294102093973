import * as React from "react";
import { Scatter, ChartData } from "react-chartjs-2";
import { CommonAcquisition } from "common";
import { ChartOptions } from "chart.js";
import { Container } from "semantic-ui-react";
import { connect } from "react-redux";
import { State } from "../../../reducers";
import { Rep } from "../../../classes/Workout";
import { isEqual, isNull } from "lodash";
import { number } from "prop-types";
import { Dispatch } from "redux";
import { handleActivityStatus } from "../../../actions/activities.actions";

interface LiveGraphProps {
    chartData: {x:number, y:number};
    currentRep: Rep;
    activityStatus: string;
    dispatch: Dispatch<void>;
}

interface LiveGraphState {
    redirect: boolean;
    chartDataArray?:Array<{x:number, y:number}>;
    color: string;
    maxValue: number;
}
const mapStateToProps = (state: State) => ({
    currentRep: state.activities.currentRep
  });
class LiveGraph extends React.PureComponent<LiveGraphProps, LiveGraphState> {
    constructor(props: LiveGraphProps) {
        super(props);
        this.state = { 
            redirect: false,
            color:'rgba(192,89,54,1)',
            chartDataArray:new Array(),
            maxValue:this.props.currentRep.expectedStrength
        };
        
        this.generateChartData = this.generateChartData.bind(this);
    }
    componentDidMount(){
        this.updateChartData({x:0, y:0});
    }
    componentDidUpdate(prevProps) {
        if (!isEqual(this.props.currentRep,prevProps.currentRep)) {
            const nextColor = this.props.currentRep.intensity == 0 ? '#6D6D6D' : 'rgba(192,89,54,1)';
            const nextMaxValue = this.props.currentRep.intensity == 0? 100: this.props.currentRep.expectedStrength;
            this.setState({ 
                color: nextColor,
                chartDataArray:new Array(),
                maxValue: nextMaxValue
            });
        } else if (prevProps.chartData.x !== this.props.chartData.x) {
            this.updateChartData(this.props.chartData);

        }else if(prevProps.activityStatus !== this.props.activityStatus){
            if(this.props.activityStatus == 'reset' ||this.props.activityStatus == 'stop'){
                this.setState({chartDataArray: new Array()});
            }
        }
    }
    shouldComponentUpdate(){
        return true;
    }
    render() {
        const chartData = this.generateChartData();
        const chartOptions = this.generateChartOptions();

        return (
                <Scatter data={chartData} options={chartOptions}/>    
        );
    }
    updateChartData(newChartData:{x:number, y:number}){
        this.setState(({chartDataArray})=>
        {
            if(this.props.activityStatus=='rest')
                newChartData.y = this.state.maxValue;
            chartDataArray.push(newChartData);
            return {chartDataArray}
        });
    }
    private generateChartData():Chart.ChartData {
        return {
            labels: ["Scatter"],
            datasets: [{
                label: "Effort",
                fill: true,
                showLine: true,
                backgroundColor: this.state.color,
                pointBorderColor: this.state.color,
                pointBackgroundColor: "#fff",
                pointBorderWidth: 0,
                pointHoverRadius: 5,
                pointHoverBackgroundColor:this.state.color,
                pointHoverBorderColor: "rgba(220,220,220,1)",
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 9,
                borderColor: this.state.color,
                data:  this.state.chartDataArray ? this.state.chartDataArray : [],
                lineTension: 0,
                spanGaps: false
            },
            {
                label: "objective",
                fill: true,
                showLine: true,
                backgroundColor: '#6D6D6D',
                pointBorderColor:'#6D6D6D',
                pointBackgroundColor: "#fff",
                pointBorderWidth: 0,
                pointHoverRadius: 5,
                pointHoverBackgroundColor:'#6D6D6D',
                pointHoverBorderColor: "rgba(220,220,220,1)",
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 9,
                data:  this.props.currentRep.intensity !== 0 ? [{x: 0, y:this.state.maxValue}, {x: this.props.currentRep.time, y:this.state.maxValue}] : [],
                lineTension: 0,
                spanGaps: false
            }]
        };
    }
    private generateChartOptions(): ChartOptions {
        return {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false,
                labels: {
                    fontColor: "rgba(255, 255, 255,0.2)"
                }
            },
            elements: {
                line: {
                    tension: 0 // disables bezier curves
                }
            },
            scales: {
                display: true,
                yAxes: [{
                    scaleLabel: {
                        display: false,
                        labelString: "Force",
                        fontColor: "rgba(255, 255, 255,0.95)"
                    },
                    gridLines: {
                        display: false,
                        color: "rgba(255, 255, 255,0.1)",
                        tickMarkLength: 0,
                    },
                    ticks: {
                        display: false,
                        autoSkip: true,
                        min: 0,
                        max: this.state.maxValue*1.3,
                        fontColor: "rgba(255, 255, 255,0.6)"
                  }
                }],
                xAxes: [{ 
                    position: "bottom",
                    scaleLabel: {
                        display: false,
                        labelString: "seconds",
                        fontColor: "rgba(255, 255, 255,0.95)",
                    },
                    gridLines: {
                        display: false,
                        color: "rgba(255, 255, 255,0.1)",
                        tickMarkLength: 0,
                    },
                    ticks: {
                        autoSkip: true,
                        maxRotation: 0,
                        display: false,
                        min: 0,
                        max: this.props.currentRep.time,
                        fontColor: "rgba(255, 255, 255,0.6)"
                  }
                }],
            },
            animation: {
                duration: 0
            },
            hover: {
                animationDuration: 0
            },
            responsiveAnimationDuration: 0,
            spanGaps: false,
            tooltips: {
                callbacks: {
// tslint:disable-next-line: typedef
                    label: function(tooltipItem) {
                        return Number(tooltipItem.yLabel) + " kg";
                    }
                },
                titleFontSize: 16,
                titleFontColor: "#0066ff",
                bodyFontColor: "#000",
                bodyFontSize: 14,
                displayColors: false
              }
        };
    }
}

export default connect (mapStateToProps)(LiveGraph);
