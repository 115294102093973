import * as React from "react";
import { Container, Grid } from "semantic-ui-react";
import { CommonGym, GetGymsResponse } from "common";
import { getAthleteGymsRequest } from "../../services/athlete";
import GymListItem from "./GymListItem";
import { PromiseManager } from "../../utils/PromiseUtils";

interface GymListState {
    requesting: boolean;
    gyms: CommonGym[];
}

class GymList extends React.Component<{}, GymListState> {
    private promises: PromiseManager = new PromiseManager();

    constructor(props: {}) {
        super(props);
        this.state = {
            requesting: false,
            gyms: []
        };
    }

    public componentDidMount() {
        this.getRequests();
    }

    public componentWillUnmount() {
        this.promises.cancelAll();
    }

    public render() {
        const items = this.state.gyms.map((gym) => {
            return (
                <Grid.Row key={gym.id}>
                    <Grid.Column>
                        <GymListItem gym={gym} />
                    </Grid.Column>
                </Grid.Row>);
        });
        return (
            <Container>
                <Grid celled="internally">
                    {items}
                </Grid>
            </Container>
        );
    }

    private getRequests = async () => {
        this.setState({ requesting: true });
        const promise = this.promises.add(getAthleteGymsRequest());
        return promise.then((response) => {
            const res = response.data as GetGymsResponse;
            this.setState({ requesting: false, gyms: res.gyms });
        })
        .catch((error) => {
            this.setState({ requesting: false, gyms: [] });
        });
    }
}

export default GymList;