import * as React from "react";
import { Table } from "semantic-ui-react";
import GymsTableRow from "./GymsTableRow";
import { CommonGym } from "common";

interface GymsTableProps {
    gyms: CommonGym[];
}

class GymsTable extends React.Component<GymsTableProps, {}> {

    public render() {
        const rows = this.props.gyms.map((gym) => {
            return (
                <GymsTableRow
                    key={gym.id}
                    gym={gym}
                />
            );
        });

        return (
            <Table unstackable={true} singleLine={true} celled={true} color="orange">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Gym Name</Table.HeaderCell>
                        <Table.HeaderCell>Phone Number</Table.HeaderCell>
                        <Table.HeaderCell>Address</Table.HeaderCell>
                        <Table.HeaderCell>Owner</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        <Table.HeaderCell>Creation Date</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows}
                </Table.Body>
            </Table >
        );
    }
}

export default GymsTable;