import React, { Component } from 'react';
import { Circle } from 'react-shapes';
import Pipe from './Pipe';
import Bluetooth from '../components/Bluetooth/Bluetooth';
import { handleActivityStatus } from '../actions/activities.actions';
import * as bluetooth from '../utils/bluetooth';
import { handleBluetoothState } from '../actions/bluetooth.actions';
import {  Dimmer, Header, Modal } from 'semantic-ui-react'
import * as sensorDataAnalysis from '../utils/sensorDataAnalysis';
import { State } from '../reducers';
import { connect } from 'react-redux';
import Navigation from '../components/Exercise/Navigation/Navigation';
import "./flappybird.css";
import {ReactComponent as Bunny} from "./bunny.svg";
import Calibration from '../components/calibration/Calibration';

const birdRadius = 80 * window.innerHeight/1080;
const mapStateToProps = (state: State) => ({
    activityStatus: state.activities.activityStatus,
    bluetoothConnected: state.bluetooth.bluetoothConnected,
    calibrationStatus: state.activities.calibrationStatus
  });
class Flappybird extends Component {

  getInitialPipes() {
    const count = 3;
    const pipes = [];
    for (let i = 1; i < count; i++) {
      const x = window.innerWidth + (window.innerWidth / i);
      const newPipe = this.generatePipe();
      pipes.push({
        upperPipeHeight: newPipe.upperPipeHeight,
        bottomPipeHeight: newPipe.bottomPipeHeight,
        x: x
      })
    }
    return pipes;
  }
  detectScreenSize(){
    if(window.innerWidth<=760){
      return 2
    }
    return 4
  }
  generatePipe(){
    const upperPipeHeight = (Math.random() * ((window.innerHeight-birdRadius*2.5) -10));
    const max = window.innerHeight-upperPipeHeight-(birdRadius*2.5);
    const min = max*0.5;
    const bottomPipeHeight = (Math.random() * (max-min)+min);
    return {upperPipeHeight: upperPipeHeight, bottomPipeHeight: bottomPipeHeight};
  }
  
  constructor(props) {
    super(props);
    this.state = {
      birdHeight: window.innerHeight / 2,
      left: window.innerWidth /5,
      gravity: 0.8,
      velocity: 0,
      pipes: this.getInitialPipes(),
      pipeSpeed: this.detectScreenSize(),
      userWeight: 0,
      screenScaling:0,
      gameOver:false,
      paused:false,
      score:0,
      calibrationData: new Array()
    }
    this.moveUp = this.moveUp.bind(this);
    this.calibrate = this.calibrate.bind(this);
    this.updateStrength = this.updateStrength.bind(this);
    this.restart = this.restart.bind(this);
    this.pauseActivity = this.pauseActivity.bind(this);
  }
  componentDidUpdate(prevProps){
    if (this.props.calibrationStatus !== prevProps.calibrationStatus) {
      this.setState(({ calibrationData }) => {
        calibrationData.length = 0;
        return { calibrationData };
      });
    }
  }
  componentDidMount() {
    this.setState({
      bluetoothConnected: bluetooth._connected,
      userWeight: 0
    });
  }

  update() {
    if(!this.state.paused){
      const birdCrashed = this.state.birdHeight > window.innerHeight - birdRadius;
      const pipeWasHit = this.state.pipes.find(pipe => pipe.isHit)
      if(birdCrashed || pipeWasHit){
        clearInterval(this.interval);
        this.setState({
          gameOver:true
        })
        return;
      }
      let passedPipe =0;
      const newPipes = this.state.pipes.map(pipe => {
        const newX = pipe.x - this.state.pipeSpeed
        if (newX < 0) {
          const newPipe = this.generatePipe();
          return {
            upperPipeHeight: newPipe.upperPipeHeight,
            bottomPipeHeight: newPipe.bottomPipeHeight,
            x: window.innerWidth - 40
          }
        } else {
          let isHit = false;
          const xDifference = (pipe.x - this.state.left );
          
          const hitOnX = xDifference < 10+birdRadius*0.7 && xDifference > 10;
          const hitOnUpperY = this.state.birdHeight < pipe.upperPipeHeight;
          const hitOnLowerY = this.state.birdHeight + birdRadius > (window.innerHeight - pipe.bottomPipeHeight)
          const isPassed = !hitOnX && pipe.x <this.state.left 
          if ((hitOnUpperY || hitOnLowerY) && hitOnX) {
            isHit = true
          }  
          else if(isPassed && pipe.isPassed == false)
          {
            passedPipe=1;
          }
            
          return {
            ...pipe,
            x: newX,
            isHit: isHit,
            isPassed: isPassed
          }
        }
      })
      this.setState((state)=>{
        return{
          score:state.score + passedPipe,
          pipes: newPipes}
      })
    }
  }

  moveUp(sensorData) {
    this.setState((state)=>{
      let birdPositionY = state.screenScaling*sensorData;
      return {
        birdHeight: birdPositionY
      }
    });
  }
  updateStrength(sensorData) {
    if (this.props.activityStatus == 'calibrate') {
      if(this.props.calibrationStatus !== 'pending' && this.props.calibrationStatus !== 'user stepped out'){
        this.setState(({calibrationData})=>{
          calibrationData.push(sensorData);
          return {calibrationData: [].concat(...calibrationData)};
        });
      }
    } 
    else if(!this.state.gameOver && !this.state.paused) {
      this.moveUp(sensorData);
    }
  }
  calibrate(userWeight){
    this.setState({
      userWeight: userWeight,
      screenScaling: window.innerHeight/(userWeight*1.15)
    });
    this.interval = setInterval(() => this.update(), 15);
  }
  restart(){
    this.setState({
      birdHeight: window.innerHeight / 2,
      left: window.innerWidth/5,
      pipes: this.getInitialPipes(),
      gameOver:false,
      paused:false,
      score:0
    });
    this.interval = setInterval(() => this.update(), 15);
  }
  renderPipes(){
    if(this.state.userWeight >0){
      return this.state.pipes.map(pipe => {
        const upperPipeHeight = pipe.upperPipeHeight;
        const x = pipe.x;

        const bottomPipeTop = window.innerHeight - pipe.bottomPipeHeight;
        const bottomPipeHeight = pipe.bottomPipeHeight;

        return <Pipe key={x} isHit={pipe.isHit} upperPipeHeight={upperPipeHeight} bottomPipeHeight={bottomPipeHeight} x={x} bottomPipeTop={bottomPipeTop} />
      })
    }
  }
  pauseActivity() {
    this.setState((state) => {
      return { paused: !state.paused }
    })
  }
  render() {
    const left = this.state.left;
    const birdHeight = this.state.birdHeight;
    return (
      <>
        <h1 className="ui header" style={{ zIndex: 2, right: 100, top: 50, position: 'absolute' }}>Score: {this.state.score}</h1>
        <Bluetooth
          callback={this.updateStrength}
        />
        <Calibration
          sensorDataArray={this.state.calibrationData}
          callback={this.calibrate}
        />

        <div className="ui flappybird-container" onClick={this.pauseActivity}>
          <div style={{ left: left, top: birdHeight, position: 'absolute', width: birdRadius, height: birdRadius, padding: 0 }}>
            <Bunny ></Bunny>
            {/* <Circle r={birdRadius} fill={{ color: '#2409ba' }} stroke={{ color: '#E65243' }} strokeWidth={3} /> */}
          </div>
          {this.renderPipes()}
        </div>

        <Dimmer
          active={this.state.paused}
          verticalAlign='center'
        >
          <Header as='h2' inverted>
            Paused
        </Header>

          <Navigation
            middleIcon={'play'}
            middleIconAction={this.pauseActivity}
            rightIcon={'undo link'}
            rightIconAction={this.restart}
            style={{ bottom: 'auto' }}>
          </Navigation>
        </Dimmer>
        <Dimmer
          active={this.state.gameOver}
          verticalAlign='center'
        >
          <Header as='h2' inverted>
            Game Over
        </Header>
          <Header as='h3' inverted>
            Your score : {this.state.score}
          </Header>

          <button className="ui inverted orange button" onClick={this.restart}>Restart</button>
        </Dimmer>
      </>
    );
  }
}

export default connect(mapStateToProps)(Flappybird);