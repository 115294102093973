import { AxiosInstance } from "axios";
import axios from "axios";

let _token = "";

// Use this when you want to talk to the backend api.
// We used to do axios.defaults.headers to set the auth token authomatically for all requests.
// However, when we do this, a bug currently prevents us to unset the token in any way.
// This prevents us from using AWS S3 wich return 400 because Authorization is always set.
// https://github.com/axios/axios/issues/385
export const api = (): AxiosInstance => {
    return axios.create({ headers: { "Authorization": `JWT ${_token}` } });
};

export const setToken = (token: string): void => {
    _token = token;
};
