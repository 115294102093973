
export interface CancelablePromise {
    promise: Promise<any>;
    cancel: () => void;
}

export class PromiseUtils {
    public static makeCancelable = (promise: Promise<any>): CancelablePromise => {
        let hasCanceled = false;

        const wrappedPromise = new Promise((resolve, reject) => {
            promise.then(
                val => hasCanceled ? reject({ isCanceled: true }) : resolve(val),
                error => hasCanceled ? reject({ isCanceled: true }) : reject(error)
            );
        });

        return {
            promise: wrappedPromise,
            cancel() {
                hasCanceled = true;
            },
        };
    }
}

export class PromiseManager {
    private promises: CancelablePromise[] = [];

    public add = (promise: Promise<any>): Promise<any> => {
        const cancellablePromise = PromiseUtils.makeCancelable(promise);
        this.promises.push(cancellablePromise);

        cancellablePromise.promise.then((response) => {
            this.remove(cancellablePromise);
            return response;
        }, (error) => {
            this.remove(cancellablePromise);
            return error;
        });

        return cancellablePromise.promise;
    }

    public cancelAll = () => {
        this.promises.forEach((promise) => {
            promise.cancel();
        });

        this.promises = [];
    }

    private remove = (promise: CancelablePromise) => {
        const index = this.promises.findIndex(p => p === promise);
        if (index >= 0) {
            this.promises.splice(index, 1);
        }
    }
}