import * as React from "react";
import { RouteComponentProps } from "react-router";
import { api } from "../config/axiosConfig";
import { AxiosResponse } from "axios";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { OAuthSuccess } from "../actions/user.actions";
import { Redirect } from "react-router-dom";

interface OAuthRedirectProps extends RouteComponentProps<void> {
    dispatch: Dispatch<void>;
}

class OAuthRedirect extends React.Component<OAuthRedirectProps, {}> {
    constructor(props: OAuthRedirectProps) {
        super(props);
        const token = new URLSearchParams(this.props.location.search).get("token");
        if (token) {
            api()
            .post("/v1/auth/jwt", { token: token })
            .then(async(response: AxiosResponse) => {
                if (response.status === 200) {
                    await this.props.dispatch( OAuthSuccess(response.data.user, token)); 
                }
            }).then(()=>{this.props.history.push("/login");})
            .catch(() => {
                return;
            });
        }
    }

    render() {
        return <Redirect to="/login"/>;
    }
}

export default connect()(OAuthRedirect);
