import { adminConstants } from "./../../../constants/admin.constants";
import * as HttpStatus from "http-status-codes";

export interface RemoveAdminState {
    requesting: boolean;
    deleted: boolean;
    error?: string;
}

const defaultState: RemoveAdminState = {
    requesting: false,
    deleted: false,
    error: undefined
};

export const removeAdmin = (state: RemoveAdminState = defaultState, action: any): RemoveAdminState => {
    switch (action.type) {
        case adminConstants.REMOVE_ADMIN_REQUEST:
            return { ...state, requesting: true, deleted: false, error: undefined };
        case adminConstants.REMOVE_ADMIN_SUCCESS:
            return {
                ...state,
                requesting: false,
                deleted: true,
                error: undefined
            };
        case adminConstants.REMOVE_ADMIN_FAILURE:
            const status: number = action.status;
            let error: string = "";

            switch (status) {
                case HttpStatus.NOT_FOUND: 
                    error = "The admin was not found";
                    break;
                default:
                    error = "An error occured and we were unable to execute your request";
                    break;
            }
            return { ...state, requesting: false, deleted: false, error };
        default:
            return { ...state };
    }
};