import { activitiesConstants } from "./../constants/activities.constants";
import { CommonAcquisition } from "common";
import { Rep } from "../classes/Workout";

export interface ActivityFiltersState {
    title: string;
    type: string;
}

export interface ActivitiesState {
    requesting: boolean;
    received: boolean;
    activities: Array<CommonAcquisition>;
    total: number;
    page: number;
    uploadedId: any;
    filters: ActivityFiltersState;
    activityStatus: string;
    timerStatus:string;
    calibrationStatus: string;
    currentRep:Rep;
}

const defaultState = {
    requesting: false,
    received: false,
    activities: [],
    uploadedId: null,
    total: 0,
    page: 1,
    filters: { title: "", type: "" },
    activityStatus: 'stop',
    timerStatus:'stop',
    calibrationStatus: 'stop',
    currentRep:null
};

export const activities = (state: ActivitiesState = defaultState, action: any) => {
    switch (action.type) {
        case activitiesConstants.ACTIVITIES_REQUEST:
            return { ...state, requesting: true, received: false };
        case activitiesConstants.ACTIVITIES_SUCCESS:
            return {
                ...state,
                requesting: false,
                received: true,
                activities: action.data.activities,
                total: action.data.total
            };
        case activitiesConstants.ACTIVITIES_FAILURE:
            return { ...defaultState };
        case activitiesConstants.ARCHIVE_SUCCESS:
            const act = state.activities;
            const index = act.map((a) => {return a.id; }).indexOf(action.data);
            act.splice(index, 1);
            return { ...state, activities: act, total: state.total - 1};
        case activitiesConstants.ARCHIVE_FAILURE:
            return { ...state };
        case activitiesConstants.UPDATE_FILTERS:
            return { ...state, filters: action.data };
        case activitiesConstants.SET_PAGE:
            return { ...state, page: action.data };
        case activitiesConstants.ACTIVITIES_RESET_STATE:
            return { ...defaultState };
        case activitiesConstants.UPLOAD_SUCCESS:
            return {
                ...state,
                uploadedId: action.data.id,
            };
        case activitiesConstants.UPLOAD_FAILURE:
            return { ...defaultState };
        case activitiesConstants.ACTIVITY_STATUS:
            return{...state, activityStatus: action.data};
        case activitiesConstants.TIMER_STATUS:
            return { ...state, timerStatus: action.data };
        case activitiesConstants.CALIBRATION_SATUS:
            return { ...state, calibrationStatus: action.data };
        case activitiesConstants.CURRENT_REP:
            return { ...state, currentRep: action.data };
        default:
            return { ...state };
    }
};