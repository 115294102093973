import * as React from "react";
import { RouteComponentProps } from "react-router";
import WorkoutPreview from "../../pages/WorkoutPreview/WorkoutPreview";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { State } from "../../reducers";
import { getAllWorkouts } from "../../actions/workouts.actions";
import { WorkoutsState, getWorkoutsSuccess, getWorkoutsError, getWorkoutsPending } from "../../reducers/workouts.reducer";
import {showSideBar} from "../../actions/navBar.actions"
import store from '../../config/store';
import { Translation, withTranslation, WithTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import { Button, Card } from "semantic-ui-react";
import './WorkoutListing.css';
import { ResponsiveUtil } from "../../utils/ResponsiveUtil";
import { WorkoutSlider } from "./WorkoutSlider";
interface RouteProps {
    id: string;
}

interface WorkoutListingProps extends RouteComponentProps< RouteProps>, WithTranslation
{
    workouts: WorkoutsState;
    dispatch: Dispatch<void>;
}

const mapStateToProps = (state: State) => ({
    workouts :getWorkoutsSuccess(state),
    error: getWorkoutsError(state),
    pending: getWorkoutsPending(state)
});

class WorkoutListing extends React.Component<WorkoutListingProps> {

    public componentDidMount() {
        console.log('didmounts');   
        this.fetchWorkouts();
        store.dispatch(showSideBar());
    }
    

    public fetchWorkouts() {
        this.props.dispatch<any>(getAllWorkouts());
    } 
    private navigate = () => {
        this.props.history.push('/exercise-creator');
    }
    
    myWorkoutsHTML=()=>{
        const cardsMyWorkouts = this.props.workouts.workouts.filter(
            (workout)=>workout.type == 'mine'
            ).map((workout) => <WorkoutPreview workout={workout} ></WorkoutPreview>);
        if(cardsMyWorkouts.length>0){
            return(
                <WorkoutSlider workouts={cardsMyWorkouts}/>
            )
        }
        else{
            return(
                <div className='emptyMyWorkouts'>
                    <span>
                        <p>{this.props.t('myWorkouts.empty')}</p>
                    </span>    
                </div>
            )
        }
    }
    public render() {
        const cardsExercises = this.props.workouts.workouts.filter((workout)=>{
            return workout.type == 'training';
            }).map((workout) =><WorkoutPreview workout={workout}></WorkoutPreview>);

        const cardsRoutines = this.props.workouts.workouts.filter((workout)=>{
            return workout.type == 'warmp-up';
            }).map((workout) => <WorkoutPreview workout={workout}></WorkoutPreview>);

        const cardsTests = this.props.workouts.workouts.filter((workout)=>{
            return workout.type == 'test';
        }).map((workout) => <WorkoutPreview workout={workout}></WorkoutPreview>);
        
        return (
            <React.Fragment>
                <div className='ui one column grid workout-listing'>
                    <br/>
                    <h1>{this.props.t('header.mainHeader')}</h1>
                    
                    <div className='column'>
                        <span className='my-workouts'>
                            <h2>{this.props.t('myWorkouts.title')}</h2>
                            <Button className='btnAddWorkout' primary onClick={this.navigate}>
                                <span>+</span>
                             </Button>
                        </span>
                        {this.myWorkoutsHTML() }
                    </div>
                    <h2>{this.props.t("0.title")}</h2>
                    <div className='column'>
                    <Card.Group>
                        <WorkoutPreview workout={{
                            id: 0,
                            img: 'Raw.jpg',
                            title: '0.title',
                            subtitle: '0.subtitle'}}>
                        </WorkoutPreview>
                    </Card.Group>
                    </div>
                    <h2>{this.props.t('header.exercises')}</h2>
                    <div className='column'>
                            <WorkoutSlider workouts={cardsExercises}/>
                    </div>

                    <h2>{this.props.t('routine.title')}</h2>
                    <div className='column'>
                        {cardsRoutines}
                    </div>
                    <h2>{this.props.t('header.tests')}</h2>
                    <div className='column'>
                    <Card.Group>
                        {cardsTests }
                    </Card.Group>
                        
                    </div>
                </div>
            </React.Fragment>
        );
    }


}

export default withTranslation("workout")(connect(mapStateToProps)(WorkoutListing));