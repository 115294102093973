import * as React from "react";
import { HeatmapData } from "common";
import ReactCalendarHeatmap from "react-calendar-heatmap";
import "./CalendarHeatmap.css";
import moment from "moment";
import { Popup } from "semantic-ui-react";
import { WithTranslation, withTranslation } from "react-i18next";

interface CalendarHeatmapProps extends WithTranslation {
    heatmapData: HeatmapData[];
    nbOfMonth: number;
}

class CalendarHeatmap extends React.Component<CalendarHeatmapProps> {
    public render() {
        if (this.props.heatmapData.length > 0) {
            const values = this.props.heatmapData;
            const endDate = moment();
            const startDate = endDate.clone().subtract(this.props.nbOfMonth, "month");
            return (
                <ReactCalendarHeatmap
                    startDate={startDate.toString()}
                    endDate={endDate.toString()}
                    values={values}
                    classForValue={this.colorMapping}
                    transformDayElement={this.customDataProcessing}
                />
            );
        }
        return null;

    }
    private colorMapping = (value: HeatmapData) => {
        if (!value) {
            return "color-empty";
        }
        return `color-entralpi-${value.count}`;
    }

    private customDataProcessing = (rect: any, value: HeatmapData, index: number) => {
        let content = null;
        if (value) {
            let tooltipContent = this.props.t('profileStats.activityCount', {count: value.count});
            content = (
                <Popup
                    key={index}
                    content={tooltipContent}
                    trigger={rect}
                    inverted={true}
                    position="top center"
                />
            );
        } else {
            content = rect;
        }
        return content;
    }
}
export default withTranslation("profile")(CalendarHeatmap);
