import * as React from "react";
import { Header } from "semantic-ui-react";
import OrderTable from "./OrderTable";
import { CommonOrder, OrderStatus } from "common";
import LoadingSegment from "../LoadingSegment/LoadingSegment";
import { State } from "../../reducers";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { getOrders, closeStatusModal, updateOrderStatus } from "../../actions/admin.actions";
import { AdminOrdersState } from "../../reducers/admin/orders.reducer";
import UpdateStatusModal from "./UpdateStatusModal";

interface OrderSectionProps extends AdminOrdersState {
    dispatch: Dispatch<any>;
}

interface OrderSectionState {
    success: boolean;
    awaitingOrders: CommonOrder[];
    doneOrders: CommonOrder[];
}

const mapStatetoProps = (state: State) => ({ ...state.admin.orders });

class OrderSection extends React.Component<OrderSectionProps, OrderSectionState> {
    public componentDidMount() {
        this.getOrders();
    }

    public render() {
        return (
            <React.Fragment>
                <Header as="h3">Awaiting Orders</Header>
                <LoadingSegment isLoading={!this.props.success}>
                    <OrderTable orders={this.props.awaitingOrders} editableStatus={true} />
                </LoadingSegment>
                <Header as="h3">Orders History</Header>
                <LoadingSegment isLoading={!this.props.success}>
                    <OrderTable orders={this.props.doneOrders} />
                </LoadingSegment>
                <UpdateStatusModal open={this.props.isModalOpen} onConfirm={this.onModalConfirm} onCancel={this.closeModal}/>
            </React.Fragment>
        );
    }

    public onModalConfirm = () => {
        const order = this.props.selectedOrder;
        if (order) {
            this.props.dispatch(updateOrderStatus(order.id, OrderStatus.DONE)).then(() => {
                this.closeModal();
            })
            .catch(() => {
                this.closeModal();
            });
        } else {
            this.closeModal();
        }
    }

    public closeModal = () => {
        this.props.dispatch(closeStatusModal());
    }

    public getOrders() {
        this.props.dispatch(getOrders());
    }
}

export default connect(mapStatetoProps)(OrderSection);