import * as React from "react";
import { Grid } from "semantic-ui-react";
import { RouteComponentProps, Route, Switch, Redirect } from "react-router";

import SettingsMenu from "../../components/SettingsMenu/SettingsMenu";
import SettingsProfile from "../../components/SettingsProfile/SettingsProfile";
import SettingsAccount from "../../components/SettingsAccount.tsx/SettingsAccount";
import GymListPage from "../GymListPage/GymListPage";
import GymRequest from "../GymRequest/GymRequest";
import SettingsDisplay from "../../containers/SettingsDisplay/SettingsDisplay";
import SettingsPrivacy from "../../containers/SettingsPrivacy/SettingsPrivacy";

class Settings extends React.Component<RouteComponentProps<void>, {}> {
    render() {
        const { match } = this.props;
        return (
            <Grid stackable={true}>
                <Grid.Column mobile={5} tablet={5} computer={4} >
                    <SettingsMenu />
                </Grid.Column>
                <Grid.Column mobile={11} tablet={11} computer={12}>
                    <Switch>
                        <Redirect exact={true} path={`${match.url}`} to={`${match.url}/profile`} />
                        <Route path={`${match.url}/profile`} component={SettingsProfile} />
                        <Route path={`${match.url}/account`} component={SettingsAccount} />
                        <Route path={`${match.url}/display`} component={SettingsDisplay} />
                        <Route path={`${match.url}/privacy`} component={SettingsPrivacy} />
                        <Route exact={true} path={`${match.url}/gyms`} component={GymListPage} />
                        <Route path={`${match.url}/gyms/buy`} component={GymRequest} />
                    </Switch>
                </Grid.Column>
            </Grid>
        );
    }
}

export default Settings;
