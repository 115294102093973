import * as React from "react";
import { Grid } from "semantic-ui-react";
import ResetPasswordForm from "../../components/ResetPasswordForm/ResetPasswordForm";

class ForgotPassword extends React.Component {
    render() {
        return (
            <Grid centered={true} verticalAlign="middle" className="login-page">
                <Grid.Column>
                    <ResetPasswordForm/>
                </Grid.Column>
            </Grid>
        );
    }
}

export default ForgotPassword;
