import * as React from 'react';
import './ProfileTopSection.css';
import { CommonAthleteStatistics } from 'common';
import { Link } from 'react-router-dom';
import {
  Segment, Image, Header, Statistic, Message, Button,
} from 'semantic-ui-react';
import { UserProfileState } from '../../../reducers/userProfile.reducer';
import { WithTranslation, withTranslation } from 'react-i18next';

interface ProfileTopSectionProps extends WithTranslation{
    user: UserProfileState;
    isOwnProfile: boolean;
    statistics: CommonAthleteStatistics;
}

class ProfileTopSection extends React.Component<ProfileTopSectionProps> {
  public render() {
    const level: string = this.props.statistics.level || 'N/A';
    const strength: string = this.props.statistics.strength ? `${this.props.statistics.strength}kg` : 'N/A';

    return (
      <Segment className="profile-top-section">
        <Image className="profile-picture" circular src={this.props.user.imageUrl} />
        <div className="user-overview-infos">
          <div className="name">
            <Header as="h1">{`${this.props.user.firstName} ${this.props.user.lastName}`}</Header>
          </div>
          <div className="stats">
            {true ? <div /> : (
              <Statistic.Group>
                <Statistic horizontal>
                  <Statistic.Value className="no-uppercase">{strength}</Statistic.Value>
                  <Statistic.Label>{this.props.t('profileTop.strength')}</Statistic.Label>
                </Statistic>
                <Statistic horizontal>
                  <Statistic.Value>{level}</Statistic.Value>
                  <Statistic.Label>{this.props.t('profileTop.level')}</Statistic.Label>
                </Statistic>
              </Statistic.Group>
            )}
            <Button className="ui button" primary={true} as={Link} to="/exercise-launcher">
                            {this.props.t('profileTop.startExercise')}
            </Button>
            {this.renderStrengthMessage}
          </div>
        </div>
      </Segment>
    );
  }

  private isStrengthStatExpired() {
    const date: Date = new Date();
    date.setMonth(date.getMonth() - 2);
    if (this.props.statistics.strengthDate) {
      const strengthDate: Date = new Date(this.props.statistics.strengthDate);
      return strengthDate < date;
    }

    return false;
  }

  private renderStrengthMessage() {
    const hidden = true;
    if (!hidden && this.props.isOwnProfile) {
      if (this.props.statistics.strengthDate) {
        if (this.isStrengthStatExpired()) {
          return (
            <Message
              compact
              warning
              header={this.props.t('profileTop.strTestOutOfDateHeader')}
              content={this.props.t('profileTop.strTestOutOfDateContent')}
            />
          );
        }
      } else {
        return (
          <Message
            compact
            warning
            header={this.props.t('profileTop.strTestNotDoneHeader')}
            content={this.props.t('profileTop.strTestNotDoneContent')}
          />
        );
      }
    }
  }
}

export default withTranslation('profile')(ProfileTopSection);
