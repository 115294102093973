import * as React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface IWorkoutSlider{
    workouts: JSX.Element[];
}
export const WorkoutSlider =(props: IWorkoutSlider)=>{
    const slickSettings = {
        className: "slider variable-width",
        dots: true,
        infinite: false,
        slidesToScroll: 1,
        variableWidth: true,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>
    }
    return(
        <Slider {...slickSettings}>
            {props.workouts}
        </Slider>
    )
}

const NextArrow =(props) =>{
    const { className, style, onClick } = props;
    if(window.innerWidth > 800)
        return (
        <div
            className={className}
            style={{ ...style, display: "block"}}
            onClick={onClick}
        />
        );
    else
        return null;
  }
  
const PrevArrow = (props)=> {
    const { className, style, onClick } = props;
    if (window.innerWidth > 800)
        return (
            <div
                className={className}
                style={{ ...style, display: "block" }}
                onClick={onClick}
            />
        );
    else
        return null;
}

