import { settingsConstants } from "./../constants";
import axios, { AxiosError, AxiosResponse } from "axios";
import { api } from "../config/axiosConfig";
import { fetchUserRequest } from "../services/user";
import { FetchUserResponse, CommonDisplaySettings, CommonPrivacySettings } from "common";
import { userConstants } from "../constants/user.constants";

export interface SettingsProfileFormErrors {
    firstName?: string;
    lastName?: string;
    weight?: string;
}

export const cancelEditProfile = () => {
    return {
        type: settingsConstants.CANCEL_EDIT_PROFILE
    };
};

export const updateErrorsProfile = (errors: SettingsProfileFormErrors) => {
    return {
        type: settingsConstants.UPDATE_ERRORS_PROFILE,
        data: errors
    };
};

export const fetchUserProfile = (id: number) => {
    const success = (user: any) => {
        return {
            type: settingsConstants.FETCH_SUCCESS,
            user: user
        };
    };

    const failure = (response: any) => {
        return {
            type: settingsConstants.FETCH_FAILURE,
            data: response
        };
    };

    return (dispatch: any) => {
        return fetchUserRequest(id)
            .then((response: AxiosResponse<FetchUserResponse>) => {
                dispatch(success(response.data));
                return response.data;
            })
            .catch((error: AxiosError) => {
                dispatch(failure(error.response));
                return error.response;
            });
    };
};

// First: Get the AWS S3 uploadUrl and imageUrl from the backend
// Second: Upload to AWS S3, imageUrl will then be a valid url
export const uploadImage = (image: File) => {
    const success = (imageUrl: string) => {
        return {
            type: settingsConstants.IMAGE_UPLOAD_SUCCESS,
            imageUrl: imageUrl
        };
    };

    const failure = (errors: any) => {
        return {
            type: settingsConstants.IMAGE_UPLOAD_FAILURE,
            errors: errors
        };
    };

    const signingURL = `/v1/athlete/sign-s3?fileName=${image.name}&fileType=${image.type}`;

    return (dispatch: any) => {
        api()
            .get(signingURL)
            .then((response: any) => {
                axios
                    .put(response.data.uploadUrl, image)
                    .then(() => { dispatch(success(response.data.imageUrl)); })
                    .catch((error) => { dispatch(failure(error)); });
            })
            .catch((error) => { dispatch(failure(error)); });
    };
};

export const modifyDisplaySettings = (displaySettings: CommonDisplaySettings) => {
    const request = () => ({ type: userConstants.MODIFY_DISPLAY_SETTINGS_REQUEST });
    const success = (data: any) => ({ type: userConstants.MODIFY_DISPLAY_SETTINGS_SUCCESS, data });
    const failure = () => ({ type: userConstants.MODIFY_DISPLAY_SETTINGS_FAILURE });

    const requestURL = "/v1/athlete/display-settings";

    return (dispatch: any) => {    
        dispatch(request());
        api()
            .patch(requestURL, displaySettings)
            .then(response => {dispatch(success(response.data)); })
            .catch(error => { dispatch(failure()); });
    };
};

export const modifyPrivacySettings = (privacySettings: CommonPrivacySettings) => {
    const request = () => ({ type: userConstants.MODIFY_PRIVACY_SETTINGS_REQUEST });
    const success = (data: any) => ({ type: userConstants.MODIFY_PRIVACY_SETTINGS_SUCCESS, data });
    const failure = () => ({ type: userConstants.MODIFY_PRIVACY_SETTINGS_FAILURE });

    const requestURL = "/v1/athlete/privacy-settings";

    return (dispatch: any) => {        
        dispatch(request());
        api()
            .patch(requestURL, privacySettings)
            .then(response => { dispatch(success(response.data)); })
            .catch(error => { dispatch(failure()); });
    };
};