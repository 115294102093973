import React, { useState } from 'react';
import GeneralForm from './edit/GeneralForm';
import TrainingBloc from './edit/TrainingBloc';
import ExerciseContainerEdit from './edit/ExerciseContainerEdit';
import { Button } from 'semantic-ui-react';
import { connect } from "react-redux";
import RestBloc from './edit/RestBloc';
import { expect, validateForm } from "../../utils/Validator";
import {TrainingBlocClass, ITrainingBloc, IRestBloc, IBloc, RestBlocClass, IBlocGroup, BlocGroupClass} from './classes';
import { api } from "../../config/axiosConfig";
import { isEmpty } from 'lodash';
import { RouteComponentProps } from 'react-router';
import { State } from '../../reducers';
import Hands from '../../components/Hands/Hands';

interface ExerciseCreatorState{
    blocGroupContainer: [IBlocGroup];
    currentId:number;
    errors: any;
    title: string;
    subtitle?:string;
    img?:string;
    desc: string;
    type:string;
    subtype:string;
    id?:number;
    isAdmin:boolean;
}
const mapStateToProps = (state: State) => ({
    isAdmin: state.authentication.user.role.isAdmin,
});
class ExerciseCreator extends React.Component<RouteComponentProps,ExerciseCreatorState>{ 
    constructor(props : RouteComponentProps){
        super(props); 
        const defaultState: ExerciseCreatorState= {
            blocGroupContainer:undefined,
            currentId:  0,
            title: '',
            desc:  '',
            subtype: '',
            isAdmin:false,
            errors:{
                title:false,
                desc:false,
                subtype:false
            }
        }
        if(props.location.state != undefined && props.location.state.blocGroupContainer){
            this.state = {
                id: props.location.state.id,
                blocGroupContainer:this.generateGroupBloc(props.location.state.blocGroupContainer) ,
                currentId: props.location.state.blocGroupContainer.length - 1,
                title: props.location.state.title,
                desc: props.location.state.desc,
                subtype: props.location.state.subtype,
                type:'mine',
                errors: defaultState.errors,
            }
            
        }
        else {
            this.state={
                blocGroupContainer:  [new BlocGroupClass({id:0, updateState: this.updateBlocs, delete: this.deleteGroupBloc})],
                currentId: defaultState.currentId,
                title:defaultState.title,
                desc: defaultState.desc,
                type:'mine',
                subtype: defaultState.subtype,
                errors: defaultState.errors,
                isAdmin:defaultState.isAdmin
            }
            this.state.blocGroupContainer[0].addBloc('hang');
        }
        this.deleteGroupBloc = this.deleteGroupBloc.bind(this);
        this.updateBlocs = this.updateBlocs.bind(this);
        this.handleInputChanges = this.handleInputChanges.bind(this);
    }
    componentDidMount(){
        if(this.props.isAdmin && this.props.location.state && this.props.location.state.createFeaturedWorkout)
            this.setState({isAdmin:true});
    }
    generateGroupBloc = (blocGroupContainer: [IBlocGroup]) => 
        blocGroupContainer.map((blocGroup: IBlocGroup) =>
            new BlocGroupClass({id: blocGroup.id, multiplier: blocGroup.multiplier, updateState: this.updateBlocs, delete: this.deleteGroupBloc, blocs:blocGroup.blocs})
    )
    private validations = [
        expect("title").required(),
        expect("desc").required(),
        expect("subtype").required(),
    ];
    deleteGroupBloc = (groupId?) => {
        const blocGroupIndex = this.state.blocGroupContainer.findIndex(group=>group.id == groupId)
        this.setState(({ blocGroupContainer }) =>{
            blocGroupContainer.splice(blocGroupIndex, 1);
            return {blocGroupContainer};
        })
    }
    updateBlocs = (newBlocGroup?: IBlocGroup) => {
        const blocGroupIndex = this.state.blocGroupContainer.findIndex(group=>group.id == newBlocGroup.id)
        this.setState(({ blocGroupContainer }) => {
            blocGroupContainer[blocGroupIndex] = newBlocGroup
            return {blocGroupContainer}
        });
    } 

    addBlocGroup = () => {
        this.setState(({ blocGroupContainer, currentId }) => {
            const newBlocGroup = new BlocGroupClass({id: currentId+1, updateState: this.updateBlocs, delete: this.deleteGroupBloc});
            newBlocGroup.addBloc("hang");
            blocGroupContainer.push(newBlocGroup);
            currentId += 1;
            return { blocGroupContainer, currentId }
        });
    }
    handleInputChanges =(input:{value:string, id:string})=>{
        switch (input.id) {
            case 'inputTitle':
                return this.setState({title:input.value});
            case 'inputSubtitle':
                return this.setState({subtitle : input.value});
            case 'inputDesc':
                return this.setState({desc : input.value});
            case 'inputImg':
                return this.setState({img : input.value});
            case 'workoutSubtype':
                return this.setState({subtype: input.value});
            case 'workoutType':
                return this.setState({type :input.value});
        }
        
    }
    saveWorkout=()=>{
        let errors = validateForm(this.state, this.validations);
        if(isEmpty(errors)){
            let requestURL;
            if(this.props.location.state && !this.props.location.state.blocGroupContainer){
                requestURL =this.state.isAdmin? '/v1/workout/uploadFeaturedWorkout': '/v1/workout/upload';
                api()
                .post(requestURL, { ...this.state})
                .then(() => {
                    this.props.history.push('/');
                })
                .catch((error) => { console.log('error uploading workout') });
            }
                    
            else{
                requestURL = '/v1/workout/update' 
                api()
                .patch(requestURL, { ...this.state,})
                .then(() => {
                    this.props.history.push('/');
                })
                .catch((error) => { console.log('error updating workout') });
            }
        }
        else
            this.setState({errors: errors});
    }
    render() {
        return (
            <div>
                {<GeneralForm state= {this.state} handleInputChanges={this.handleInputChanges} errors={this.state.errors}/>}
                {<ExerciseContainerEdit blocGroupContainer={this.state.blocGroupContainer} isAdmin={this.state.isAdmin}/>}
                <Button onClick={() => this.addBlocGroup()}> Add another group</Button>
                <Button onClick={() => this.saveWorkout()}> Save </Button>
            </div>
        );
    }
}


export default connect(mapStateToProps) (ExerciseCreator);