import * as React from "react";
import { Confirm } from "semantic-ui-react";

interface UpdateStatusModalProps {
    onConfirm: any;
    onCancel: any;
    open: boolean;
}

class UpdateStatusModal extends React.Component<UpdateStatusModalProps> {
    public render() {
        return (
            <Confirm
                header="Warning"
                open={this.props.open}
                content={
                    `Setting the status to done is irreversible. 
                        It means that the order has been completed.
                        Therefore, from this moment, the client subscription will be modified!`
                }
                size="mini"
                onConfirm={this.props.onConfirm}
                onCancel={this.props.onCancel}
            />
        );
    }
}

export default UpdateStatusModal;