import React from 'react';
import { Input } from 'semantic-ui-react';
import SubtypeDropDown from './SubtypeDropDown';
import TypeDropDown from './TypeDropDown';


interface GeneralFormProps{
    handleInputChanges: Function;
    errors: {title:boolean, desc:boolean, subtype:boolean};
    state: any;
}

const GeneralForm = (props: GeneralFormProps) => {
    const handleChanges = (e, { value, id }) => {
        props.handleInputChanges({ value: value, id: id });
    }
    const name = () =>
        <div>
            <h3>Title</h3>
            <Input value={props.state.title} id='inputTitle' placeholder='Enter the title...' onChange={handleChanges} error={props.errors.title} />
        </div>
    const subtitle = () => {
        if (props.state.isAdmin)
            return (
                <div>
                    <h3>SubTitle</h3>
                    <Input id='inputSubtitle' placeholder='Enter the subtitle...' onChange={handleChanges} />
                </div>
            )
    }

    const img = () => {
        if (props.state.isAdmin) {
            return (
                <div>
                    <h3>Image</h3>
                    <Input id='inputImg' placeholder='Enter the local img address...' onChange={handleChanges} />
                </div>
            )
        }
    }

    const desc = () =>
        <div>
            <h3>Description</h3>
            <Input value={props.state.desc} id='inputDesc' placeholder='Enter the description of the exercise...' onChange={handleChanges} error={props.errors.desc} />
        </div>

    const type = () => {
        if (props.state.isAdmin)
            return (
                <div>
                    <h3>Workout type</h3>
                    <TypeDropDown handleChanges={handleChanges} id='workoutType' />
                </div>
            )
    }

    const subtype = () =>
        <div>
            <h3>Workout subtype</h3>
            <SubtypeDropDown value={props.state.subtype} handleChanges={handleChanges} error={props.errors.subtype} />
        </div>
    return (
        <div>
            {name()}
            {subtitle()}
            {img()}
            {desc()}
            {type()}
            {subtype()}
        </div>)
}
    

export default GeneralForm;