import * as React from "react";
import "./NavBar.css";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { State } from "../../reducers/index";
import { disconnect } from "../../actions/user.actions";
import { Responsive } from "semantic-ui-react";
import DesktopTopNavBar from "../../components/NavBar/DesktopNavBar/DesktopTopNavBar";
import { MobileTopNavBar } from "../../components/NavBar/MobileTopNavBar/MobileTopNavBar";
import { MobileSideNavBar } from "../../components/NavBar/MobileSideNavBar/MobileSideNavBar";
import { NavBarState } from "../../reducers/navBar.reducer";
import { ResponsiveUtil } from "../../utils/ResponsiveUtil";

interface NavBarProps {
    dispatch: Dispatch<void>;
    navBar: NavBarState;
}

const mapStatetoProps = (state: State) => {
    return {
        navBar: state.navBar
    };
};

class NavBar extends React.Component<NavBarProps> {
    handleLogOut = () => {
        this.props.dispatch(disconnect());
    }

    render() {
        return (
            <React.Fragment>
                <Responsive minWidth={ResponsiveUtil.MOBILE_MAX_WIDTH}  className={`NavBarVisible${this.props.navBar.visibility}`} >
                    <DesktopTopNavBar handleLogOut={this.handleLogOut} />
                </Responsive>

                <Responsive maxWidth={ResponsiveUtil.MOBILE_MAX_WIDTH}  className={`NavBarVisible${this.props.navBar.visibility}`}>
                    <MobileTopNavBar dispatch={this.props.dispatch} />
                    <MobileSideNavBar
                        handleLogOut={this.handleLogOut}
                        showSideNavBar={this.props.navBar.showSideBar}
                        dispatch={this.props.dispatch}
                    />
                </Responsive>
            </React.Fragment >
        );
    }

}

export default connect(mapStatetoProps)(NavBar);