import * as React from "react";
import { Header, Button } from "semantic-ui-react";
import "./GymListMenu.css";
import { Link } from "react-router-dom";

class GymListMenu extends React.Component {

    private readonly PAGE_TITLE = "My Gyms";

    public render() {
        return (
            <div className="gym-header">
                <Header as="h1">{this.PAGE_TITLE}</Header>
                <Button
                    as={Link}
                    to="/settings/gyms/buy"
                    className="gym-button"
                    color="orange"
                >
                    Buy an Entralpi system
                </Button>
            </div>
        );
    }
}

export default GymListMenu;