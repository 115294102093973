export const userConstants = {
    LOGIN_REQUEST: "USER_LOGIN_REQUEST",
    LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
    LOGIN_FAILURE: "USER_LOGIN_FAILURE",

    LOGIN_FACEBOOK_REQUEST: "USER_FACEBOOK_LOGIN_REQUEST",
    LOGIN_FACEBOOK_SUCCESS: "USER_FACEBOOK_LOGIN_SUCCESS",
    LOGIN_FACEBOOK_FAILURE: "USER_FACEBOOK_LOGIN_FAILURE",
    DISCONNECT: "USER_DISCONNECT",

    SIGNUP_REQUEST: "USER_SIGNUP_REQUEST",
    SIGNUP_SUCCESS: "USER_SIGNUP_SUCCESS",
    SIGNUP_FAILURE: "USER_SIGNUP_FAILURE",
    SIGNUP_UPDATE_ERRORS: "SIGNUP_UPDATE_ERRORS",

    MODIFY_REQUEST: "MODIFY_USER_REQUEST",
    MODIFY_SUCCESS: "MODIFY_USER_SUCCESS",
    MODIFY_FAILURE: "MODIFY_USER_FAILURE",

    USER_CARD_REQUEST: "USER_CARD_REQUEST",
    USER_CARD_SUCCESS: "USER_CARD_SUCCESS",
    USER_CARD_FAILURE: "USER_CARD_FAILURE",
    USER_PROFILE_REQUEST: "USER_PROFILE_REQUEST",
    USER_PROFILE_SUCCESS: "USER_PROFILE_SUCCESS",
    USER_PROFILE_FAILURE: "USER_PROFILE_FAILURE",
    
    MODIFY_DISPLAY_SETTINGS_REQUEST: "MODIFY_DISPLAY_SETTINGS_REQUEST",
    MODIFY_DISPLAY_SETTINGS_SUCCESS: "MODIFY_DISPLAY_SETTINGS_SUCCESS",
    MODIFY_DISPLAY_SETTINGS_FAILURE: "MODIFY_DISPLAY_SETTINGS_FAILURE",
    
    MODIFY_PRIVACY_SETTINGS_REQUEST: "MODIFY_PRIVACY_SETTINGS_REQUEST",
    MODIFY_PRIVACY_SETTINGS_SUCCESS: "MODIFY_PRIVACY_SETTINGS_SUCCESS",
    MODIFY_PRIVACY_SETTINGS_FAILURE: "MODIFY_PRIVACY_SETTINGS_FAILURE",


    SOCIAL_LOG_IN_PENDING: "UPDATING_SOCIAL_LOGIN_PENDING_STATE",

    REDIRECT_URL: "USER_REDIRECT_URL",
};