import * as React from "react";
import { CommonInterval } from "common";
import { ChartOptions, ChartDataSets, ChartData } from "chart.js";
import { Bar } from "react-chartjs-2";
import IntervalPropertySelector, { IntervalProperty } from "../IntervalPropertySelector/IntervalPropertySelector";
import { capitalize } from "lodash";
import "./IntervalChart.css";

interface IntervalChartDimension {
    height?: number;
    width?: number;
}

interface IntervalChartProps {
    intervals?: CommonInterval[];
    dimensions?: IntervalChartDimension;
    weightUnit: string;
}

interface IntervalChartState {
    activeProperty: IntervalProperty;
}

class IntervalChart extends React.Component<IntervalChartProps, IntervalChartState> {
    private readonly DATASET_STYLE: ChartDataSets = {
        lineTension: 0,
        fill: true,
        backgroundColor: "rgba(192,89,54,0.4)",
        borderColor: "rgba(192,89,54,1)",
        borderWidth: 1
    };

    constructor(props: IntervalChartProps) {
        super(props);
        this.state = {
            activeProperty: "duration"
        };
    }

    public handlePropertyChange = (activeProperty: IntervalProperty) => {
        this.setState({ activeProperty });
    }

    public render() {
        const data = this.getChartData();
        return (
            <React.Fragment>
                <div className="interval-property-selector">
                    <IntervalPropertySelector value={this.state.activeProperty} onChange={this.handlePropertyChange} />
                </div>
                <div className="interval-chart-wrapper">
                    <Bar {...this.props.dimensions} data={data} options={this.getChartOptions() as ChartOptions} />
                </div>
            </React.Fragment>
        );
    }

    private getIntervalProperty = (interval: CommonInterval) => {
        switch (this.state.activeProperty) {
            case "deviation":
                return interval.stdDeviation;
            case "strain":
                return interval.strain;
            case "strength":
                return 60 * interval.strain/(interval.end - interval.start);
            case "duration":
                return interval.end - interval.start;
            default:
                return 0;
        }
    }

    private getChartData = (): ChartData => {
        const intervals = this.props.intervals;
        if (!intervals) {
            return { datasets: [] };
        }
        const labels = Array.from(Array(intervals.length).keys()).map((value: number) => `${value + 1}`);
        const data: number[] = intervals.map((interval, index): number => this.getIntervalProperty(interval));

        return { labels, datasets: [{ ...this.DATASET_STYLE, data }] };
    }

    private getYAxisLabel = (): string => {
        const property = this.state.activeProperty;
        const unit: string = property === "duration" ? "s" : this.props.weightUnit;
        return `${capitalize(property)} (${unit})`;
    }

    private getUnits = (): string => {
        const property = this.state.activeProperty;
        const unit: string = property === "duration" ? " sec" : ` ${this.props.weightUnit}`;
        return `${unit}`;
    }

    private getChartOptions = () => {
        const units = this.getUnits();
        return {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false
            },
            scales: {
                display: true,
                yAxes: [{
                    ticks: {
                        min: 0,
                        maxTicksLimit: 5,
                        fontColor: "rgba(255, 255, 255,0.6)"
                    },
                    scaleLabel: {
                        display: true,
                        labelString: this.getYAxisLabel(),
                        fontColor: "rgba(255, 255, 255,0.95)"
                    },
                    gridLines: {
                        color: "rgba(255, 255, 255,0.1)"
                    }
                }],
                xAxes: [{
                    maxBarThickness: 50,
                    scaleLabel: {
                        display: true,
                        labelString: "Interval",
                        fontColor: "rgba(255, 255, 255,0.95)"
                    },
                    gridLines: {
                        color: "rgba(255, 255, 255,0.1)"
                    },
                    ticks: {
                        maxTicksLimit: 5,
                        fontColor: "rgba(255, 255, 255,0.6)"
                    }
                }],
            },
            tooltips: {
                callbacks: {
// tslint:disable-next-line: typedef
                    label: function(tooltipItem: { yLabel: any; }) {
                        return Number(tooltipItem.yLabel.toFixed(2)) + units;
                    }
                },
                backgroundColor: "#fff",
                titleFontSize: 0,
                titleFontColor: "#C05939",
                bodyFontColor: "#000",
                bodyFontSize: 14,
                displayColors: false
              }
        };
    }
}

export default IntervalChart; 