import * as React from "react";
import "./NotFound.css";
import * as wallBackground from "../../assets/images/V404_wall.png";
import { Segment } from "semantic-ui-react";

class NotFound extends React.Component {
    render() {
        return (
            <Segment style={{ backgroundImage: `url(${wallBackground})` }}>
                <div className="message-div" >
                    <h1>That's a V404!</h1>
                    <p>Try a wall of your level</p>
                </div>
            </Segment>
        );
    }
}

export default NotFound;
