import * as React from "react";
import { Card, Header, Form, Divider, Message } from "semantic-ui-react";
import { api, setToken } from "../../config/axiosConfig";
import { withRouter, RouteComponentProps, Redirect } from "react-router";
import * as querystring from "querystring";
import * as firebase from 'firebase/app';
import 'firebase/auth';

interface ResetPasswordFormState {
    requesting: boolean;
    email: string;
    newPassword: string;
    confirmPassword: string;
    success: boolean;
    errors: any;
    oobCode: string;
}

class ResetPasswordForm extends React.Component<RouteComponentProps<void>, ResetPasswordFormState> {
    constructor(props: RouteComponentProps<void>) {
        super(props);
        const query = this.props.location.search.replace("?", "");
        const oobCode = querystring.parse(query).oobCode as string || "";

        this.state = {
            requesting: false,
            email: "",
            newPassword: "",
            confirmPassword: "",
            oobCode: oobCode,
            success: false,
            errors: {},
        };
    }

    componentWillMount() {
        firebase.auth().verifyPasswordResetCode(this.state.oobCode).then((email)=>{
            this.setState({email:email});
        }).catch((e)=>{
            console.log(e);
            //this.goBacktoLogin();
        })
    }

    public handleInputChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { name, value } = event.target as HTMLInputElement;
        this.setState({ ...this.state, [name]: value.trim() });
    }

    public handleSubmit = async () => {
        if (this.isPasswordValid()) {
            this.setState({ errors: {} });
            this.sendResetRequest();
        } else {
            this.setState({
                errors: {
                    confirmPassword: "Does not match"
                }
            });
        }
    }

    public render() {
        const errors = this.state.errors;
        const {newPassword, confirmPassword} = this.state;
        return (
            <Card centered={true} className="signup-panel">
                <Card.Content>
                    <Header as="h1" textAlign="center">Recover Your Account</Header>
                    <Divider hidden={true} />
                    <Form onSubmit={this.handleSubmit} success={this.state.success}>
                        <Form.Input
                            name="email"
                            label="Email"
                            value={this.state.email}
                            disabled={true}
                        />
                        <Form.Field 
                            required={true} 
                            onChange={this.handleInputChange} 
                            error={"newPassword" in errors}
                        >
                            <label>New Password</label>
                            <input 
                                type="password" 
                                name="newPassword" 
                                placeholder="New Password" 
                                value={newPassword}
                            />
                            <p>{errors.newPassword}</p>
                        </Form.Field>
                        <Form.Field 
                            required={true} 
                            onChange={this.handleInputChange} 
                            error={"confirmPassword" in errors}
                        >
                            <label>Confirm Password</label>
                            <input 
                                type="password"
                                name="confirmPassword" 
                                placeholder="Confirm Password" 
                                value={confirmPassword}
                            />
                            <p>{errors.confirmPassword}</p>
                        </Form.Field>
                        <Form.Button color="orange">Submit</Form.Button>
                        <Message
                            success={true}
                            header="Request Completed!"
                            content="You've successfully change your password!"
                        />
                    </Form>
                </Card.Content>
            </Card>
        );
    }

    private isPasswordValid(): boolean {
        return this.state.newPassword === this.state.confirmPassword;
    }

    private sendResetRequest() {
        if (!this.state.requesting) {
            const requestURL = "/v1/auth/password/reset";
            this.setState({ requesting: true });
            api()
                .post(requestURL, { newPassword: this.state.newPassword, oobCode: this.state.oobCode })
                .then(response => {
                    this.setState({ requesting: false, success: true, errors: {} });
                    setTimeout(this.goBacktoLogin, 3000);
                })
                .catch(error => {
                    const errors = error.response.data.errors;
                    this.setState({ requesting: false, success: false, errors });
                });
        }
    }

    private goBacktoLogin = () => {
        this.props.history.push("/login");
    }
}

export default withRouter(ResetPasswordForm);