import { combineReducers } from "redux";
import { navBar } from "./navBar.reducer";
import { NavBarState } from "./navBar.reducer";
import { authentication, AuthenticationState } from "./authentication.reducer";
import { signup, SignUpState } from "./signup.reducer";
import { settings, SettingsState } from "./settings.reducer";
import { userProfile, UserProfileState } from "./userProfile.reducer";
import { activities, ActivitiesState } from "./activities.reducer";
import { workouts ,WorkoutsState} from "./workouts.reducer"
import { bluetooth, BluetoothState } from "./bluetooth.reducer";
import { admin, AdminState } from "./admin.reducer";
import { userConstants } from "../constants";

// Declare complete state reducer interface
export interface State {
    workouts: WorkoutsState;
    authentication: AuthenticationState;
    signup: SignUpState;
    settings: SettingsState;
    userProfile: UserProfileState;
    activities: ActivitiesState;
    admin: AdminState;
    navBar: NavBarState;
    bluetooth: BluetoothState;
}

// Combine Reducers
const appReducer = combineReducers({
    authentication,
    signup,
    settings,
    activities,
    admin,
    userProfile,
    workouts,
    navBar,
    bluetooth
});

const reducer = (state: any, action: any) => {
    if (action.type === userConstants.DISCONNECT) {
        state = undefined;
    }

    return appReducer(state, action);
};

export default reducer;