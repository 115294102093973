import { CommonWorkout } from "common";
import React from "react";
import "./Guidelines.css"
import { Rep } from "../../classes/Workout";
import { withTranslation, WithTranslation } from "react-i18next";
import Hands from '../../components/Hands/Hands';

interface guidelinesProps extends WithTranslation{
    rep: Rep
}
class Guidelines extends React.Component<guidelinesProps>{
    constructor(props: guidelinesProps) {
        super(props);
        this.state = {
            img: ''
        };
    }
    componentWillReceiveProps(nextProps){
        let imgHtml = null;
        //put (nextProps.rep.img =="") to make it works
        if(false){
            const imgRef = require(`../../assets/images/grips/${nextProps.rep.img}`);
            imgHtml = <img style={{objectFit: "cover", position: "fixed",left:0, top:0, zIndex: -3, width: '100vw'}} src={imgRef}/>;
        }
        this.setState({
            img: imgHtml
        });
        
    }

    render(){
        const guidelineValue = this.props.rep.intensity ?
            (<div className="guidelinesHands">
                <Hands height={150} fingers={this.props.rep.fingers}/>
            </div>)
            :
            <h3 className="guidelinesText">{this.props.t(this.props.rep.guideline)}</h3>;
        return (
            <div>
                {guidelineValue}
            </div>   
        );
    }
}

export default withTranslation('workout')(Guidelines);