import * as React from "react";
import { Segment, Button } from "semantic-ui-react";
import SettingRow from "../SettingRow/SettingRow";
import { CommonGym, CommonOrder, OrderStatus, Plan } from "common";
import OrderTable from "../OrderTable/OrderTable";
import { getAthleteGymOrdersRequest, getAthleteGymOrdersCountRequest } from "../../services/athlete";
import LoadingSegment from "../LoadingSegment/LoadingSegment";
import ModifySystemModal from "./ModifySystemModal";
import axios, { AxiosResponse } from "axios";
import { OrderService } from "../../services/orders";
import { cloneDeep } from "lodash";
import { PromiseManager } from "../../utils/PromiseUtils";
import SegmentHeader from "../SegmentHeader/SegmentHeader";
import { GymService } from "../../services/gyms";
import Formatter from "../../utils/Formatter";

interface GymOrderSectionProps {
    gym: CommonGym;
}

interface GymOrderSectionState {
    addModalOpen: boolean;
    returnModalOpen: boolean;
    orders: {
        done: CommonOrder[],
        inProgress: CommonOrder[],
    };
    plan?: Plan;
    currentReturnCount: number;
    requesting: boolean;
    success: boolean;
}

class GymOrderSection extends React.Component<GymOrderSectionProps, GymOrderSectionState> {
    private static readonly MAX_ADD_SYSTEMS_VALUE = 100;
    private promises: PromiseManager = new PromiseManager();

    constructor(props: GymOrderSectionProps) {
        super(props);
        this.state = {
            addModalOpen: false,
            returnModalOpen: false,
            orders: {
                done: [],
                inProgress: []
            },
            currentReturnCount: props.gym.systemCount,
            requesting: false,
            success: false
        };
    }

    public componentDidMount() {
        this.fetchData();
    }

    public componentWillUnmount() {
        this.promises.cancelAll();
    }

    public openAddModal = () => this.setState({ addModalOpen: true });
    public closeAddModal = () => this.setState({ addModalOpen: false });
    public openReturnModal = () => this.setState({ returnModalOpen: true });
    public closeReturnModal = () => this.setState({ returnModalOpen: false });

    public onAddingOrder = () => {
        this.closeAddModal();
        this.fetchData();
    }

    public onReturnOrder = () => {
        this.closeReturnModal();
        this.fetchData();
    }

    public render() {
        const gym = this.props.gym;
        const plan = this.state.plan;

        const price: string = plan ? Formatter.formatPrice(gym.systemCount * plan.amount / 100, plan.currency) : "N/A";

        return (
            <React.Fragment>
                <Segment className="with-header">
                    <SegmentHeader>Current Subscription</SegmentHeader>
                    <LoadingSegment isLoading={!this.state.success}>
                        <SettingRow title="Number of systems" content={gym.systemCount.toString()} />
                        <SettingRow title="Monthly payment" content={price} />
                        <div className="flex-container right">
                            <Button color="orange" onClick={this.openAddModal}>Get More</Button>
                            <Button color="blue" disabled={this.isReturnDisabled()} onClick={this.openReturnModal}>Return</Button>
                        </div>
                    </LoadingSegment>
                </Segment>
                <ModifySystemModal
                    gym={gym}
                    type="add"
                    open={this.state.addModalOpen}
                    maximum={GymOrderSection.MAX_ADD_SYSTEMS_VALUE}
                    onSuccess={this.onAddingOrder}
                    onCancel={this.closeAddModal}
                />
                <ModifySystemModal
                    gym={gym}
                    type="return"
                    open={this.state.returnModalOpen}
                    maximum={this.getMaximumReturnCount()}
                    onSuccess={this.onReturnOrder}
                    onCancel={this.closeReturnModal}
                />
                <Segment className="with-header">
                    <SegmentHeader>Orders In Progress</SegmentHeader>
                    <LoadingSegment isLoading={!this.state.success}>
                        <OrderTable actions={true} orders={this.state.orders.inProgress} onCancel={this.handleCancelOrder} />
                    </LoadingSegment>
                </Segment>
                <Segment className="with-header">
                    <SegmentHeader>Modifications History</SegmentHeader>
                    <LoadingSegment isLoading={!this.state.success}>
                        <OrderTable orders={this.state.orders.done} />
                    </LoadingSegment>
                </Segment>
            </React.Fragment>
        );
    }

    public getMaximumReturnCount() {
        return this.props.gym.systemCount - this.state.currentReturnCount;
    }

    public isReturnDisabled() {
        return this.getMaximumReturnCount() <= 0;
    }

    public fetchData() {
        if (!this.state.requesting) {
            this.getOrdersAndPlan();
            this.getCurrentReturnCount();
        }
    }

    public getOrdersAndPlan() {
        this.setState({ requesting: true });
        const ordersDonePromise = this.promises.add(
            getAthleteGymOrdersRequest(this.props.gym.id, { status: [OrderStatus.DONE, OrderStatus.REJECTED] }));
        const ordersInProgressPromise = this.promises.add(
            getAthleteGymOrdersRequest(this.props.gym.id, { status: [OrderStatus.SHIPPING, OrderStatus.PENDING] }));
        const planPromise = this.promises.add(GymService.getStandardPlan());

        Promise.all([ordersInProgressPromise, ordersDonePromise, planPromise])
            .then(axios.spread((inProgressRes: AxiosResponse, doneRes: AxiosResponse, planRes: AxiosResponse) => {
                const orders = {
                    inProgress: inProgressRes.data,
                    done: doneRes.data
                };
                this.setState({ requesting: false, success: true, orders, plan: planRes.data });
            })).catch((error) => {
                if (!error.isCanceled) {
                    this.setState({ requesting: false, success: false });
                }
            });
    }

    public getCurrentReturnCount() {
        const promise = this.promises.add(getAthleteGymOrdersCountRequest(
            this.props.gym.id,
            { orderType: "return", status: [OrderStatus.SHIPPING, OrderStatus.PENDING] })
        );

        promise.then((response) => {
            this.setState({ currentReturnCount: Math.abs(response.data) });
        })
            .catch((error) => {
                if (!error.isCanceled) {
                    this.setState({ currentReturnCount: this.props.gym.systemCount });
                }
            });
    }

    public handleCancelOrder = (orderId: number) => {
        const promise = this.promises.add(OrderService.remove(orderId));
        promise.then(() => {
            const orders = cloneDeep(this.state.orders);
            const index = orders.inProgress.findIndex((order) => order.id === orderId);
            if (index >= 0) {
                orders.inProgress.splice(index, 1);
            }
            this.setState({ orders });
        }).catch(() => {
            // Nothing happens if there is an error
        });
    }
}

export default GymOrderSection;