import * as React from 'react';
import { CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements';
import './GymFormPayment.css';
import { Form } from 'semantic-ui-react';

class GymFormPayment extends React.Component {
  render() {
    return (
      <Form.Group>
        <Form.Field width={10}>
          <label>Credit Card Number</label>
          <CardNumberElement />
        </Form.Field>
        <Form.Field width={3}>
          <label>Expiration Date</label>
          <CardExpiryElement />
        </Form.Field>
        <Form.Field width={3}>
          <label>CVC</label>
          <CardCVCElement />
        </Form.Field>
      </Form.Group>
    );
  }
}

export default GymFormPayment;
