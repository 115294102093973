/* eslint-disable react/jsx-filename-extension */
import * as React from 'react';
import './PrivacyPolicy.css';
import { WithTranslation, withTranslation } from 'react-i18next';

interface PrivacyPolicyProps extends WithTranslation{}

class PrivacyPolicy extends React.Component<PrivacyPolicyProps> {
  render() {
    return (
        <div className="ui two column centered grid login-page">
            <div className="column paragraphStyle">
            <h1>{this.props.t('privacyPolicyHeader.header')}</h1>
              <p>{this.props.t('privacyPolicyHeader.content')}</p>
              <h1>{this.props.t('infoCollectionAndUse.header')}</h1>
              <p>{this.props.t('infoCollectionAndUse.content')}</p>
              <p>{this.props.t('infoCollectionAndUse.links')}</p>
              <li><a href="https://www.google.com/policies/privacy/">{this.props.t('infoCollectionAndUse.googlePlay')}</a></li>
              <li><a href="https://firebase.google.com/policies/analytics">{this.props.t('infoCollectionAndUse.googleAnalytics')}</a></li>
              <li><a href="https://firebase.google.com/support/privacy/">{this.props.t('infoCollectionAndUse.firebase')}</a></li>
              <li><a href="https://www.facebook.com/about/privacy/update/printable/">{this.props.t('infoCollectionAndUse.facebook')}</a></li>
              <h1>{this.props.t('logData.header')}</h1>
              <p>{this.props.t('logData.content')}</p>
              <h1>{this.props.t('cookies.header')}</h1>
              <p>{this.props.t('cookies.cookiesExplained')}</p>
              <p>{this.props.t('cookies.content')}</p>
              <h1>{this.props.t('serviceProviders.header')}</h1>
              <p>{this.props.t('serviceProviders.reasonsExplained')}</p>
              <ul>
                <li>{this.props.t('serviceProviders.reason_1')}</li>
                <li>{this.props.t('serviceProviders.reason_2')}</li>
                <li>{this.props.t('serviceProviders.reason_3')}</li>
                <li>{this.props.t('serviceProviders.reason_4')}</li>
              </ul>
              <p>{this.props.t('serviceProviders.content')}</p>
              <h1>{this.props.t('security.header')}</h1>
              <p>{this.props.t('security.content')}</p>
              <h1>{this.props.t('links.header')}</h1>
              <p>{this.props.t('links.content')}</p>
              <h1>{this.props.t('children.header')}</h1>
              <p>{this.props.t('children.content')}</p>
              <h1>{this.props.t('changes.header')}</h1>
              <p>{this.props.t('changes.content')}</p>
              <h1>{this.props.t('contact.header')}</h1>
              <p>{this.props.t('contact.content')} <a href="mailto:info@entralpi.com">info@entralpi.com</a>.</p>      
            </div>
            
        </div>
    );
  }
}

export default withTranslation('privacyPolicy')(PrivacyPolicy);
