import { userConstants } from "../constants";

export interface SignUpFormErrors {
    email?: string;
    password?: string;
    firstName?: string;
    lastName?: string;
}

export const updateSignUpErrors = (errors: SignUpFormErrors) => {
    return {
        type: userConstants.SIGNUP_UPDATE_ERRORS,
        errors: errors
    };
};