import * as React from "react";
import { Container, Icon } from "semantic-ui-react";
import "../../../containers/Exercise/Exercise.css";

interface NavigationProps {
  leftIcon: string;
  leftIconAction: any;
  middleIcon: string;
  middleIconAction: any;
  rightIcon: string;
  rightIconAction: any;
}

interface NavigationState {
  redirect: boolean;
}

class Navigation extends React.PureComponent<NavigationProps, NavigationState> {
  constructor(props: NavigationProps) {
    super(props);
    this.state = { redirect: false };
  }

  render() {
    return (
      <Container
        style={{
        position: "absolute",
        marginLeft: "auto",
        marginRight: "auto",
        left: "0",
        right: "0",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
        }}>
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center"
          }}
        >
          <Icon
            name={this.props.leftIcon}
            style={{ fontSize: "1.5em", margin: "auto .3em" }}
            onClick={this.props.leftIconAction}
          />
          <Icon
            name={this.props.middleIcon}
            className={"material-icon icon "}
            style={{ fontSize: "3.5em", margin: "auto .3em" }}
            onClick={this.props.middleIconAction}
          />
          <Icon
            name={this.props.rightIcon}
            style={{ fontSize: "1.5em", margin: "auto .3em" }}
            onClick={this.props.rightIconAction}
          />
        </Container>
      </Container>
    );
  }
}

export default Navigation;
