import { userConstants } from "./../constants/user.constants";
import { settingsConstants } from "./../constants/settings.constants";
import * as axiosConfig from "../config/axiosConfig";
import { CommonUser } from "common";
import { resetUI } from "../utils/bluetooth";
import i18next from '../i18n';

export interface AuthenticationState {
    requesting: boolean;
    login: boolean;
    redirectURL?: string;
    user?: CommonUser;
    token: string;
    errors: boolean;
}

const defaultState = {
    requesting: false,
    login: false,
    redirectURL: undefined,
    user: undefined,
    token: "",
    errors: false
};

export const authentication = (state: AuthenticationState = defaultState, action: any) => {
    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                requesting: true,
                login: false
            };
        case userConstants.LOGIN_SUCCESS:
            axiosConfig.setToken(action.token);
            return {
                ...state,
                requesting: false,
                login: true,
                user: action.user,
                token: action.token,
            };
        case userConstants.LOGIN_FAILURE:
            return {
                ...state,
                requesting: false,
                login: false,
                errors: true
            };
        case userConstants.LOGIN_FACEBOOK_SUCCESS:
            axiosConfig.setToken(action.token);
            return {
                ...state,
                requesting: false,
                login: true,
                user: action.user,
                token: action.token,
            };
        case userConstants.LOGIN_FACEBOOK_FAILURE:
            return {
                ...state,
                requesting: false,
                login: false,
                errors: true
            };
        case userConstants.MODIFY_SUCCESS:
            return {
                ...state,
                user: action.data
            };
        case settingsConstants.FETCH_SUCCESS:
            return { ...state, user: action.user };
        case settingsConstants.FETCH_FAILURE:
            const status: string = action.data.status.toString();
            if (status[0] === "4") {
                return { ...state, login: false, user: undefined, errors: true };
            }
            return { ...state };
        case settingsConstants.IMAGE_UPLOAD_SUCCESS:
            let user = { ...state.user } as CommonUser;
            user.imageUrl = action.imageUrl;
            return { ...state, user: user };
        case userConstants.MODIFY_DISPLAY_SETTINGS_SUCCESS:
            if(state.user.displaySettings.lang != action.data.lang){
                i18next.changeLanguage(action.data.lang);
            }
            return {
                ...state,
                user: { ...state.user, displaySettings: action.data }
            };
        case userConstants.MODIFY_PRIVACY_SETTINGS_SUCCESS:
            return{
                ...state,
                user:{...state.user, privacySettings: action.data}
            };
        case userConstants.REDIRECT_URL:
            return { ...state, redirectURL: action.url };
        default:
            return { ...state };
    }
};
