import * as React from "react";
import { Menu, MenuItemProps } from "semantic-ui-react";

export type IntervalProperty = "duration" | "strength" | "deviation" | "strain";

interface IntervalPropertySelectorProps {
    value?: IntervalProperty;
    onChange?: (property: IntervalProperty) => any;
}

class IntervalPropertySelector extends React.Component<IntervalPropertySelectorProps> {

    public handleClick = (event: any, { name }: MenuItemProps) => {
        if (this.props.onChange) {
            this.props.onChange(name as IntervalProperty);
        }
    }

    public render() {
        return (
            <Menu widths={4}>
                <Menu.Item name="duration" active={this.isActive("duration")} color="orange" onClick={this.handleClick} />
                <Menu.Item name="strength" active={this.isActive("strength")} color="orange" onClick={this.handleClick} />
                <Menu.Item name="deviation" active={this.isActive("deviation")} color="orange" onClick={this.handleClick} />
                <Menu.Item name="strain" active={this.isActive("strain")} color="orange" onClick={this.handleClick} />
            </Menu>
        );
    }

    private isActive = (property: IntervalProperty) => {
        return this.props.value === property;
    }
}

export default IntervalPropertySelector;