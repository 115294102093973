import JSZip from "jszip";
import { AxiosResponse } from "axios";
import { api } from "../config/axiosConfig";

import { Interval } from "../classes/Interval";
export let _weightDataArray: [[number, number]];
export var _beginningTime: any;
export var date: any;
// import { max } from "d3";

const startTimer = () => {
  date = Date.now();
};
export const resetTimer =() =>{
  date = 'NaN';
};

export const timeSinceBeginning = () => {
  return (Date.now() - date) / 1000;
};

export const addDataToString = async (data: number) => {
  return new Promise((resolve)=>{
    if(data!==null){
      let time = timeSinceBeginning();
      _weightDataArray.push([data, time]);
    }
    resolve();
  });
  
};

export const dataArrayDifferential =() =>{
  const intervThreshold = 3; // [kg]
  if((_weightDataArray[_weightDataArray.length-2][0] != 0) && 
  ((_weightDataArray[_weightDataArray.length-2][0] < intervThreshold) &&
  (_weightDataArray[_weightDataArray.length-1][0] >= intervThreshold))){
    return 1;
  }else if((_weightDataArray[_weightDataArray.length-2][0] > intervThreshold) && 
  (_weightDataArray[_weightDataArray.length-1][0] <= intervThreshold)){
    return -1;
  }
}

export const freeDataArray = () => {
  _weightDataArray = [[0,0]];
};

export const arrayToCSV = (data: any) => {
  var csv = "";
  data.forEach(function(row: any) {
          csv += row.join(",");
          csv += "\n";
  });

  return csv;
};


export const getResultsFromData = (data: any, userWeight: number, activityType: String, structuredIntervals?: Array<Interval>) => {
  const intervals = activityType == 'raw' ? Algorithm.generateIntervals(data, userWeight): 
    Algorithm.generateIntervalsParams(data, userWeight, structuredIntervals);
  const activity = Algorithm.setActivityStructure(intervals);

  let result = {
    "n_intervals": intervals.length ? intervals.length : 1,
    "interval_count": intervals.length ? intervals.length : 1,
    "intervals":  intervals,
    "total_strain": activity.total_strain ? activity.total_strain : -1,
    "avg_strain": activity.avg_strain ? activity.avg_strain : -1,
    "avg_interval_duration": activity.avg_interval_duration ? activity.avg_interval_duration : -1,
    "duration_variation": 0,
    "max_strength":  activity.max_strength ? activity.max_strength : -1, // maxStrength(_weightDataArray),
    "strengthVariation": 0
};
  return result;
};


const zipCSV = (csvContent: string) => {
  var zip = new JSZip();
  zip.file("Data.csv", csvContent);
  let archive = zip.generateAsync({type : "base64"});
  return archive;
};

export async function onEnd (userWeight: any, activityType: any,addedWeight:number, intervals?: Array<Interval>) {
  let now = new Date().toISOString();
  const archive = ({
      "type": activityType.selectedWorkout ? activityType.selectedWorkout : "raw", 
      "subtype": activityType.type ? activityType.type : "none",
      "date": now ? now : "unknown time", 
      "title": activityType.title ? activityType.title : "Free hanging", 
      "tag": `${activityType.notes}`,
      "data": _weightDataArray, 
      "user_weight": userWeight ? userWeight : 150, 
      //"result": results,
      "added_weight": addedWeight,
      "notes": `${activityType.notes}`,
      "intervals": intervals
  });
  freeDataArray();
  return archive;
};

export const onStart = () => {
  startTimer();
  _weightDataArray = [];
};

export const pulledWeight = (setup: any, userWeight: number, forcePlateData: number, addedWeight: number) => {
  var weight;
  switch (setup) {
    case "hangboard":
      if (userWeight + addedWeight - forcePlateData < 0.15) 
        return 0;
       else if (forcePlateData < 0.01)
        return "Out"; // out
      else 
        weight = Math.round(10 * (userWeight + addedWeight - forcePlateData)) / 10;
      return weight.toFixed(1);
    case "portable":
      weight = Math.round(10 * forcePlateData) / 10;
      return weight.toFixed(1);

    case "lift":
      if (forcePlateData < 0.15) {
        return "Out"; // out
      } else if (forcePlateData - userWeight - addedWeight < 0.15) {
        return 0;
      } else {
        weight = Math.round(10 * (forcePlateData - userWeight - addedWeight)) / 10;
      }
      return weight.toFixed(1);
    default:
      if (userWeight + addedWeight - forcePlateData < 0.15) {
        return 0;
      } else if (forcePlateData < 0.15) {
        return "Out"; // out
      } else {
        weight = Math.round(10 * (userWeight + addedWeight - forcePlateData)) / 10;
      }
      return weight.toFixed(1);
  }
};