import * as React from "react";
import { Table, Label, Button } from "semantic-ui-react";
import { CommonOrder, OrderStatus } from "common";

interface OrderTableRowProps {
    order: CommonOrder;
    actions?: boolean;
    onCancel?: (orderId: number) => void;
}

class OrderTableRow extends React.Component<OrderTableRowProps> {
    public render() {
        const order = this.props.order;
        return (
            <Table.Row>
                <Table.Cell>{this.formatDate(order.createdDate)}</Table.Cell>
                <Table.Cell>{this.getType()}</Table.Cell>
                <Table.Cell>{Math.abs(order.count)}</Table.Cell>
                <Table.Cell><Label size="small" color="blue">{OrderStatus.toString(order.status)}</Label></Table.Cell>
                {this.renderActions()}
            </Table.Row>
        );
    }

    public getType(): string {
        return this.props.order.count < 0 ? "Return" : "Add";
    }

    public formatDate = (date: Date) => {
        return new Date(date).toLocaleDateString();
    }

    public renderActions = () => {
        const button = <Button basic={true} size="mini" color="blue" onClick={this.handleCancel}>Cancel</Button>;
        const cancel = this.props.order.status === OrderStatus.PENDING ?  button : null;
        return this.props.actions ? <Table.Cell>{cancel}</Table.Cell> : null;
    }

    public handleCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel(this.props.order.id);
        }
    }
}

export default OrderTableRow;
