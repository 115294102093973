import { adminConstants } from "../constants";
import { api } from "../config/axiosConfig";
import { AxiosRequestConfig, AxiosError } from "axios";
import { GetAdminListResponse, OrderStatus, CommonOrder } from "common";
import { Dispatch } from "redux";
import { OrderService } from "../services/orders";
import axios from "axios";
import AxiosUtils from "../utils/AxiosUtils";

export const getAdminList = () => {
    const request = () => (
        { type: adminConstants.ADMIN_LIST_REQUEST }
    );

    const success = (data: GetAdminListResponse) => (
        { type: adminConstants.ADMIN_LIST_SUCCESS, data }
    );

    const failure = (data: GetAdminListResponse) => (
        { type: adminConstants.ADMIN_LIST_FAILURE, data }
    );

    const requestURL = "/v1/admin/management/list/";

    return (dispatch: Dispatch<void>) => {
        dispatch(request());
        api().get(requestURL)
            .then((response) => {
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(failure(error.response.data));
            });
    };
};

export const removeAdmin = (data?: any) => {
    const request = () => (
        { type: adminConstants.REMOVE_ADMIN_REQUEST }
    );

    const success = () => (
        { type: adminConstants.REMOVE_ADMIN_SUCCESS }
    );

    const failure = (status: number) => (
        { type: adminConstants.REMOVE_ADMIN_FAILURE, status }
    );

    const requestURL = "/v1/admin/management/remove";

    let config: AxiosRequestConfig = data;
    return (dispatch: Dispatch<void>) => {
        dispatch(request());
        return api().patch(requestURL, config)
            .then((response) => {
                dispatch(success());
                dispatch(getAdminList());
                return response;
            })
            .catch((error: AxiosError) => {
                dispatch(failure(AxiosUtils.getStatus(error.response)));
                return error;
            });
    };
};

export const addAdmin = (data?: any) => {
    const request = () => (
        { type: adminConstants.ADD_ADMIN_REQUEST }
    );

    const success = () => (
        { type: adminConstants.ADD_ADMIN_SUCCESS }
    );

    const failure = () => (
        { type: adminConstants.ADD_ADMIN_FAILURE }
    );

    const requestURL = "/v1/admin/management/add";

    let config: AxiosRequestConfig = data;
    return (dispatch: Dispatch<void>) => {
        dispatch(request());
        api().patch(requestURL, config)
            .then(() => {
                dispatch(success());
                dispatch(getAdminList());
            })
            .catch((error) => {
                dispatch(failure());
            });
    };
};

export const addAdminError = (error: string) => {
    return {
        type: adminConstants.ADD_ADMIN_ERROR,
        error: error,
    };
};

export const resetAddAdmin = () => {
    return {
        type: adminConstants.RESET_ADD_ADMIN,
    };
};

export const getOrders = () => {
    const request = () => ({ type: adminConstants.ORDERS_REQUEST });

    const success = (awaitingOrders: any, doneOrders: any) => {
        const data = { awaitingOrders, doneOrders };
        return { type: adminConstants.ORDERS_SUCCESS, data };
    };

    const failure = () => ({ type: adminConstants.ORDERS_FAILURE });

    return (dispatch: Dispatch<void>) => {
        const awaiting = OrderService.getMany({ status: [OrderStatus.PENDING, OrderStatus.SHIPPING] });
        const done = OrderService.getMany({ status: [OrderStatus.DONE, OrderStatus.REJECTED], limit: 20 });
        dispatch(request());
        return Promise.all([awaiting, done])
            .then(axios.spread((awaitingRes, doneRes) => { dispatch(success(awaitingRes.data, doneRes.data)); }))
            .catch((error) => { dispatch(failure()); });
    };
};

export const updateOrderStatus = (id: number, status: OrderStatus) => {
    const request = () => ({ type: adminConstants.UPDATE_ORDER_STATUS_REQUEST });
    const success = (order: CommonOrder) => ({ type: adminConstants.UPDATE_ORDER_STATUS_SUCCESS, data: order } );
    const failure = () => ({ type: adminConstants.UPDATE_ORDER_STATUS_FAILURE });

    return (dispatch: Dispatch<void>) => {
        dispatch(request());
        return OrderService.updateStatus(id, status)
            .then((response) => {
                dispatch(success(response.data));
            })
            .catch((error) => {
                dispatch(failure());
            });
    };
};

export const selectOrder = (order: CommonOrder) => ({ type: adminConstants.SELECT_ORDER, data: order });

export const openStatusModal = () => ({ type: adminConstants.OPEN_STATUS_CONFIRMATION_MODAL });

export const closeStatusModal = () => ({ type: adminConstants.CLOSE_STATUS_CONFIRMATION_MODAL });
