import * as React from 'react';
import { Segment, Form, Icon } from 'semantic-ui-react';
import { SyntheticEvent } from 'react';
import { ActivityFiltersState } from '../../reducers/activities.reducer';
import { WithTranslation, withTranslation } from 'react-i18next';

interface ActivityFiltersProps extends WithTranslation {
    onSubmit?: (filters: ActivityFiltersState) => void;
}

class ActivityFilters extends React.Component<ActivityFiltersProps, ActivityFiltersState> {
    state = {
      title: '',
      type: 'all',
    };

    private selectOptions = [
      { key: 'All', text: this.props.t('search.all'), value: 'all' },
      { key: 'Test', text: this.props.t('search.test'), value: 'test' },
      { key: 'Training', text: this.props.t('search.training'), value: 'training' },
      { key: 'Raw', text: this.props.t('search.raw'), value: 'raw' },
    ];

    public handleInputChange = (event: SyntheticEvent<HTMLElement>, { value }: { value: string }) => {
      this.setState({ title: value });
    }

    public handleSelectChange = (event: any) => {
      this.setState({ type: event.target.value });
    }

    public handleSubmit = () => {
      if (this.props.onSubmit) {
        this.props.onSubmit(this.state);
      }
    }

    public render() {
      return (
        <Segment>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group>
              <Form.Input
                label={this.props.t('search.label')}
                icon
                placeholder={this.props.t('search.searchBar')}
                width={7}
                onChange={this.handleInputChange}
              >
                <input />
                <Icon name="search" />
              </Form.Input>
              <Form.Select
                value={this.state.type}
                label={this.props.t('search.activityType')}
                options={this.selectOptions}
                width={7}
                onChange={this.handleSelectChange}
              />
              <Form.Field>
                <label><br /></label>
                <Form.Button
                  content={this.props.t('btn.apply')}
                  primary
                  width={2}
                />
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
      );
    }
}

export default withTranslation('common')(ActivityFilters);
