import * as React from "react";
import { Link } from "react-router-dom";
import { Header, Grid, Item } from "semantic-ui-react";

class AdminHome extends React.Component {
    render () {
        return (
            <React.Fragment>
                <Grid>
                    <Grid.Column>
                        <Header as="h1" dividing={true}>Administration Actions</Header>
                        <Item.Group>
                            <Item>
                                <Item.Content>
                                    <Item.Header as="a">Metrics Dashboard</Item.Header>
                                    <Item.Description>
                                        Presents a lot of meaningful metrics about the web application.
                                    </Item.Description>
                                </Item.Content>
                            </Item>
                            <Item>
                                <Item.Content>
                                    <Item.Header as={Link} to="/admin/gyms">Manage Gyms</Item.Header>
                                    <Item.Description>
                                        Access a menu to manage all gyms.
                                    </Item.Description>
                                </Item.Content>
                            </Item>
                            <Item>
                                <Item.Content>
                                    <Item.Header as={Link} to="/admin/rights">Add an administrator</Item.Header>
                                    <Item.Description>
                                        Give administrator rights to another user.
                                    </Item.Description>
                                </Item.Content>
                            </Item>
                            <Item>
                                <Item.Content>
                                    <Item.Header as={Link} to={{pathname:"/exercise-creator", state:{createFeaturedWorkout:true}}}>Create workout</Item.Header>
                                    <Item.Description>
                                        Create a featured workout visible to all
                                    </Item.Description>
                                </Item.Content>
                            </Item>
                        </Item.Group>
                    </Grid.Column>
                </Grid>
            </React.Fragment>
        );
    }
}

export default AdminHome;