import * as sensorDataAnalysis from './sensorDataAnalysis';
import { resolve } from 'dns';

export const bluetoothConstants = {
  WEIGHT_DATA_STREAM_UUID: '0000fff4-0000-1000-8000-00805f9b34fb',
  BATTERY_LEVEL_UUID: '00002a19-0000-1000-8000-00805f9b34fb',
  DEVICE_NAME_UUID: '00002a23-0000-1000-8000-00805f9b34fb',

  SERVICE0: '0000180a-0000-1000-8000-00805f9b34fb',
  SERVICE1: 0x1800,
  SERVICE2: 0x1801,
  SERVICE3: 0x181d, // Entralpi UUID
  // SERVICE3: "0000fff0-0000-1000-8000-00805f9b34fb", // Health Scale UUID
  SERVICE4: 0x180f,
  SERVICE5: 'f000ffc0-0451-4000-b000-000000000000',
  SERVICE6: '0000fff0-0000-1000-8000-00805f9b34fb',
  SERVICE7: '0000fff1-0000-1000-8000-00805f9b34fb', // Entralpi UUID
  //   SERVICE7: "0000fff4-0000-1000-8000-00805f9b34fb",

  SIMPLEPROFILE_SERV_UUID: 0x181D,
  SIMPLEPROFILE_CHAR1_UUID: 0xFFF1,
  SIMPLEPROFILE_CHAR2_UUID: 0xFFF2,
  SIMPLEPROFILE_SERVICE: 0x00000001,
};

export var weightData: number = 0;
export var _connected: boolean = false;
export var _servicesDiscovered: boolean = false;
export var _notificationsEnabled: boolean = false;
export var _selectedDevice: any;
export var _connectedServer: any;
export var _hasWeightService: boolean = false;
export var _hasWeightData: boolean = false;
export var _hasDeviceNameService: boolean = false;
export var _hasDeviceNameString: boolean = false;
export var _weightDataStreamUuid: any = {};
export var _deviceNameService: any;

export const setNotificationsStatus = (status: boolean) => {
  _notificationsEnabled = status;
};

export const setConnectedStatus = (status: boolean) => {
  _connected = status;
  if (status === true) {
    // document.getElementById("btn_scan").innerHTML = "Disconnect";
  } else {
    //  document.getElementById("btn_scan").innerHTML = "Discover Devices";
  }
};

export const setDiscoveryStatus = (status: boolean) => {
  _servicesDiscovered = status;
};

export const resetUI = () => {
  setConnectedStatus(false);
  setDiscoveryStatus(false);
  setNotificationsStatus(false);
  // document.getElementById("device_name").innerHTML = "";
  // document.getElementById("accelerometer_data").innerHTML = "";
};

export const onDisconnected = () => {
  // TODO SET CONNECTION STATUS
  resetUI();
};

export const connect = () => {
  return new Promise((resolve)=>{
    if (_connected === false) {
      console.log('connecting');
      _selectedDevice.gatt.connect().then(
        (server: any) => {
          console.log(`Connected to ${server.device.id}`);
          console.log(`connected=${server.connected}`);
          setConnectedStatus(true);
          _connectedServer = server;
          _selectedDevice.addEventListener(
            'gattserverdisconnected',
            onDisconnected,
          );
          discoverSvcsAndChars().then(()=>{
            resolve('connection successful');
          });
        },
        (error: any) => {
          console.log(`ERROR: could not connect - ${error}`);
          alert(`ERROR: could not connect - ${error}`);
          setConnectedStatus(false);
        },
      );
    }
  });
  
};

export const discoverDevices = () => {
  return new Promise((resolve, reject)=>{
    console.log('discoverDevices');
    const options = {
      filters: [{ namePrefix: 'ENTRALPI' }],
      optionalServices: [
        bluetoothConstants.WEIGHT_DATA_STREAM_UUID,
        bluetoothConstants.SERVICE7,
        bluetoothConstants.SERVICE0,
        bluetoothConstants.SERVICE1,
        bluetoothConstants.SERVICE2,
        bluetoothConstants.SERVICE3,
        bluetoothConstants.SERVICE4,
        bluetoothConstants.SERVICE5,
        bluetoothConstants.SERVICE6,
        bluetoothConstants.SIMPLEPROFILE_SERV_UUID,
        bluetoothConstants.SIMPLEPROFILE_CHAR1_UUID,
        bluetoothConstants.SIMPLEPROFILE_CHAR2_UUID,
        bluetoothConstants.SIMPLEPROFILE_SERVICE,
      ],
    };
    console.log(options);
    console.log(navigator);
    const mobileNavigatorObject: any = window.navigator;
    if (mobileNavigatorObject && mobileNavigatorObject.bluetooth) {
    // Here write your logic of mobileNavigatorObject.bluetooth.requestDevice();
      mobileNavigatorObject.bluetooth
        .requestDevice(options)
        .then((device: any) => {
          console.log(`> Name: ${device.name}`);
          console.log(`> Id: ${device.id}`);
          console.log(device.gatt);
          console.log(`> Connected: ${device.gatt.connected}`);
          _selectedDevice = device;
          console.log(_selectedDevice);
          connect().then(()=>{
            resolve('successfully discovered the device');
          });
        })
        .catch((error: any) => {
          alert(`ERROR: ${error}`);
          console.log(`ERROR: ${error}`);
          reject();
        });
    }
  })
  
};

export const discoverDevicesOrDisconnect =() => {
  return new Promise((resolve, reject)=>{
    console.log('discoverDevicesOrDisconnect');

  if (!_connected) {
    discoverDevices().then(()=>{resolve('now connected')}).catch((error)=>{
      reject(error);
    });
  } else {
    // TODO disconnect from the current device
  }
  })
};

export const toggleWeightDataNotifications = () => {
  return new Promise((resolve)=>{
    if (!_connected) {
      alert('Error: Discover and connect to a device before using this function');
      return;
    }
    if (!_servicesDiscovered) {
      alert('Error: Service discovery has not yet completed');
      return;
    }
    if (!_hasWeightService) {
      alert('Error: The connected device does not contain the weight data service');
      return;
    }
    if (!_hasWeightData) {
      alert('Error: The connected device does not contain the weight data characteristic');
      return;
    }
    if (!_notificationsEnabled) {
      _weightDataStreamUuid.startNotifications().then(() => {
        setNotificationsStatus(true);
        _weightDataStreamUuid.addEventListener('characteristicvaluechanged', onWeightData);
      })
        .catch((error: any) => {
          alert(`Error: ${error}`);
        });
    } else {
      _weightDataStreamUuid.stopNotifications()
        .then(() => {
          setNotificationsStatus(false);
          _weightDataStreamUuid.removeEventListener('characteristicvaluechanged', onWeightData);
        })
        .catch((error: any) => {
        });
    }
    resolve('toggleWeightDataNotifications');
  });
};

export const discoverSvcsAndChars = () => {
  return new Promise((resolve)=>{
    try {
      _connectedServer.getPrimaryService(bluetoothConstants.SERVICE3).then(
        (services: any) => {
          services.getCharacteristic(bluetoothConstants.SERVICE7).then(
            (service: any) => {
              _weightDataStreamUuid = service;
              _hasWeightService = true;
              _hasWeightData = true;
              setDiscoveryStatus(true);
              toggleWeightDataNotifications().then(()=>{
                resolve('discoverSvcsAndChars');
              });
            },
          );
        },
        (error: any) => {
          alert(`ERROR: could not connect - ${error}`);
        },
      );
    } catch (error) {
      _connectedServer.getPrimaryService(bluetoothConstants.SERVICE3).then(
        (services: any) => {
          services.getCharacteristic(bluetoothConstants.SERVICE7).then(
            (service: any) => {
              _weightDataStreamUuid = service;
              _hasWeightService = true;
              _hasWeightData = true;
              setDiscoveryStatus(true);
              toggleWeightDataNotifications();
            },
          );
        },
        (error: any) => {
          alert(`ERROR: could not connect - ${error}`);
        },
      );
    }
  });
};


export const readDeviceName = () => {

  // state validation
  if (!_connected) {
    alert('Error: Discover and connect to a device before using this function');
    return;
  }
  if (!_servicesDiscovered) {
    alert('Error: Service discovery has not yet completed');
    return;
  }
  if (!_hasDeviceNameService) {
    alert('Error: The connected device does not contain the device informationservice');
    return;
  }
  if (!_hasDeviceNameString) {
    alert('Error: The connected device does not contain the model name stringcharacteristic');
    return;
  }
  _deviceNameService.readValue().then((value: { buffer: Iterable<number>; }) => {
    const data = new Uint8Array(value.buffer);
    /* let device_name_string = new TextDecoder("utf-8").decode(data);
            console.log(device_name_string); */
    // document.getElementById("device_name").innerHTML = device_name_string;
  })
    .catch((error: any) => {
      alert(`Error: ${error}`);
    });
};

export const onWeightData = (event: any) => {
  try {
    const { buffer } = event.target.value;
    const dataview = new DataView(buffer);
    weightData = dataview.getUint16(0) / 100;
    // sensorDataAnalysis.addDataToString(weightData);
    // console.log("Weight =" + weightData);
  } catch (e) {
    console.log(e);
  }
};
