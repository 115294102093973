import React, { useState } from 'react';
import TrainingBloc from './TrainingBloc';
import { Button, Card } from 'semantic-ui-react';
import RestBloc from './RestBloc';
import { TrainingBlocClass, IBloc, IBlocGroup } from '../classes';
interface IExercciseContainerProps{
    blocGroupContainer: [IBlocGroup];
    isAdmin:boolean
}


const ExerciseContainerEdit =(props: IExercciseContainerProps) =>{
    const displayBlocGroup = () => {
        return props.blocGroupContainer.map((blocGroup: IBlocGroup) =>
            <Card>
                <Card.Header>
                    <Button negative onClick={() => blocGroup.deleteSelf(blocGroup.id)}> X </Button>
                </Card.Header>
                <Card.Content>
                    <h3>Muliplicator</h3>
                    <Button onClick={() => blocGroup.setMultiplier('+')}> + </Button>{blocGroup.multiplier}<Button onClick={() => blocGroup.setMultiplier('-')}> - </Button>
                </Card.Content>
                <Card.Content>
                    {displayBlocs(blocGroup.blocs, blocGroup.deleteBloc)}
                </Card.Content>
                <Card.Content>
                    <Button onClick={() => blocGroup.addBloc("hang")}> Add Hang </Button>
                    <Button onClick={() => blocGroup.addBloc("rest")}> Add Rest </Button>
                </Card.Content>
            </Card>
        );
    }
    const displayBlocs=(blocs: [IBloc], deleteBloc)=>{
        return blocs.map((bloc: IBloc)=>{
            if (bloc instanceof TrainingBlocClass)
                return (<TrainingBloc bloc={bloc} handleDelete={deleteBloc} isAdmin={props.isAdmin}/>)
            else
                return (<RestBloc bloc={bloc} handleDelete={deleteBloc}/>)
        });
    }
    return (
        <div>
            <h2>Blocs</h2>
            <Card.Group>
                {displayBlocGroup()}
            </Card.Group>            
        </div>
    )
}





export default ExerciseContainerEdit;