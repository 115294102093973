import React, { useState } from 'react';
import TrainingBloc from './TrainingBloc';
import RestBloc from './RestBloc';
import { TrainingBlocClass, IBloc, IBlocGroup } from '../classes';
import './ExerciseContainer.css';
import { WithTranslation, withTranslation } from 'react-i18next';
interface IExercciseContainerProps extends WithTranslation{
    blocGroupContainer: [IBlocGroup];
}


const ExerciseContainerEdit =(props: IExercciseContainerProps) =>{
    const displayBlocGroup = () => {
        return props.blocGroupContainer.map((blocGroup: IBlocGroup) =>
            <div className='bloc-container'>
                <div className='reps'>
                    {displayBlocs(blocGroup.blocs)}
                </div>
                <div className='multiplier'>
                    <div className='circle'>
                        <h4>X {blocGroup.multiplier}</h4>
                    </div>
                     
                </div>
            </div>
        );
    }
    const displayBlocs=(blocs: [IBloc])=>{
        return blocs.map((bloc: IBloc)=>{
            //const line =bloc.id != blocs[blocs.length-1].id?<div className='line'></div>:null;
            if (bloc.intensity)
                return (
                <>
                    <TrainingBloc bloc={bloc} />
                </>)
            else
                return (
                <>
                    <RestBloc bloc={bloc}/>
                </>)
        });
    }
    return (
        <div>
            <h3>{props.t('details.overview')}</h3>
            <div>
                {displayBlocGroup()}
            </div>            
        </div>
    )
}





export default withTranslation('workout') (ExerciseContainerEdit);