import * as React from "react";
import { Table } from "semantic-ui-react";
import ActivityTableRow from "../../containers/ActivityTableRow/ActivityTableRow";
import { WithTranslation, withTranslation } from "react-i18next";

interface ActivityTableProps extends WithTranslation {
    activities: Array<any>;
    showActions?: boolean;
}

class ActivityTable extends React.Component<ActivityTableProps, {}> {

    public render() {
        const activities = this.props.activities;
        const rows = activities.map((activity) =>
            <ActivityTableRow key={activity.id} activity={activity} showActions={this.props.showActions}  />
        );

        return (
            <Table color="orange" size="small" celled={true} unstackable={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{this.props.t('activityTable.date')}</Table.HeaderCell>
                        <Table.HeaderCell>{this.props.t('activityTable.type')}</Table.HeaderCell>
                        <Table.HeaderCell>{this.props.t('activityTable.title')}</Table.HeaderCell>
                        <Table.HeaderCell>{this.props.t('activityTable.tag')}</Table.HeaderCell>
                        {this.showActionHeader()}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows}
                </Table.Body>
            </Table>
        );
    }

    private showActionHeader() {
        const header = <Table.HeaderCell>{this.props.t('activityTable.actions')}</Table.HeaderCell>;
        return this.props.showActions ? header : null;
    }
}

export default withTranslation('profile')(ActivityTable);