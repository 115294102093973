import * as React from "react";
import { GymStatus } from "common";
import { SemanticCOLORS, Label, LabelProps } from "semantic-ui-react";

interface GymStatusLabelProps extends LabelProps {
    status: GymStatus;
}

class GymStatusLabel extends React.Component<GymStatusLabelProps> {
    public render() {
        const { status, ...labelProps } = this.props;
        return (
            <Label color={this.getColor()} {...labelProps}>
                {GymStatus.toString(status)}
            </Label>);
    }

    private getColor() {
        let color: SemanticCOLORS | undefined = undefined;
        switch (this.props.status) {
            case GymStatus.ACTIVE:
                color = "green";
                break;
            case GymStatus.CLOSING:
            case GymStatus.DELETED:
                color = "red";
                break;
            case GymStatus.INACTIVE:
            default:
                color = undefined;
        }
        return color;
    }
}

export default GymStatusLabel;