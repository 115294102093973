import * as React from 'react';
import {
  Form, Header, Segment, Button, Divider, Image,
} from 'semantic-ui-react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { injectStripe, ReactStripeElements } from 'react-stripe-elements';
import { CreateGymBody, Plan } from 'common';
import GymFormGeneral, { GymFormGeneralFields } from '../GymFormComponents/GymFormGeneral';
import FormAddress, { FormAddressFields } from '../GymFormComponents/FormAddress';
import GymFormOrder, { GymFormOrderFields } from '../GymFormComponents/GymFormOrder';
import GymFormPayment from '../GymFormComponents/GymFormPayment';
import { GymService } from '../../services/gyms';
import AxiosUtils from '../../utils/AxiosUtils';
import * as poweredByStripe from '../../assets/images/powered_by_stripe.png';
import Formatter from '../../utils/Formatter';
import { PromiseManager } from '../../utils/PromiseUtils';

interface GymRequestFormState {
    requesting: boolean;
    sameAddress: boolean;
    totalPrice: number;
    plan?: Plan;
    form: {
        general: GymFormGeneralFields;
        order: GymFormOrderFields;
        billing: FormAddressFields
    };
}

interface GymRequestFormProps extends RouteComponentProps<void>, ReactStripeElements.InjectedStripeProps { }

class GymRequestForm extends React.Component<GymRequestFormProps, GymRequestFormState> {
    private generalGroup!: GymFormGeneral | null;

    private billingGroup!: FormAddress | null;

    private orderGroup!: GymFormOrder | null;

    private promises: PromiseManager = new PromiseManager();

    constructor(props: GymRequestFormProps) {
      super(props);
      this.state = {
        requesting: false,
        sameAddress: true,
        totalPrice: 0,
        form: {
          general: GymFormGeneral.defaultValues,
          order: GymFormOrder.defaultValues,
          billing: FormAddress.defaultValues,
        },
      };
    }

    public componentDidMount() {
      this.getStandardPlan();
    }

    public handleSubmit = async (event: any) => {
      event.preventDefault();
      const token = await this.getStripeToken();
      if (!this.state.requesting && this.isFormValid() && token) {
        this.sendCreateGymRequest(token.id);
      }
    }

    public async getStripeToken() {
      const stripe = this.props.stripe as ReactStripeElements.StripeProps;
      const result = await stripe.createToken();
      return result.token;
    }

    public isFormValid = () => {
      let valid = true;
      if (!this.generalGroup || !this.generalGroup.validate()) {
        valid = false;
      }
      if (!this.orderGroup || !this.orderGroup.validate()) {
        valid = false;
      }
      if (this.billingGroup && !this.billingGroup.validate()) {
        valid = false;
      }
      return valid;
    }

    public handleCheck = (event: any) => {
      const { target } = event;
      this.setState({
        sameAddress: target.checked,
      });
    }

    public onChange = (key: 'general' | 'order' | 'billing') => (values: any) => {
      const form = { ...this.state.form, [key]: values };
      this.setState({ form });
    }

    public onOrderChange = (values: GymFormOrderFields) => {
      this.onChange('order')(values);
      const { plan } = this.state;
      if (plan) {
        const totalPrice = Number(values.count) * plan.amount;
        this.setState({ totalPrice });
      }
    }

    public render() {
      const plan: Plan = this.state.plan as Plan;
      // We should use the loading segment, but there is a bug where it fades out instead of fading in
      if (!plan) {
        return null;
      }
      return (
        <Form onSubmit={this.handleSubmit}>
          <Header as="h3" dividing>
                    General Infos
            <Header.Subheader>
                        General infos about your gym
            </Header.Subheader>
          </Header>
          <Segment>
            <GymFormGeneral ref={(c) => this.generalGroup = c} onChange={this.onChange('general')} />
          </Segment>
          <Header as="h3">Order</Header>
          <Segment>
            <GymFormOrder ref={(c) => this.orderGroup = c} plan={plan} onChange={this.onOrderChange} />
            <Divider />
            <label>Total : </label>
            {Formatter.formatPrice(this.state.totalPrice / 100, plan.currency)}
          </Segment>
          <Header as="h3">Billing Infos</Header>
          <Segment>
            <Form.Field inline disabled>
              <label>Use gym's address</label>
              <input type="checkbox" checked={this.state.sameAddress} onChange={this.handleCheck} />
            </Form.Field>
            {this.renderBilling()}
          </Segment>
          <Header as="h3">Payment</Header>
          <Segment>
            <GymFormPayment />
            <Image src={poweredByStripe} size="small" />
          </Segment>
          <Form.Group className="right">
            <Form.Field>
              <Button color="orange">Buy</Button>
            </Form.Field>
            <Form.Field>
              <Link to="/settings/gyms"><Button>Cancel</Button></Link>
            </Form.Field>
          </Form.Group>
        </Form>
      );
    }

    private renderBilling = () => {
      const address = (
        <>
          <Divider />
          <FormAddress ref={(c) => this.billingGroup = c} onChange={this.onChange('billing')} />
        </>
      );
      return this.state.sameAddress ? null : address;
    }

    private createRequestBody(tokenId: string) {
      const body: CreateGymBody = {
        gym: { ...this.state.form.general },
        stripeTokenId: tokenId,
        orderCount: Number(this.state.form.order.count),
      };
      return body;
    }

    private updateErrors(errors: any) {
      if (errors.gym && this.generalGroup) {
        this.generalGroup.updateErrors(errors.gym);
      }
    }

    private sendCreateGymRequest = (token: string) => {
      this.setState({ requesting: true });
      const body = this.createRequestBody(token);
      GymService.createGym(body)
        .then(() => {
          this.setState({ requesting: false });
          this.props.history.replace('/settings/gyms');
        })
        .catch((error) => {
          const errors = AxiosUtils.getErrors(error);
          this.updateErrors(errors);
          this.setState({ requesting: false });
        });
    }

    private getStandardPlan = () => {
      const promise = this.promises.add(GymService.getStandardPlan());
      promise.then((response) => {
        const plan = response.data;
        const totalPrice = Number(GymFormOrder.defaultValues.count) * plan.amount;
        this.setState({ plan, totalPrice });
      }).catch((error) => {
        // We do nothing with the error
      });
    }
}

export default injectStripe(withRouter(GymRequestForm));
