import React from "react";
import * as sensorDataAnalysis from '../../utils/sensorDataAnalysis';
import { connect } from "react-redux";
import { State } from "../../reducers";
import { start } from "repl";
import { Dispatch } from "redux";
import { handleActivityStatus, handleTimerStatus } from "../../actions/activities.actions";
import { Rep } from "../../classes/Workout";

interface TimerProps{
    rep?: {},
    callBack?: any,
    updateTime: any,
    dispatch: Dispatch<void>;
    activityStatus: string;
    timerStatus:string;
    currentRep:Rep;
}
interface TimerState{
    elapsedTimeInTenthofSeconds: number;
    startingTime:number;
    beforePausedTime:number;
}

const mapStateToProps = (state: State) => ({
    activityStatus: state.activities.activityStatus,
    timerStatus: state.activities.timerStatus,
    currentRep: state.activities.currentRep
  });
const initialState:TimerState = {
    elapsedTimeInTenthofSeconds: 0,
    startingTime: 0,
    beforePausedTime:0,
}
class Timer extends React.Component<TimerProps, TimerState>{

    wasMounted = false;
    private timer: number;
    constructor(props: TimerProps) {
        super(props);
        this.state = initialState;

        this.startTimer = this.startTimer.bind(this);
        this.resetTimer = this.resetTimer.bind(this);
        }
    componentDidMount(){
        this.wasMounted = true;
        sensorDataAnalysis.resetTimer();
    }
    startTimer() {
        if(this.props.timerStatus == 'start')
        {
            this.setState({
                startingTime: Date.now(),
            });
            this.timer = setInterval(() =>{
                if (this.props.timerStatus !== 'pause') {
                    if (this.state.elapsedTimeInTenthofSeconds > this.props.currentRep.time && !this.props.activityStatus.includes('wait'))
                        this.props.dispatch(handleTimerStatus('done'));


                    const deltaTime = ((Date.now() - this.state.startingTime) / 1000) + this.state.beforePausedTime;
                    this.setState({
                        elapsedTimeInTenthofSeconds: deltaTime
                    });
                    this.props.updateTime(deltaTime);
                }
                
            }, 10); 
        }
         
    }
    resetTimer(){
        this.setState({
            startingTime: Date.now(),
            elapsedTimeInTenthofSeconds: 0,
            beforePausedTime: 0,
        });
    }
    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        if (this.props.timerStatus !== prevProps.timerStatus) {
            if(prevProps.timerStatus == 'pause'){
                this.setState({
                    startingTime: Date.now()
                })
            }
            switch(this.props.timerStatus){
                case "start": 
                    this.startTimer();
                    this.props.dispatch(handleTimerStatus('working'));
                    break;               
                case "reset" :
                    this.resetTimer();
                    this.props.dispatch(handleTimerStatus('working'));
                    break;
                case "pause":
                    this.setState({
                        beforePausedTime: this.state.elapsedTimeInTenthofSeconds
                    });
                    break;
                case "stop":
                        clearInterval(this.timer);
                        this.setState(initialState);
            }
        }
      }

    render(){
        const timeSinceBeginning = isNaN(sensorDataAnalysis.timeSinceBeginning()) ? 0 : sensorDataAnalysis.timeSinceBeginning();
        const minutes = Math.floor(Math.floor(timeSinceBeginning/60));
        var seconds:any= Math.floor(60*(timeSinceBeginning/60-Math.floor(timeSinceBeginning/60)));
        seconds = (seconds < 10) ? `0${seconds}`: `${seconds}`;
        const deciseconds= Math.floor(10*(this.state.elapsedTimeInTenthofSeconds-Math.floor(this.state.elapsedTimeInTenthofSeconds)));
        return(
            <div>
                <h3>
                <span style={{fontFamily:"open sans",fontWeight:300,fontSize: "5rem"}}>{minutes}:{seconds}</span><span style={{fontFamily:"open sans",fontWeight:300,fontSize: "2rem"}}>{deciseconds}</span>
                </h3>
            </div>
        )
    }
}

export default connect (mapStateToProps)(Timer);