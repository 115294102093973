import * as React from "react";
import "./LoginPanel.css";
import { Form, Header, Divider, Button } from "semantic-ui-react";
import { withRouter, RouteComponentProps, Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { login, facebookLogin, googleLogin } from "../../actions/user.actions";
import  store  from "../../config/store";
import * as firebase from 'firebase';
import { WithTranslation, withTranslation } from 'react-i18next';

interface LoginPanelState {
    email: string;
    password: string;
}

interface LoginPanelProps extends RouteComponentProps<void>, WithTranslation {
    dispatch: Dispatch<void>;
    authentication: any;
}

const mapStateToProps = (state: any) => {
    return { authentication: state.authentication };
};

class LoginPanel extends React.Component<LoginPanelProps, LoginPanelState> {
    constructor(props: LoginPanelProps) {
        super(props);

        this.state = {
            email: "",
            password: "",
        };

    }

    handleChange = (event: React.SyntheticEvent<HTMLElement>) => {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        this.setState({...this.state, [target.name]: target.value});
    }

    submitLogin = () => {
        this.props.dispatch(login(this.state));
    }
    facebookSignIn = () =>{
        const react_context = this;
        const provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider).then((result)=> {
            react_context.props.dispatch(facebookLogin({access_token:result.credential.accessToken}));
          }).catch(function(error) {
            console.log('errorCode: '+ error.code);
            console.log('erroMsg: '+error.message);
        });
    }
    googleSignIn= ()=>{
        const react_context = this;
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then((result) => {
            react_context.props.dispatch(googleLogin({ id_token: result.credential.idToken }));
        }).catch(function (error) {
            console.log('errorCode: ' + error.code);
            console.log('erroMsg: ' + error.message);
        });
        
    }
    render() {
        if (this.props.authentication.login) {
            return <Redirect to="/"/>;
        }

        return (
            <>
                    <div className="login-panel ">

                        <Header as="h1" inverted={true}>{this.props.t('login.header')}</Header>
                        <Form>
                            <Form.Field>
                                <a className="fluid ui button blue" onClick={this.facebookSignIn}>Facebook</a>
                            </Form.Field>
                            <Form.Field>
                                <a className="fluid ui button orange" onClick={this.googleSignIn}>Google</a>
                            </Form.Field>
                            <Form.Field>
                                <div className="g-signin2 fluid ui" data-onsuccess="requestGoogleLogin"></div>
                            </Form.Field>
                            <Divider horizontal={true} inverted={true}>{this.props.t('login.or')}</Divider>
                            {store.getState().authentication.errors ?
                                <p id='loginErrorMessage'>
                                    {this.props.t('login.errorMessage')}
                                </p>
                                : ''}
                            <Form.Field>
                                <input
                                    name="email"
                                    type="text"
                                    placeholder={this.props.t('login.email')}
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <input
                                    name="password"
                                    type="password"
                                    placeholder={this.props.t('login.password')}
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                            <Button fluid={true} onClick={this.submitLogin}>{this.props.t('login.loginButton')}</Button>
                            <Divider inverted={true} />
                            <p>{this.props.t('login.needAccountQuestion')} <Link to="/signup/confirm/email">{this.props.t('login.signUp')}</Link></p>
                            <Link to="/account/recovery">{this.props.t('login.forgotPasswordQuestion')}</Link>
                        </Form>
                    </div>

            </>
        );
                
    }
}

export default withTranslation('account')(connect(mapStateToProps)(withRouter(LoginPanel)));
