import { bluetoothConstants } from "./../constants/bluetooth.constants";


export interface BluetoothState {
    bluetoothStatus: string;
    bluetoothConnected: boolean;
}

const defaultState = {
    bluetoothStatus: 'notConnected',
    bluetoothConnected: false
};

export const bluetooth = (state: BluetoothState = defaultState, action: any) => {
    switch (action.type) {
        case bluetoothConstants.UPDATE_BLUETOOTH_STATUS:
            return { ...state, bluetoothStatus: action.data };
        case bluetoothConstants.BLUETOOTH_CONNECTION:
            return { ...state, bluetoothConnected: action.data };
        default:
            return { ...state };    
    }
};
