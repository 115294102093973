import * as React from "react";
import { CommonGym, CommonAddress } from "common";
import { Segment, Form } from "semantic-ui-react";
import SettingRow from "../SettingRow/SettingRow";
import EditableSection from "../EditableSection/EditableSection";
import GymFormGeneral from "../GymFormComponents/GymFormGeneral";
import { GymService } from "../../services/gyms";
import { PromiseManager } from "../../utils/PromiseUtils";
import SegmentHeader from "../SegmentHeader/SegmentHeader";
import Formatter from "../../utils/Formatter";

interface GymGeneralSectionProps {
    gym: CommonGym;
    onEditSuccess?: (gym: CommonGym) => void;
}

interface GymGeneralSectionState {
    editing: boolean;
    requesting: boolean;
}

class GymGeneralSection extends React.Component<GymGeneralSectionProps, GymGeneralSectionState> {
    private generalForm!: GymFormGeneral | null;
    private promises: PromiseManager = new PromiseManager();

    constructor(props: GymGeneralSectionProps) {
        super(props);
        this.state = {
            editing: false,
            requesting: false
        };
    }

    public componentWillUnmount() {
        this.promises.cancelAll();
    }

    public handleEditClick = () => {
        this.setState({ editing: !this.state.editing });
    }

    public getFormDefaultValues = () => {
        const { name, phone, address } = this.props.gym;
        const { id, ...addressFields } = address;
        let values = { name, phone, ...addressFields };
        return values;
    }

    public handleSubmit = () => {
        this.modifyGymRequest();
    }

    render() {
        const gym = this.props.gym;
        return (
            <EditableSection editing={this.state.editing} onClick={this.handleEditClick} iconOffset={{ right: 10, top: 10 }}>
                <EditableSection.Content>
                    <Segment className="with-header">
                        <SegmentHeader>General Infos</SegmentHeader>
                        <SettingRow title="Gym Name" content={gym.name} />
                        <SettingRow title="Phone Number" content={Formatter.formatPhone(gym.phone)} />
                        <SettingRow title="Address" content={CommonAddress.toString(gym.address)} />
                    </Segment>
                </EditableSection.Content>
                <EditableSection.Form>
                    <Segment className="with-header">
                        <SegmentHeader>General Infos</SegmentHeader>
                        <Form>
                            <GymFormGeneral ref={(form) => this.generalForm = form} defaultValues={this.getFormDefaultValues()} />
                            <Form.Group className="right">
                                <Form.Button onClick={this.handleEditClick}>Cancel</Form.Button>
                                <Form.Button color="orange" onClick={this.handleSubmit}>Save</Form.Button>
                            </Form.Group>
                        </Form>
                    </Segment>
                </EditableSection.Form>
            </EditableSection>
        );
    }

    private modifyGymRequest = () => {
        if (!this.state.requesting && this.generalForm) {
            this.setState({ requesting: true });
            const { name, phone, ...address } = this.generalForm.state.values;
            const promise = this.promises.add(GymService.modifyGym(this.props.gym.id, { name, phone, address }));
            promise.then((response) => {
                this.setState({ requesting: false, editing: false });
                if (this.props.onEditSuccess) {
                    this.props.onEditSuccess(response.data);
                }
            }).catch((error) => {
                if (!error.isCanceled) {
                    this.setState({ requesting: false });
                }
            });
        }

    }
}

export default GymGeneralSection;