import * as React from "react";
import { Divider, Sidebar, Menu, Container, Header, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";
import RestrictedRender from "../../../containers/RestrictedRender";
import { closeSideBar } from "../../../actions/navBar.actions";
import "./MobileSideNavBar.css";
interface MobileSideNavBarProps {
    handleLogOut: any;
    showSideNavBar: boolean;
    dispatch: any;
}
export class MobileSideNavBar extends React.Component<MobileSideNavBarProps> {
    closeSideBar = () => {
        this.props.dispatch(closeSideBar());
    }
    render() {
        return (
            <Sidebar
                as={Menu}
                width="thin"
                direction="left"
                visible={this.props.showSideNavBar}
                animation="overlay"
                inverted={true}
                vertical={true}
                borderless={true}
            >
                <Container onClick={this.closeSideBar}>
                    <Menu.Item ><Header size="medium" inverted={true}>Entralpi</Header></Menu.Item>
                    <Divider fitted={true} />
                    <Divider fitted={true} />
                    <Menu.Item className="mobile-side-nav-bar-header"><Header size="small" inverted={true} >My account</Header></Menu.Item>
                    <Menu.Item className="mobile-side-nav-bar-header" as={Link} to="/exercise-launcher"><p> <Icon name="dumbbell center" /> Exercise</p></Menu.Item>
                    <Menu.Item as={Link} to="/profile">
                        <p>  <Icon name="user" /> Profile</p>
                    </Menu.Item>
                    <RestrictedRender requiredRole="admin"/>
                    <Divider fitted={true} />
                    <Menu.Item className="mobile-side-nav-bar-header"><Header size="small" inverted={true} > My settings</Header>
                    </Menu.Item>
                    <Menu.Item as={Link} to="/settings" >
                        <p> <Icon name="settings" /> Settings </p>
                    </Menu.Item>
                    <Menu.Item as={Link} to="/login" onClick={this.props.handleLogOut}>
                        <p> <Icon name="sign out alternate" />Log Out</p>
                    </Menu.Item>
                </Container>
            </Sidebar >
        );
    }
}