import * as React from "react";
import { connect } from "react-redux";
import SettingRow from "../../components/SettingRow/SettingRow";
import { Container, Image } from "semantic-ui-react";
import { State } from "../../reducers";
import { fetchUserProfile } from "../../actions/settings.actions";
import "./ViewSettingsProfile.css";
import { withTranslation, WithTranslation } from "react-i18next";

interface ViewSettingsProfileProps extends WithTranslation {
    user: any;
    token: string;
    dispatch: any;
}

const mapStateToProps = (state: State) => {
    return { user: state.authentication.user, token: state.authentication.token };
};

class ViewSettingsProfile extends React.Component<ViewSettingsProfileProps, {}> {
    componentWillMount() {
        this.props.dispatch(fetchUserProfile(this.props.user.id));
    }

    render() {

        let firstName, lastName, weight;
        firstName = lastName = "";

        const user = this.props.user;
        if (user) {
            firstName = user.firstName;
            lastName = user.lastName;
            weight = user.weight || 0;
        }

        const name: string = firstName + " " + lastName;
        const profilePicture = <Image src={this.props.user.imageUrl} className="profile-picture" circular={true} />;
        return (
            <Container className="profile-settings">
                <SettingRow title={this.props.t('profile.subPicture')} content={profilePicture} />
                <SettingRow title={this.props.t('profile.subName')} content={name} />
                <SettingRow title={this.props.t('profile.subWeight')} content={weight} />
            </Container>
        );
    }
}

export default withTranslation('settings')(connect(mapStateToProps)(ViewSettingsProfile));
