import * as React from "react";
import MainLayout from "./MainLayout/MainLayout";
import { Switch, Route, Redirect } from "react-router";
import Settings from "./Settings/Settings";
import Admin from "./Admin/Admin";
import ActivityListing from "../containers/ActivityListing/ActivityListing";
import Profile from "../containers/Profile/Profile";
import ActivityDetails from "./ActivityDetails/ActivityDetails";
import RestrictedRoute from "../containers/RestrictedRoute";
import GymPage from "./GymPage/GymPage";
import ExerciseSelector from "../containers/ExerciseSelector/ExerciseSelector";
import Exercise from "../containers/Exercise/Exercise";
import WorkoutListing from "../containers/WorkoutListing/WorkoutListing";
import WorkoutDetails from "../pages/WorkoutDetails/WorkoutDetails"
import ExerciseManager from "../containers/ExerciseManager/ExerciseManager";
import Flappybird from "../flappybird/flappybird";
import { State } from "../reducers";
import i18n from "../i18n";
import { connect } from "react-redux";
import { Lang } from "common";
import ExerciseCreator from "./exerciseCreator/ExerciseCreator";

const mapStatetoProps = (state: State, ownProps: any) => ({
    lang: state.authentication.user.displaySettings.lang
});
interface HomeProps {
    lang: Lang
}
class Home extends React.Component<HomeProps, {}> {
    render() {
        i18n.changeLanguage(this.props.lang)
        return (
            <MainLayout>
                <Switch>
                    <RestrictedRoute path="/admin" requiredRole="admin" component={Admin} />
                    <Route path="/profile" component={Profile} />
                    <Route path="/gyms/:id" component={GymPage} />
                    <Route path="/activities/:id" component={ActivityDetails} />
                    <Route exact={true} path="/athletes/:id/profile" component={Profile} />
                    <Route path="/athletes/:id/activities" component={ActivityListing} />
                    <Route path="/exercise-launcher" component={WorkoutListing} />
                    <Route path="/exercise" component={Exercise} />
                    <Route path="/workouts/:workout" component={WorkoutDetails} />
                    <Route path="/settings" component={Settings} />
                    <Route path="/exercise-creator" component={ExerciseCreator} />
                    <Route path="/raw" component={ExerciseSelector} />
                    <Route path="/exercisemanager" component={ExerciseManager} />
                    <Route path="/flappybird" component={Flappybird} />
                    <Redirect from="/" to="/exercise-launcher" />
                </Switch>
            </MainLayout>
        );
    }
}

export default connect(mapStatetoProps)(Home);
