import * as React from "react";
import { Segment, Grid } from "semantic-ui-react";
import "./SettingRow.css";

interface SettingRowProps {
    title: string;
    content: React.ReactNode;
}

class SettingRow extends React.Component<SettingRowProps, {}> {
    render() {
        const { title, content } = this.props;
        return (
            <Segment as={Grid} className="setting-row" verticalAlign="middle" vertical={true}>
                <Grid.Column className="title" mobile={7} tablet={5} computer={5}>{title}</Grid.Column>
                <Grid.Column mobile={9} tablet={11} computer={11}>{content}</Grid.Column>
            </Segment>
        );
    }
}

export default SettingRow;