import * as React from "react";
import { RouteComponentProps } from "react-router";
import ViewSettingsProfile from "../../containers/SettingsProfile/ViewSettingsProfile";
import EditSettingsProfile from "../../containers/SettingsProfile/EditSettingsProfile";
import { Container, Header, Segment } from "semantic-ui-react";
import EditableSection from "../EditableSection/EditableSection";
import { WithTranslation, withTranslation } from "react-i18next";

interface SettingsAccountState {
    editingDisplaySettings: boolean;
}
interface SettingsProfileProps extends RouteComponentProps<void>, WithTranslation{}
class SettingsProfile extends React.Component<SettingsProfileProps, SettingsAccountState> {
    constructor(props: any) {
        super(props);
        this.state = { editingDisplaySettings: false };
    }

    render() {
        const editingDisplaySettings = this.state.editingDisplaySettings;

        return (
            <Segment>
                <EditableSection editing={editingDisplaySettings} onClick={this.editButtonCallback} iconOffset={{ right: 0, top: 8 }}>
                    <EditableSection.Content>
                        <Container>
                            <Header as="h1">{this.props.t('profile.header')}</Header>
                            <ViewSettingsProfile />
                        </Container>
                    </EditableSection.Content>
                    <EditableSection.Form>
                        <Container>
                            <Header as="h1">{this.props.t('profile.header')}</Header>
                            <EditSettingsProfile endEditingCallback={this.endEditingCallback} />
                        </Container>
                    </EditableSection.Form>
                </EditableSection >
            </Segment>
        );
    }

    editButtonCallback = (e: Event) => {
        this.setState({ editingDisplaySettings: !this.state.editingDisplaySettings });
    }

    endEditingCallback = () => {
        this.setState({ editingDisplaySettings: false });
    }
}

export default withTranslation('settings')(SettingsProfile);