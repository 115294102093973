import * as React from "react";
import { Menu } from "semantic-ui-react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";

enum PageMenuTab {
    GYMS,
    ORDERS
}

interface PageMenuState {
    activeTab: PageMenuTab;
}

class PageMenu extends React.Component<RouteComponentProps<void>, PageMenuState> {

    constructor(props: RouteComponentProps<void>) {
        super(props);
        this.state = {
            activeTab: this.findActiveTab()
        };
    }

    public render() {
        const activeTab = this.state.activeTab;
        return (
            <Menu pointing={true} secondary={true} size="large">
                <Menu.Item
                    as={Link}
                    to={`${this.props.match.url}`}
                    name="Gyms"
                    active={activeTab === PageMenuTab.GYMS}
                    onClick={() => this.setActiveTab(PageMenuTab.GYMS)}
                />
                <Menu.Item 
                    as={Link} 
                    to={`${this.props.match.url}/orders`} 
                    name="Orders" 
                    active={activeTab === PageMenuTab.ORDERS} 
                    onClick={() => this.setActiveTab(PageMenuTab.ORDERS)}
                />
            </Menu>
        );
    }

    private setActiveTab = (tab: PageMenuTab) => {
        this.setState({activeTab: tab});
    }

    private findActiveTab = () => {
        const path = this.props.location.pathname;
        const isOrder: boolean = /.*orders.*/.test(path);
        if (isOrder) {
            return PageMenuTab.ORDERS;
        }

        return PageMenuTab.GYMS;
    }
}

export default withRouter(PageMenu);