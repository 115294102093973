import { api } from "../config/axiosConfig";
import { GymOrdersCountQuery, GetOrdersQuery } from "common";

export const getAthleteGymsRequest = () => {
    let requestURL = "/v1/athlete/gyms";
    return api().get(requestURL);
};

export const getAthleteGymRequest = (gymId: number) => {
    let requestURL = "/v1/athlete/gyms/" + gymId;
    return api().get(requestURL);
};

export const getAthleteGymOrdersRequest = (gymId: number, query?: GetOrdersQuery) => {
    let requestURL = `/v1/athlete/gyms/${gymId}/orders`;
    return api().get(requestURL, {params: query});
};

export const getAthleteGymOrdersCountRequest = (gymId: number, query: GymOrdersCountQuery) => {
    let requestURL = `/v1/athlete/gyms/${gymId}/orders/count`;
    return api().get(requestURL, {params: query});
};