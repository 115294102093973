import * as React from "react";
import { Table } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { CommonGym, CommonAddress } from "common";
import GymStatusLabel from "../GymStatusLabel/GymStatusLabel";
import Formatter from "../../utils/Formatter";

interface GymsTableRowProps {
    gym: CommonGym;
}

interface GymsTableRowState {
    requesting: boolean;
}

class GymsTableRow extends React.Component<GymsTableRowProps, GymsTableRowState> {
    constructor(props: GymsTableRowProps) {
        super(props);
        this.state = {
            requesting: false
        };
    }

    public render() {
        const gym = this.props.gym;
        const userFullName = `${gym.owner.firstName} ${gym.owner.lastName}`;
        const userProfileURL = `/athletes/${gym.owner.id}`;
        return (
            <Table.Row>
                <Table.Cell>{gym.name}</Table.Cell>
                <Table.Cell>{Formatter.formatPhone(gym.phone)}</Table.Cell>
                <Table.Cell>{CommonAddress.toString(gym.address)}</Table.Cell>
                <Table.Cell><Link to={userProfileURL}>{userFullName}</Link></Table.Cell>
                <Table.Cell><GymStatusLabel status={gym.status} size="small"/></Table.Cell>
                <Table.Cell>{this.formatDate(gym.createdDate)}</Table.Cell>
            </Table.Row>
        );
    }

    public formatDate = (date: Date) => {
        return new Date(date).toLocaleDateString("en-US");
    }
}

export default GymsTableRow;