import React from 'react';
import { useState} from 'react';
import { Link } from "react-router-dom";
import { api } from "../../config/axiosConfig";
import { firebaseConfig } from "../../App";
import { Message, Modal, Button, Header, Icon, Popup } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';

export const ShareButton = ({ activityId, privacySettings, meta }) => {
    const [enablePrivacySettings, setEnablePrivacySettings] = useState(false);
    
    const clientRoute = ()=>{
      let route;
      switch (process.env.REACT_APP_NODE_ENV){
        case 'production':
          route= 'https://app.entralpi.com';
          break;
        case 'staging': 
          route= 'https://dev.entralpi.com';
          break;
        default:
          route= 'http://localhost:3000'
      }
      return route;
    }

    return(
      <div style={{position: 'absolute', top: '2vh', right: '2vh', color: '#fff'}}>
        {errorModal()}
        <Button icon='share' onClick={getShareableLink}/>
      </div>
    );
    function getShareableLink() {
        if(privacySettings == 'private' && !enablePrivacySettings){
            setEnablePrivacySettings(true);
        }
        else{
            const requestURL = `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseConfig.apiKey}`;
            const data = {
                dynamicLinkInfo: {
                    domainUriPrefix: "https://entralpi.io",
                    link: `${clientRoute()}/activities/${activityId}`,
                    socialMetaTagInfo: {
                        socialTitle: meta.title,
                        socialDescription: meta.description,
                        socialImageLink:  meta.image
                    }
                }
            };
            api().post(requestURL, data)
            .then(response=>{
            window.open(`https://www.facebook.com/sharer/sharer.php?u=${response.data.shortLink}`);
            })
            .catch(error=>{console.log(error.response)});
        } 
    }
    function errorModal(){
            return(
            <Modal open={enablePrivacySettings==true}>
            <Header icon='warning' content='Privacy settings' />
            <Modal.Content>
              <p style={{color:'black'}}>
                Your account is automatically set to private.<br />
                You can authorize sharing and allow other user to see your activities by changing your privacy settings to public.<br /> <br />
                To change it go to Settings > Privacy and change this back at anytime.<br />
                Learn more about our <Link to="/privacy">privacy policy</Link>.<br />
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button color='green' onClick={()=>setEnablePrivacySettings(false)} >
                Ok
              </Button>
            </Modal.Actions>
          </Modal>
          )
    }
}
