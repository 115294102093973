import React, { useState } from 'react';
import { Card, Button, Dropdown } from 'semantic-ui-react';
import { IRestBloc } from '../classes';

interface ITypeDropDownProps{
    handleChanges: any,
    id:string
}
const selectOptions = [
    { key: "Test", text: "Test", value: "test" },
    { key: "Training", text: "Training", value: "training" },
    { key: "Warmp-up", text: "Warmp-up", value: "warmp-up" }
];
const TypeDropDown =(props: ITypeDropDownProps) =>{
    return(<Dropdown
        placeholder='Select a category'
        fluid
        selection
        options={selectOptions}
        onChange={props.handleChanges}
        id={props.id}
  />)
}

export default TypeDropDown;