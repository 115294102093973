import { activitiesConstants } from "../constants";
import { api } from "../config/axiosConfig";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { GetActivitiesResponse } from "common";
import { ActivityFiltersState } from "../reducers/activities.reducer";
import { Dispatch } from "redux";
import { response } from "express";
import { Rep } from "../classes/Workout";

export const getActivities = (id: string, query?: any) => {
    const numId = +id;
    const request = () => (
        { type: activitiesConstants.ACTIVITIES_REQUEST }
    );

    const success = (data: GetActivitiesResponse) => (
        { type: activitiesConstants.ACTIVITIES_SUCCESS, data }
    );

    const failure = (data: GetActivitiesResponse) => (
        { type: activitiesConstants.ACTIVITIES_FAILURE, data }
    );

    const requestURL = `/v1/athletes/${numId}/activities`;

    let config: AxiosRequestConfig = { params: query };
    return (dispatch: Dispatch<void>) => {
        dispatch(request());
        api()
            .get(requestURL, config)
            .then((response) => {
                dispatch(success(response.data)); })
            .catch((error) => { dispatch(failure(error.response.data)); });
    };
};

export const updateActivitiesFilters = (filters: ActivityFiltersState) => (
    { type: activitiesConstants.UPDATE_FILTERS, data: filters }
);

export const setActivitiesPage = (page: number) => (
    { type: activitiesConstants.SET_PAGE, data: page }
);

export const archiveActivity = (id: number) => {
    const request = () => (
        { type: activitiesConstants.ARCHIVE_REQUEST }
    );

    const success = (data: number) => (
        { type: activitiesConstants.ARCHIVE_SUCCESS, data }
    );

    const failure = () => (
        { type: activitiesConstants.ARCHIVE_FAILURE }
    );

    const requestURL = `/v1/athlete/activities/${id}`;

    return (dispatch: Dispatch<void>) => {
        dispatch(request());
        api()
            .patch(requestURL, { isArchived: true })
            .then(() => { dispatch(success(id)); })
            .catch(() => { dispatch(failure()); });
    };
    
};

export const resetActivitiesState = () => (
    { type: activitiesConstants.ACTIVITIES_RESET_STATE }
);

export const uploadActivity = (activity:any) => {
        const success = (data: any) => {
            return{
                type: activitiesConstants.UPLOAD_SUCCESS,
                data: data
            }
        };
    
        const failure = (errors:any) => {
            return{ 
                type: activitiesConstants.UPLOAD_FAILURE,
                errors : errors
            }
        };
        const requestURL = '/v1/athlete/upload';
        return async (dispatch: any) => {
            await api()
                .post(requestURL, activity)
                .then((response) => {   
                    activity.id = response.data.id;                  
                    dispatch(success(activity));
                })
                .catch((error) => { dispatch(failure(error)); });        
            };
};

export const handleActivityStatus = (status: string)=>(
    { type: activitiesConstants.ACTIVITY_STATUS, data: status }
)
export const handleCalibrationStatus = (status: string)=>(
    { type: activitiesConstants.CALIBRATION_SATUS, data: status }
)
export const handleTimerStatus=(status:string)=>(
    { type: activitiesConstants.TIMER_STATUS, data: status }
)
export const handleCurrentRepUpdate=(newRep: Rep)=>(
    { type: activitiesConstants.CURRENT_REP, data: newRep }
)
    
