import * as React from "react";
import { Route, Switch, RouteComponentProps } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login/Login";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy"
import SignUp from "./pages/SignUp/SignUp";
import ConfirmEmail from "./pages/ConfirmEmail/ConfirmEmail"
import Home from "./pages/Home";
import OAuthRedirect from "./pages/OAuthRedirect";
import PrivateRoute from "./containers/PrivateRoute";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import * as ReactGA from "react-ga";
import * as firebase from 'firebase';
import { Suspense } from "react";
import { WithTranslation, withTranslation, useTranslation } from "react-i18next";
import i18n from './i18n.js';
import { Segment, Dimmer, Loader } from "semantic-ui-react";

function initializeReactGA() {
    ReactGA.initialize("UA-108070542-3");
    ReactGA.pageview("/");
}
export const firebaseConfig = {
    apiKey: "AIzaSyCdt3QFgre4byyuKFiCjOg_fdXrt5XDZbg",
    authDomain: "entralpi-44964.firebaseapp.com",
    databaseURL: "https://entralpi-44964.firebaseio.com",
    projectId: "entralpi-44964",
    storageBucket: "entralpi-44964.appspot.com",
    messagingSenderId: "481020418205",
    appId: "1:481020418205:web:ef1adcefa87113ee7f5c74",
    measurementId: "G-SK6FVBWNRT"
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.performance();
// Analitics Integration
initializeReactGA();


class App extends React.Component {
    translationLoader = () =>
    <Dimmer active style={{ height: "100%" }}>
        <Loader size='big' style={{ display: "block" }} />
    </Dimmer>
    render() {   
        console.log(this.props);
        return (
            <React.Fragment>
                <Suspense fallback={<this.translationLoader/>}>
                    <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/privacy" component={PrivacyPolicy} />
                    <Route path="/signup/register" component={SignUp} />
                    <Route path="/signup/confirm/email" component={ConfirmEmail} />
                    <Route path="/oauth/redirect" component={OAuthRedirect} />
                    <Route path="/account/recovery" component={ForgotPassword} />
                    <Route path="/account/reset" component={ResetPassword} />
                    <PrivateRoute path="/" component={Home}/>
                    </Switch>
                </Suspense>
                
            </React.Fragment>
        );
    }
}

export default App;
