export const settingsConstants = {
    UPDATE_ERRORS_PROFILE: "UPDATE_ERRORS_PROFILE",
    CANCEL_EDIT_PROFILE: "CANCEL_EDIT_PROFILE",

    FETCH_SUCCESS: "USER_FETCH_SUCCESS",
    FETCH_FAILURE: "USER_FETCH_FAILURE",

    IMAGE_UPLOAD_SUCCESS: "IMAGE_UPLOAD_SUCCESS",
    IMAGE_UPLOAD_FAILURE: "IMAGE_UPLOAD_FAILURE",
};
