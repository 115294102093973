import * as React from 'react';
import {
  Container, Segment, Header, Grid, Responsive,
} from 'semantic-ui-react';
import { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { CommonAthleteStatistics, CommonUser } from 'common';
import { State } from '../../reducers/index';
import { UserProfileState } from '../../reducers/userProfile.reducer';
import ActivityPreview from '../../containers/ActivityPreview/ActivityPreview';
import { fetchProfileInfo } from '../../actions/user.actions';
import NotFound from '../../pages/NotFound/NotFound';
import ProfileTopSection from '../../components/Profile/ProfileTopSection/ProfileTopSection';
import SegmentHeader from '../../components/SegmentHeader/SegmentHeader';
import { StrengthProgressionChart } from '../../components/StrengthProgressionChart/StrengthProgressionChart';
import LoadingSegment from '../../components/LoadingSegment/LoadingSegment';
import { ActivitiesState } from '../../reducers/activities.reducer';
import { getActivities } from '../../actions/activities.actions';
import CalendarHeatmap from '../../components/CalendarHeatmap/CalendarHeatmap';
import { ResponsiveUtil } from '../../utils/ResponsiveUtil';
import { WithTranslation, withTranslation } from 'react-i18next';

export interface UserProfileProps extends WithTranslation{
    dispatch: Dispatch<void>;
    userId: string;
    userProfile: UserProfileState;
    user: CommonUser;
    activities: ActivitiesState;
    athleteStatistics: CommonAthleteStatistics;
}

interface UserProfile {
    isOwnProfile: boolean;
    hideSections: boolean;
}

function mapStateToProps(state: State) {
  return {
    user: state.authentication.user,
    activities: state.activities,
    userProfile: state.userProfile,
    athleteStatistics: state.userProfile.athleteStatistics,
  };
}

class UserProfile extends Component<UserProfileProps, UserProfile> {
    private chartOptions = {
      legend: {
        display: false,
      },
      scales: {
        yAxes: [{
          ticks: {
            min: 0,
            max: 100,
          },
          scaleLabel: {
            display: true,
            labelString: 'Percentile',
          },
        }],
      },
    };

    componentWillMount() {
      this.setState({ isOwnProfile: false, hideSections: true });
    }

    componentWillReceiveProps(nextProps: UserProfileProps) {
      if (this.props.userId !== nextProps.userId) {
        this.props.dispatch(fetchProfileInfo(nextProps.userId));
        this.fetchActivities(nextProps.userId, this.props.activities);
        this.setProfileOwnership(Number(nextProps.userId));
      }
    }

    componentDidMount() {
      this.props.dispatch(fetchProfileInfo(this.props.userId));
      this.fetchActivities(this.props.userId, this.props.activities);
      this.setProfileOwnership(Number(this.props.userId));
    }

    render() {
      const { strengthGraphData } = this.props.userProfile;
      const { heatmapData } = this.props.userProfile;
      return (
        <>
          {this.props.userProfile.failed ? (
            <NotFound />
          ) : (
            <Container>
              <LoadingSegment isLoading={!this.props.userProfile.received} showLoadingDelay={0}>
                <ProfileTopSection
                  user={this.props.userProfile}
                  isOwnProfile={this.state.isOwnProfile}
                  statistics={this.props.athleteStatistics}
                />
              </LoadingSegment>
              {this.state.hideSections ? <div /> : (
                <Grid stackable>
                  <Grid.Column width={8}>
                    <Segment className="with-header">
                      <SegmentHeader>{this.props.t('profileStats.abilitiesOverview')}</SegmentHeader>
                      <LoadingSegment isLoading={!this.props.userProfile.received} showLoadingDelay={0}>
                        <Bar height={150} data={this.props.userProfile.polarChartData} options={this.chartOptions} />
                      </LoadingSegment>
                    </Segment>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Segment className="with-header">
                      <SegmentHeader>{this.props.t("profileStats.strengthProgression")}</SegmentHeader>
                      <LoadingSegment isLoading={!this.props.userProfile.received} showLoadingDelay={0}>
                        <StrengthProgressionChart strengthGraphData={strengthGraphData} />
                      </LoadingSegment>
                    </Segment>
                  </Grid.Column>
                </Grid>
              )}
              <Segment className="with-header">
                <SegmentHeader>{this.props.t("profileStats.activitiesHeader")}</SegmentHeader>
                <LoadingSegment isLoading={!(this.props.userProfile.received && this.props.activities.received)} showLoadingDelay={0}>
                  <Header>{this.props.t("profileStats.overview")}</Header>
                  <Responsive minWidth={ResponsiveUtil.MOBILE_MAX_WIDTH}>
                    <CalendarHeatmap nbOfMonth={9} heatmapData={heatmapData} />
                  </Responsive>
                  <Responsive maxWidth={ResponsiveUtil.MOBILE_MAX_WIDTH}>
                    <CalendarHeatmap nbOfMonth={6} heatmapData={heatmapData} />
                  </Responsive>
                  <h4>
                    {this.props.t("profileStats.totalTrainingTime")}:{" "} 
                    {this.displayTotalTrainingTime()}
                  </h4>
                  <Header>{this.props.t("activityTable.recent")}</Header>
                  <ActivityPreview activities={this.props.activities} userId={this.props.userId} />
                </LoadingSegment>
              </Segment>
            </Container>
          )}
        </>
      );
    }

    private setProfileOwnership = (id: number) => {
      if (id === this.props.user.id) {
        this.setState({ isOwnProfile: true });
      } else {
        this.setState({ isOwnProfile: false });
      }
    }

    private fetchActivities(id: string, activities: ActivitiesState) {
      const query = { activitiesPerPage: 5 };
      this.props.dispatch(getActivities(id, query));
    }

    private displayTotalTrainingTime() {
      if (typeof this.props.athleteStatistics === 'undefined') {
        return 'Undefined';
      }
      if (this.props.athleteStatistics.totalTrainingTime > 3600) {
        return this.props.t('profileStats.hourCount', {count: this.toHours(this.props.athleteStatistics.totalTrainingTime)});
      } 
      if (this.props.athleteStatistics.totalTrainingTime > 60) {
        return this.props.t('profileStats.minuteCount', {count: this.toMinutes(this.props.athleteStatistics.totalTrainingTime)});
      }
      return this.props.t('profileStats.secondCount', {count: Math.round(this.props.athleteStatistics.totalTrainingTime)});
    }

    private toHours(timeInSecs: number): number {
      return Math.round(timeInSecs / (60 * 60));
    }

    private toMinutes(timeInSecs: number): number {
      return Math.round(timeInSecs / (60));
    }
}

export default withTranslation('profile')(connect(mapStateToProps)(UserProfile));
