import React, { useState } from 'react';
import { Card, Button } from 'semantic-ui-react';
import { IRestBloc } from '../classes';

interface IRestBlocState{
    restTime: number
}
interface IRestBlocProps{
    handleDelete: Function,
    bloc: IRestBloc
}
const RestBloc =(props: IRestBlocProps) =>{
    return (
        <Card>
            <Card.Content>
                <Card.Header> Rest</Card.Header>
                <Button negative onClick={() => props.handleDelete(props.bloc.id)}> X </Button>
            </Card.Content>
            <Card.Content>
                <Card.Header> Time</Card.Header>
                <Card.Description><Button onClick={() => props.bloc.setTime('+')}> + </Button>{props.bloc.time}<Button onClick={() => props.bloc.setTime('-')}> - </Button></Card.Description>
            </Card.Content>
        </Card>
    )
}

export default RestBloc;