import * as React from "react";
import { Grid } from "semantic-ui-react";
import "./confirmEmail.css";
import ConfirmEmailPanel from "../../containers/ConfirmEmailPanel/ConfirmEmailPanel";

class ConfirmEmail extends React.Component<{}, {}> {
    render() {
        return (
            <Grid centered={true} verticalAlign="middle" className="signup-page">
                <Grid.Column>
                    <ConfirmEmailPanel/>
                </Grid.Column>
            </Grid>
        );
    }
}

export default ConfirmEmail;
