import * as React from "react";
import { AcquisitionSubType } from "common";
import { SemanticCOLORS, LabelProps, Label } from "semantic-ui-react";
import { capitalize } from "lodash";

interface ActivitySubTypeLabelProps extends LabelProps {
    subtype: AcquisitionSubType;
}

class ActivitySubTypeLabel extends React.Component<ActivitySubTypeLabelProps> {
    public static getColor(subtype: AcquisitionSubType): SemanticCOLORS | undefined {
        switch (subtype) {
            case "resistance":
                return "green";
            case "endurance":
                return "yellow";
            case "strength":
                return "red";
            default:
                return undefined;
        }
    }

    public render() {
        let { subtype, ...labelProps } = this.props;
        let content = subtype || "None";
        return (
            <Label color={ActivitySubTypeLabel.getColor(subtype)} {...labelProps}>
                {capitalize(content)}
            </Label>);
    }
}

export default ActivitySubTypeLabel;