import { api } from "../config/axiosConfig";
import { CreateGymBody, ModifyGymBody } from "common";
import { UpdateGymCardBody } from "common";

export class GymService {
    public static createGym = (gym: CreateGymBody) => {
        let requestUrl = "/v1/gyms";
        return api().post(requestUrl, gym);
    }

    public static getGyms = () => {
        let requestURL = "/v1/gyms";
        return api().get(requestURL);
    }

    public static modifyGym = (gymId: number, body: ModifyGymBody) => {
        let requestURL = "/v1/gyms/" + gymId;
        return api().patch(requestURL, body);
    }

    public static getSubscription = (gymId: number) => {
        let requestURL = `/v1/gyms/${gymId}/subscription`;
        return api().get(requestURL);
    }

    public static updateDefaultCard = (gymId: number, stripeTokenId: string) => {
        let requestURL = `/v1/gyms/${gymId}/card`;
        const body: UpdateGymCardBody = { stripeTokenId };
        return api().patch(requestURL, body);
    }

    public static closeGym = (gymId: number) => {
        let requestURL = `/v1/gyms/${gymId}/close`;
        return api().patch(requestURL);
    }

    public static getStandardPlan = () => {
        let requestURL = "/v1/gyms/plan";
        return api().get(requestURL);
    }
}
