import * as React from "react";
import { Search, SearchProps, SearchResultData } from "semantic-ui-react";
import { api } from "../../config/axiosConfig";
import { AxiosRequestConfig } from "axios";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { WithTranslation, withTranslation } from 'react-i18next';

interface SearchBarProps extends RouteComponentProps<void>, WithTranslation {
    dispatch: Dispatch<void>;
}

interface SearchBarState {
    loading: boolean;
    results: Array<Object> | Object;
    value: string;
    showNoResults: boolean;
}

class SearchBar extends React.Component<SearchBarProps, SearchBarState> {

    timeout: any = null;

    public componentWillMount() {
        this.resetComponent();
    }

    render() {
        const { loading, results, value, showNoResults } = this.state;
        return(
            <div>
            {true ? <div/> : (<Search
                loading={loading}
                size={"mini"} 
                fluid={true}
                placeholder={this.props.t('search.searchBar')}
                results={results}
                value={value}
                onSearchChange={this.handleSearchChange}
                onResultSelect={this.handleResultSelect}
                showNoResults={showNoResults}
            />)}
            </div>
        );
    }

    private handleSearchChange = (e: React.SyntheticEvent<HTMLElement>, data: SearchProps) => {
        if (!data.value) {
            return this.resetComponent();
        }
        
        this.setState({ loading: true, value: data.value, showNoResults: false });

        clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            let requestURL: string = `/v1/athletes/search`;
            let query = { name: data.value };
            let config: AxiosRequestConfig = { params: query };
            
            api()
            .get(requestURL, config)
            .then((response) => {
                this.setState({ loading: false, results: response.data, showNoResults: true });
            })
            .catch((error) => {
                this.setState({ loading: false, showNoResults: true });
            });
        }, 500);
    }

    private resetComponent = () => {
        const state = {
            loading: false,
            results: [],
            value: "",
            showNoResults: false
        };

        this.setState(state);
    }

    private handleResultSelect = (event: React.MouseEvent<HTMLDivElement>, data: SearchResultData) => {
        this.props.history.push(`/athletes/${data.result.id}/profile`);
        this.resetComponent();
    }

}

export default withTranslation('common')(connect()(withRouter(SearchBar)));