import React from 'react';
import { Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import Timer from '../../components/Timer/Timer';
import Bluetooth from '../../components/Bluetooth/Bluetooth';
import LiveGraph from '../../components/Exercise/LiveGraph/LiveGraphRaw';
import { hideSideBar, showSideBar } from '../../actions/navBar.actions';
import * as sensorDataAnalysis from '../../utils/sensorDataAnalysis';
import * as bluetooth from '../../utils/bluetooth';
import { uploadActivity, handleActivityStatus, handleCurrentRepUpdate, handleTimerStatus, handleCalibrationStatus } from '../../actions/activities.actions';
import {  Dimmer, Header, Modal, Loader } from 'semantic-ui-react'
import Navigation from '../../components/Exercise/Navigation/Navigation';
import { connect } from "react-redux";
import { State } from '../../reducers';
import { handleBluetoothState, bluetoothConnection } from '../../actions/bluetooth.actions';
import "./Exercise.css";
import Calibration from '../../components/calibration/Calibration';

interface ExerciseProps extends RouteComponentProps{
    dispatch: Dispatch<void>;
    uploadedId: number;
    activityStatus: string;
    bluetoothConnected: boolean;
    calibrationStatus:string;
    weightUnit:string;
}
interface ExerciseState{
  graphData: [],
  paused:boolean,
  strength: number,
  pulledStrength: any,
  userWeight: any,
  addedWeights:number,
  maxTime:number,
  maxValue:number,
  calibrationData: Array<number>,
  done:boolean
}
const mapStateToProps = (state: State) => ({
  uploadedId: state.activities.uploadedId,
  activityStatus: state.activities.activityStatus,
  bluetoothConnected: state.bluetooth.bluetoothConnected,
  calibrationStatus: state.activities.calibrationStatus,
  weightUnit: state.authentication.user.displaySettings.weightUnit
});
let weightUnitFactor;
class Exercise extends React.Component<ExerciseProps, ExerciseState> {
  constructor(props: ExerciseProps) {
    super(props);
    this.state = {
      done: false,
      graphData: [],
      paused:false,
      strength: 0,
      pulledStrength: 0,
      userWeight: [],
      maxTime:0,
      maxValue:0,
      addedWeights:0,
      calibrationData:new Array<number>()
    };
    this.props.dispatch(handleCurrentRepUpdate({
      time: 216000
    }))
    this.startActivity = this.startActivity.bind(this);
    this.pauseActivity = this.pauseActivity.bind(this);
    this.restartActivity = this.restartActivity.bind(this);
    this.finishActivity = this.finishActivity.bind(this);
    this.updateGraph = this.updateGraph.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.updateStrength = this.updateStrength.bind(this);
    this.calibrate = this.calibrate.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(hideSideBar());
    this.props.dispatch(hideSideBar());
    this.props.dispatch(handleBluetoothState('off'));
    this.props.dispatch(bluetoothConnection(bluetooth._connected));
    if (bluetooth._connected)
      this.props.dispatch(handleActivityStatus('calibrate'));
    else
      this.props.dispatch(handleActivityStatus('stop'));
    sensorDataAnalysis.freeDataArray();

    sensorDataAnalysis.freeDataArray();
    weightUnitFactor = this.props.weightUnit == 'kg'?1:2.2;
  }
  componentWillUnmount(){
    this.props.dispatch(handleActivityStatus('stop'));
    this.props.dispatch(handleBluetoothState('off'));
    sensorDataAnalysis.freeDataArray();
  }
  componentDidUpdate(prevProps){
    if (this.props.calibrationStatus !== prevProps.calibrationStatus) {
      this.setState(({ calibrationData }) => {
        calibrationData.length = 0;
        return { calibrationData };
      });
    }
  }
  startActivity() {
    const actStatus = this.props.activityStatus == 'pause' ? 'continue' : 'start';
    if(actStatus == 'start') 
      sensorDataAnalysis.onStart();
    this.props.dispatch(handleActivityStatus(actStatus));
    this.props.dispatch(handleTimerStatus(actStatus));
    this.setState({
      paused: false
    });
  }

  pauseActivity() {
    if(this.props.activityStatus == 'continue '||'start ')
    {
      this.props.dispatch(handleActivityStatus('pause'));
      this.props.dispatch(handleTimerStatus('pause'));
      this.setState({
        paused: true,
      });
    }
  }
  restartActivity() {
    this.setState({
      userWeight:[],
      graphData: [],
      paused:false
    });
    sensorDataAnalysis.freeDataArray();
    this.props.dispatch(handleActivityStatus('calibrate'));
    this.props.dispatch(handleTimerStatus('stop'));
    this.props.dispatch(handleCalibrationStatus('stop'));
  }

  async finishActivity() {
    this.setState({done:true, paused: false});
    this.props.dispatch(handleTimerStatus('pause'));
    if (sensorDataAnalysis._weightDataArray.length >0) {
      const archive = await sensorDataAnalysis.onEnd(this.state.userWeight, this.props.location.state, this.state.addedWeights);
      // @ts-ignore
      this.props.dispatch(uploadActivity(archive)).then(()=>{
        this.props.history.push(`/activities/${this.props.uploadedId}`);

        this.props.dispatch(showSideBar());
        this.restartActivity();
        this.props.dispatch(handleBluetoothState('off'));
        this.props.dispatch(handleActivityStatus('stop'));
      });
    }
  }
  handleOut(){
    if(this.props.location.state.selectedSetup == 'hangboard')
      return this.state.userWeight
    return 0
      
  }
  updateGraph(time: number) {
    let strength = sensorDataAnalysis.pulledWeight(this.props.location.state.selectedSetup, this.state.userWeight, this.state.strength, this.state.addedWeights);
    let strengthData = strength=='Out'? this.handleOut(): strength;
    sensorDataAnalysis.addDataToString(Math.floor(strengthData * 100) / 100);
    
    this.setState((state) => {
      const graphData: [] = state.graphData.concat([{
        x: time,
        y: strengthData* weightUnitFactor,
      }]);

      let value = Math.max.apply(
        Math,
        graphData.map((o: any) => o.y),
      );
      value <= 5 ? (value = 5) : (value = value);
      //const maxTime = time;
      return {
        graphData,
        maxValue: parseFloat(value),
        maxTime: time,
        pulledStrength: strength,
      };
    });
  }

  updateTime(time :number) {
    if (this.props.activityStatus !== 'pause') this.updateGraph(time);
  }
  updateStrength(sensorData: any) {
    if (this.props.activityStatus == 'calibrate') {
      if(this.props.calibrationStatus !== 'pending' && this.props.calibrationStatus !== 'user stepped out'){
        this.setState(({calibrationData})=>{
          calibrationData.push(sensorData);
          return {calibrationData: [].concat(...calibrationData)};
        });
      }
    } else {
      this.setState({
        strength: sensorData,
      });
    }
  }

  calibrate(userWeight, addedWeights){
    this.setState({
      userWeight: userWeight,
      addedWeights: addedWeights,
      paused:true
    });
  }
  showPulledStrength() {
      let html = () =>
        <>
          <span style={{ fontFamily: "open sans", fontWeight: 400, fontSize: "5rem" }}>{Math.floor(this.state.pulledStrength*weightUnitFactor)}</span>
          <span style={{ fontFamily: "open sans", fontWeight: 400, fontSize: "2rem" }}>{Math.floor(10 * (this.state.pulledStrength*weightUnitFactor - Math.floor(this.state.pulledStrength*weightUnitFactor)))}</span>
        </>;
      if (this.state.pulledStrength == 'Out')
        html = () =><span style={{ fontFamily: "open sans", fontWeight: 400, fontSize: "5rem" }}>{this.state.pulledStrength}</span>
      return (
        <span className="rawStrength">
          {html()}
        </span>
      );
  }
  exerciseNotDone(){
    if(this.state.done){
      return (
      <Dimmer active>
        <Loader style={{display:'block'}}>Loading</Loader>
      </Dimmer>);
    }
    else {
      return (
        <>
          <Bluetooth callback={this.updateStrength} />
          <Calibration callback={this.calibrate} sensorDataArray={this.state.calibrationData}/>
        </>)
    }
  }
  render() {
    return (
      <>
        {this.exerciseNotDone()}
        <div className="ui one column grid exerciseManager-container" onClick={this.pauseActivity}>
          <i className="close icon finishExercise" style={{ fontSize: '3.5em', color: '#6d6d6d' }} onClick={this.finishActivity} />
          <div className="timerRawPosition">
            <Timer
              updateTime={this.updateTime}
            />
          </div>
          {this.showPulledStrength()}
          <div className="row livegraph" style={{padding:'0px'}}>
            <LiveGraph
              chartData={this.state.graphData}
              maxTime={this.state.maxTime}
              maxValue={this.state.maxValue}
            />
          </div>
        </div>

        <Dimmer
          active={this.state.paused}
          verticalAlign='center'
        >
          <Header as='h2' inverted>
            Paused
            </Header>

          <Navigation
            leftIcon={'stop link'}
            leftIconAction={this.finishActivity}
            middleIcon={'play'}
            middleIconAction={this.startActivity}
            rightIcon={'undo link'}
            rightIconAction={this.restartActivity}
            style={{bottom:'auto'}}>
          </Navigation>
        </Dimmer>
      </>

    );
  }
}

export default connect(mapStateToProps)(Exercise);

