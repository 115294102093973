import * as React from "react";
import { CommonGym, GetGymsResponse } from "common";
import { GymService } from "../../services/gyms";
import LoadingSegment from "../LoadingSegment/LoadingSegment";
import GymsTable from "../GymsTable/GymsTable";

interface GymSectionState {
    gyms: CommonGym[];
    count: number;
    requesting: boolean;
    success: boolean;
}

class GymSection extends React.Component<{}, GymSectionState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            gyms: [],
            count: 0,
            requesting: false,
            success: false   
        };
    }

    public componentDidMount() {
        this.getGyms();
    }

    public render() {
        return (
            <LoadingSegment isLoading={!this.state.success}>
                <p>{`${this.state.count} Gyms`}</p>
                <GymsTable gyms={this.state.gyms}/>
            </LoadingSegment>
        );
    }

    private getGyms = async () => {
        this.setState({ requesting: true });
        return GymService.getGyms()
            .then((response) => {
                const { gyms, count } = response.data as GetGymsResponse;
                this.setState({ requesting: false, success: true, gyms, count });
            })
            .catch((error) => {
                this.setState({ requesting: false, success: false, gyms: [], count: 0 });
            });
    }
}

export default GymSection;