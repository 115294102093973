import { createStore, applyMiddleware } from 'redux';
import Store from 'react-redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reducer, { State } from '../reducers';
import { loadState, saveState } from '../utils/localStorage';
import { throttle } from 'lodash';
import * as axiosConfig from './axiosConfig';
import { fetchUserProfile } from '../actions/settings.actions';
import { composeWithDevTools } from 'redux-devtools-extension';
// Load State
const persistedState = loadState();

// Apply Middlewares
const production: boolean = process.env.NODE_ENV === 'production';
const middleware = production ? applyMiddleware(thunk) : composeWithDevTools(applyMiddleware(thunk, logger));

// Create store
const store : any = createStore(reducer, persistedState, middleware);

// Save state when there are changes
store.subscribe(
  throttle(
    () => {
      const state : State = store.getState() as State;
      saveState({ authentication: state.authentication });
    },
    1000,
  ),
);
// Set token into axios headers
const currentState: State = store.getState() as State;
axiosConfig.setToken(currentState.authentication.token || '');

// Fetch user
if (currentState.authentication.user) {
  store.dispatch(fetchUserProfile(currentState.authentication.user.id));
}

export default store;
