import * as React from "react";
import { Message , Icon, MessageContent, MessageHeader } from "semantic-ui-react";
import { WithTranslation, withTranslation } from 'react-i18next';

interface BrowserAlertProps extends WithTranslation {
    visible: boolean,
    onClick(): any
}

class BrowserAlert extends React.Component<BrowserAlertProps> {
    render() {
        const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    
        if (this.props.visible && !isChrome && !isIOS) {
            return (
                <React.Fragment>
                    <Message id="alert" negative={true}  style={{position: "fixed", top: "40px", width: "100%", zIndex: "100"}}>
                        <Icon name="close" className="closeBtn" onClick={this.props.onClick}></Icon>
                        <MessageHeader>{this.props.t('browserAlert.header')}</MessageHeader>
                        <MessageContent>{this.props.t('browserAlert.content')} <a target="_blank" href="https://entralpi.com/pages/quickstart">{this.props.t('browserAlert.guideLink')}</a></MessageContent>
                     </Message>
                </React.Fragment>
            );
        } else if (this.props.visible && isIOS) {
            return (
                <React.Fragment>
                    <Message id="alert" negative={true}  style={{position: "fixed", top: "40px", width: "100%", zIndex: "100"}}>
                        <Icon name="close" className="closeBtn" onClick={this.props.onClick}></Icon>
                        <MessageHeader>{this.props.t('browserAlert.headerIOS')}</MessageHeader>
                        <MessageContent>{this.props.t('browserAlert.content')} <a target="_blank" href="https://entralpi.com/pages/quickstart">{this.props.t('browserAlert.guideLink')}</a></MessageContent>
                    </Message>
                </React.Fragment>
            );
        } else return null;
    }
}

export default withTranslation('common')(BrowserAlert);
