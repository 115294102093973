import * as React from "react";
import { Switch, Route } from "react-router";
import AdminHome from "./../Admin/AdminHome";
import AdminRights from "./../../containers/AdminRights/AdminRights";
import AdminGymsPage from "../../components/AdminGymsPage/AdminGymsPage";

class Admin extends React.Component<{}, {}> {
    render() {
        return (
            <Switch>
                <Route exact={true} path="/admin" component={AdminHome}/>
                <Route path="/admin/rights" component={AdminRights}/>
                <Route path="/admin/gyms" component={AdminGymsPage}/>
            </Switch>
        );
    }
}

export default Admin;