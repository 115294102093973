export interface IBlocGroup{
    blocs: [IBloc];
    multiplier: number;
    id;
    updateState(blocGroup: IBlocGroup): void;
    deleteBloc(id:number): void;
    deleteSelf(id: number):void;
    setMultiplier(param: "+"|"-");
    addBloc(type:"hang"|"rest", bloc?:IBloc);
}
export interface IBloc{
    time:number;
    id: number;
    blocGroupId: number;
    guideline: string;
    setTime(x:string): void;
    updateBloc(bloc: IBloc): void;
}
export interface ITrainingBloc extends IBloc{
    intensity: number;
    fingers: [[number], [number]];
    setIntensity(param: "+"|"-"):void;
    setFingers(handIndex:number, findIndex:number):void;
    setGuideline(e:any, {value:string}):void;
}

export interface IRestBloc extends IBloc{}

export class BlocGroupClass implements IBlocGroup{
    public blocs; multiplier; id; updateState; deleteSelf;
    constructor(props){
        this.id = props.id;
        this.multiplier = props.multiplier ||1;
        this.blocs = [];
        this.updateState = props.updateState;
        this.deleteSelf= props.delete;
        if(props.blocs)
            this.generateBlocs(props.blocs)
    }
    public generateBlocs(newBlocs:[IBloc]){
        this.blocs=[];
        return newBlocs.map((bloc:IBloc)=>{
            if(bloc.intensity)
                this.addBloc('hang',bloc);
            else
                this.addBloc('rest',bloc);
        });  
    }
    public setMultiplier=(param: string)=>{
        if(param == '+')
            this.multiplier +=1;
        else if(param == '-' && this.multiplier >1)
            this.multiplier-=1;
        this.updateState(this);
    }
    public updateBloc= (newBloc: IBloc)=>{
        const blocIndex = this.blocs.findIndex(bloc=> bloc.id == newBloc.id);
        this.blocs[blocIndex] = newBloc;
        this.updateState(this);
    }
    public deleteBloc = (id:number) => {
        const blocIndex = this.blocs.findIndex(bloc => bloc.id == id);
        this.blocs.splice(blocIndex, 1);
        this.updateState(this);
    }
    public addBloc= (type, bloc)=>{
        let newBloc: IBloc;
        const blocIndex = this.blocs.length!=0 ? this.blocs.length-1 : 0
        const lastBlocId = this.blocs.length!=0 ?this.blocs[blocIndex].id : 0;
        if (type== "hang"){
            if(bloc)
                newBloc = new TrainingBlocClass({ id: lastBlocId + 1,blocGroupId: this.id, handleDelete: this.deleteBloc, 
                    handleUpdate: this.updateBloc, bloc:bloc});
            else
                newBloc = new TrainingBlocClass({ id: lastBlocId + 1,blocGroupId: this.id, handleDelete: this.deleteBloc, 
                    handleUpdate: this.updateBloc, guideline: 'guideline.hang',});
        }
            
        else{
            if(bloc)
                newBloc = new RestBlocClass({ id: lastBlocId + 1, blocGroupId: this.id,handleDelete: this.deleteBloc, handleUpdate: this.updateBloc, bloc:bloc });
            else
                newBloc = new RestBlocClass({ id: lastBlocId + 1, blocGroupId: this.id,handleDelete: this.deleteBloc, handleUpdate: this.updateBloc, guideline:'guideline.rest'});
        }
            
        this.blocs.push(newBloc);
        if(!bloc) this.updateState(this);
    }
}

export class BlocClass implements IBloc{
    public time;id; handleDelete; updateBloc; blocGroupId; guideline;
    constructor(props){
        this.id = props.id;
        this.time = props.bloc ? props.bloc.time : 10;
        this.handleDelete = props.handleDelete;
        this.updateBloc = props.handleUpdate;
        this.blocGroupId = props.blocGroupId;
        this.guideline  = props.bloc ? props.bloc.guideline : props.guideline
    }
    public setTime = (param: string) => {
        if (param == '+')
            this.time += 1;
        else if (param == '-' && this.time > 2)
            this.time -= 1;
        this.updateBloc(this);

    }
}
export class TrainingBlocClass extends BlocClass implements ITrainingBloc{
    public intensity; fingers;
    constructor(props){
        super(props);
        this.intensity =props.bloc? props.bloc.intensity : 50;
        this.fingers = props.bloc ? props.bloc.fingers : [[1,1,1,1,0],[0,1,1,1,1]];
    }
    public setIntensity = (param: string) => {
        if (param == '+' && this.intensity < 100)
            this.intensity += 5;
        else if (param == '-' && this.intensity > 5)
            this.intensity -= 5;
        this.updateBloc(this);


    }
    public setFingers=(handIndex, fingerIndex)=>{
        this.fingers[handIndex][fingerIndex] = this.fingers[handIndex][fingerIndex] == 0?1:0;
        this.updateBloc(this);
    }
    public setGuideline=(e, {value})=>{
        this.guideline =value;
        this.updateBloc(this);
    }
}
export class RestBlocClass extends BlocClass implements IRestBloc{
    constructor(props){
        super(props);
    }
}