import React, { useState } from 'react';
import { Card, Button, Dropdown } from 'semantic-ui-react';
import { IRestBloc } from '../classes';

interface ISubtypeDropDownProps{
    handleChanges: any;
    error: any
    value: string
}
const selectOptions = [
    { key: "strength", text: "Strength", value: "strength" },
    { key: "endurance", text: "Endurance", value: "endurance" },
    { key: "resistance", text: "Resistance", value: "resistance" }
];
const SubtypeDropDown =(props: ISubtypeDropDownProps) =>{
    return(<Dropdown
        placeholder='Select a category'
        fluid
        selection
        options={selectOptions}
        onChange={props.handleChanges}
        id='workoutSubtype'
        error= {props.error}
        value = {props.value}
  />)
}

export default SubtypeDropDown;