import * as React from "react";
import "./GymPage.css";
import { getAthleteGymRequest } from "../../services/athlete";
import { withRouter, RouteComponentProps, Switch, Route, Redirect } from "react-router";
import { CommonGym, StripeBillingInfo, CommonCard, GymStatus } from "common";
import GymGeneralSection from "../../components/GymPageComponents/GymGeneralSection";
import { Header, Grid, Button, Segment } from "semantic-ui-react";
import GymPageMenu from "./components/GymPageMenu";
import GymOrderSection from "../../components/GymPageComponents/GymOrderSection";
import GymBillingSection from "../../components/GymPageComponents/GymBillingSection";
import { GymService } from "../../services/gyms";
import { Elements } from "react-stripe-elements";
import { PromiseManager } from "../../utils/PromiseUtils";
import DeleteGymModal from "../../components/GymPageComponents/DeleteGymModal";

interface GymPageState {
    gym?: CommonGym;
    billing?: StripeBillingInfo;
    deleteModal: {
        open: boolean;
        loading: boolean;
        error: string;
    };
}

class GymPage extends React.Component<RouteComponentProps<{ id: string }>, GymPageState> {
    private promises: PromiseManager = new PromiseManager();

    constructor(props: RouteComponentProps<{ id: string }>) {
        super(props);
        this.state = {
            deleteModal: {
                open: false,
                loading: false,
                error: ""
            }
        };
    }

    public componentDidMount() {
        this.getGym();
        this.getSubscription();
    }

    public componentWillUnmount() {
        this.promises.cancelAll();
    }

    public onEditGeneralSuccess = (gym: CommonGym) => {
        this.setState({ gym });
    }

    public onEditBillingSuccess = (card: CommonCard) => {
        const billing = { ...this.state.billing as StripeBillingInfo, card };
        this.setState({ billing });
    }

    public handleModalCancel = () => {
        this.setState({ deleteModal: { ...this.state.deleteModal, open: false } });
    }

    public handleDeleteClick = () => {
        this.setState({ deleteModal: { ...this.state.deleteModal, open: true } });
    }

    render() {
        const { gym, billing } = this.state;
        if (!gym || !billing) {
            return null;
        }

        const modal = this.state.deleteModal;

        return (
            <div className="gym-page-container">
                <Segment>
                    <Grid>
                        <Grid.Column width={8}><Header as="h1">{gym.name}</Header></Grid.Column>
                        <Grid.Column width={8} textAlign="right">
                            <Button size="small" basic={true} onClick={this.handleDeleteClick}>Delete</Button>
                        </Grid.Column>
                    </Grid>
                    <GymPageMenu />
                </Segment>
                <Switch>
                    <Route exact={true} path={`${this.props.match.url}/`} render={this.renderGeneral} />
                    <Route path={`${this.props.match.url}/general`} render={this.renderGeneral} />
                    <Route path={`${this.props.match.url}/rentals`} render={this.renderOrder} />
                    <Route path={`${this.props.match.url}/billing`} render={this.renderBilling} />
                    <Redirect to={`${this.props.match.url}/`} />
                </Switch>
                <DeleteGymModal
                    open={modal.open}
                    loading={modal.loading}
                    error={modal.error}
                    onDelete={this.deleteGym}
                    onCancel={this.handleModalCancel}
                />
            </div>
        );
    }

    public renderGeneral = () => {
        const gym = this.state.gym as CommonGym;
        return <GymGeneralSection gym={gym} onEditSuccess={this.onEditGeneralSuccess} />;
    }

    public renderOrder = () => {
        const gym = this.state.gym as CommonGym;
        return <GymOrderSection gym={gym} />;
    }

    public renderBilling = () => {
        const gym = this.state.gym as CommonGym;
        const billing = this.state.billing as StripeBillingInfo;
        return (
            <Elements>
                <GymBillingSection gym={gym} billing={billing} onEditSuccess={this.onEditBillingSuccess} stripe={null} elements={null} />
            </Elements>
        );
    }

    private getGym = () => {
        const promise = this.promises.add(getAthleteGymRequest(Number(this.props.match.params.id)));
        promise.then((response) => {
            const gym = response.data;
            if (gym.status === GymStatus.CLOSING || gym.status === GymStatus.DELETED) {
                this.props.history.replace("/settings/gyms");
            } else {
                this.setState({ gym });
            }
        }).catch(() => {
            // Don't care about the error
        });
    }

    private getSubscription = () => {
        const promise = this.promises.add(GymService.getSubscription(Number(this.props.match.params.id)));
        promise.then((response) => {
            this.setState({ billing: response.data });
        }).catch(() => {
            // Don't care about the error
        });
    }

    private deleteGym = () => {
        this.setState({ deleteModal: { ...this.state.deleteModal, loading: true } });
        const promise = this.promises.add(GymService.closeGym(Number(this.props.match.params.id)));
        promise.then((response) => {
            this.setState({ deleteModal: { ...this.state.deleteModal, loading: false } });
            this.props.history.replace("/settings/gyms");
        }).catch((error) => {
            if (!error.isCanceled) {
                const errorMessage = error.response.data || "An error occurred while handling your request.";
                this.setState({ deleteModal: { ...this.state.deleteModal, loading: false, error: errorMessage } });
            }
        });
    }
}

export default withRouter(GymPage);