import { adminConstants } from "./../../../constants/admin.constants";
import { CommonUser } from "common";

export interface AdminListState { 
    requesting: boolean;
    received: boolean;
    admins: Array<CommonUser>;
    total: number;
}

const defaultState = {
    requesting: false,
    received: false,
    admins: [],
    total: 0,
};

export const adminList = (state: AdminListState = defaultState, action: any) => {
    switch (action.type) {
        case adminConstants.ADMIN_LIST_REQUEST:
            return {...state, requesting: true, received: false};
        case adminConstants.ADMIN_LIST_SUCCESS:
            return {
                ...state,
                requesting: false,
                received: true,
                admins: action.data.admins,
                total: action.data.total,
            };
        case adminConstants.ADMIN_LIST_FAILURE:
            return {...defaultState};
        default:
            return {...state};
    }
};