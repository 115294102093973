import * as React from "react";
import { Form, Input } from "semantic-ui-react";
import { expect, validateForm } from "../../utils/Validator";
import { isEmpty } from "lodash";

export interface FormAddressFields {
    line: string;
    city: string;
    province: string;
    zip: string;
    country: string;
}

interface FormAddressState {
    values: FormAddressFields;
    errors: Partial<FormAddressFields>;
}

interface FormAddressProps {
    onChange?: Function;
}

class FormAddress extends React.Component<FormAddressProps, FormAddressState> {

    public static defaultValues: FormAddressFields = {
        line: "",
        city: "Montréal",
        province: "QC",
        zip: "",
        country: "Canada"
    };

    public validations = [
        expect("line").required(),
        expect("city").required(),
        expect("province").required(),
        expect("zip").required()
    ];

    constructor(props: FormAddressProps) {
        super(props);
        this.state = {
            values: FormAddress.defaultValues,
            errors: {}
        };
    }

    public handleInputChange = (event: React.SyntheticEvent<HTMLInputElement>, data: any) => {
        const { name, value } = event.target as HTMLInputElement;
        const values = { ...this.state.values };
       // values[name] = value;
        this.setState({ ...this.state, values });
        if (this.props.onChange) {
            this.props.onChange(values);
        }
    }

    public validate(): boolean {
        const errors = validateForm(this.state.values, this.validations);
        this.updateErrors(errors);
        return isEmpty(errors);
    }

    public updateErrors(errors: Partial<FormAddressFields>) {
        this.setState({ errors });
    }

    public render() {
        const { errors, values } = this.state;
        return (
            <React.Fragment>
                <Form.Field onChange={this.handleInputChange} error={"line" in errors}>
                    <label>Address</label>
                    <Input name="line" value={values.line} />
                    <p>{errors.line}</p>
                </Form.Field>
                <Form.Group widths="equal">
                    <Form.Field>
                        <label>City</label>
                        <Input name="city" value={values.city} readOnly={true} />
                        <p>{errors.city}</p>
                    </Form.Field>
                    <Form.Field>
                        <label>Province</label>
                        <Input name="province" value={values.province} readOnly={true} />
                        <p>{errors.province}</p>
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field onChange={this.handleInputChange} error={"zip" in errors}>
                        <label>Zip Code</label>
                        <Input name="zip" value={values.zip} />
                        <p>{errors.zip}</p>
                    </Form.Field>
                    <Form.Field >
                        <label>Country</label>
                        <Input name="country" value={values.country} readOnly={true} />
                    </Form.Field>
                </Form.Group>
            </React.Fragment>
        );
    }
}

export default FormAddress;