import * as React from "react";
import SettingRow from "../../components/SettingRow/SettingRow";
import { Link } from "react-router-dom";
import { Container, Form, ButtonProps, Header, Segment, Dropdown, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { State } from "../../reducers";
import { CommonUser, GradingSystem, CommonDisplaySettings, WeightUnit, Lang } from "common";
import EditableSection from "../../components/EditableSection/EditableSection";
import { modifyDisplaySettings } from "../../actions/settings.actions";
import { withTranslation, WithTranslation } from "react-i18next";

interface SettingsDisplayProps extends WithTranslation {
    user: CommonUser;
    dispatch: Dispatch<void>;
}
interface SettingsDisplayState {
    editingDisplaySettings: boolean;
    displaySettings: CommonDisplaySettings;
}

const mapStateToProps = (state: State) => (
    { user: state.authentication.user }
);

class SettingsDisplay extends React.Component<SettingsDisplayProps, SettingsDisplayState> {

    constructor(props: any) {
        super(props);
        this.state = { 
            editingDisplaySettings: false, 
            displaySettings: this.props.user.displaySettings
        };
    }
    private gradingOptions: { value: GradingSystem, text: string }[] = [
        { value: "vermin", text: this.props.t('display.VScale') },
        { value: "yosemite", text: this.props.t('display.YosemiteScale') },
    ];
    private weightUnitOptions: { value, text: string }[] = [
        { value: "kg", text: this.props.t('display.kg') },
        { value: "lbs", text: this.props.t('display.lbs') },
    ];
    private langOptions: { value, text: string }[] = [
        { value: "en", text: this.props.t('display.en') },
        { value: "fr", text: this.props.t('display.fr') },
    ];
    render() {
        
        let gradingSystem = this.gradingOptions.find(option => option.value === this.props.user.displaySettings.gradingSystem);
        let gradeSystemText = "";
        if (gradingSystem !== undefined) {
            gradeSystemText = gradingSystem.text;
        }
        let weightUnitSystem = this.weightUnitOptions.find(option => option.value === this.props.user.displaySettings.weightUnit);
        let weightUnitSystemText = "";
        if (weightUnitSystem !== undefined) {
            weightUnitSystemText = weightUnitSystem.text;
        }
        let lang = this.langOptions.find(option => option.value === this.props.user.displaySettings.lang);
        let langText = "";
        if (lang !== undefined) {
            langText = lang.text;
        }

        const editingDisplaySettings = this.state.editingDisplaySettings;

        return (
            <Segment>
                <EditableSection editing={editingDisplaySettings} onClick={this.onClickCallback} iconOffset={{right: 0, top: 8}}>
                    <EditableSection.Content>
                        <Container>
                            <Header as="h1">{this.props.t('display.header')}</Header>
                            <SettingRow title={this.props.t('display.subGrading')} content={gradeSystemText} />
                            <SettingRow title={this.props.t('display.subWeightUnit')} content={weightUnitSystemText} />
                            <SettingRow title={this.props.t('display.subLang')} content={langText} />
                        </Container>
                    </EditableSection.Content>
                    <EditableSection.Form>
                        <Container>
                            <Form>
                                <Header as="h1">{this.props.t('display.header')}</Header>
                                <Form.Field>
                                    <Header as="h4">{this.props.t('display.subGrading')}</Header>
                                    <Dropdown
                                        placeholder={this.props.t('display.subGrading')}
                                        selection={true}
                                        options={this.gradingOptions}
                                        onChange={this.handleChanges}
                                        value={this.state.displaySettings.gradingSystem}
                                    />
                                    <Header as="h4">{this.props.t('display.subWeightUnit')}</Header>
                                    <Dropdown
                                        placeholder={this.props.t('display.subWeightUnit')}
                                        selection={true}
                                        options={this.weightUnitOptions}
                                        onChange={this.handleChanges}
                                        value={this.state.displaySettings.weightUnit}
                                    />
                                    <Header as="h4">{this.props.t('display.subLang')}</Header>
                                    <Dropdown
                                        placeholder={this.props.t('display.subLang')}
                                        selection={true}
                                        options={this.langOptions}
                                        onChange={this.handleChanges}
                                        value={this.state.displaySettings.lang}
                                    />
                                </Form.Field>
                                
                                <Form.Group >
                                    <Form.Button primary={true} onClick={this.handleSubmitButton}>{this.props.t('common:btn.save')}</Form.Button>
                                    <Form.Button onClick={this.handleCancelButton}>{this.props.t('common:btn.cancel')}</Form.Button>
                                </Form.Group>
                            </Form>
                        </Container>
                    </EditableSection.Form>
                </EditableSection >
                <Button style={{ display: "flex", justifyContent: "center", alignItems: "center"}} as={Link} to="/flappybird" >{this.props.t('display.backToTraining')}</Button>
            </Segment >
        );
    }

    public handleChanges = (event: any, data) => {
        let displaySettings;
        if(data.placeholder.includes('Grading'))
            displaySettings = { ...this.state.displaySettings, gradingSystem: (data.value as GradingSystem) };
        else if (data.placeholder.includes('Weight'))
            displaySettings = { ...this.state.displaySettings, weightUnit: (data.value as WeightUnit) };
        else{
            displaySettings = { ...this.state.displaySettings, lang: (data.value as Lang) };
        }
        this.setState({ displaySettings: displaySettings });
    }

    onClickCallback = (e: Event) => {
        this.setState({ editingDisplaySettings: !this.state.editingDisplaySettings });
    }

    handleSubmitButton = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        this.props.dispatch(modifyDisplaySettings(this.state.displaySettings));
        this.setState({ ...this.state, editingDisplaySettings: false });
    }

    handleCancelButton = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        this.setState({ ...this.state, editingDisplaySettings: false });
    }
}

export default withTranslation('settings')(connect(mapStateToProps)(SettingsDisplay));
