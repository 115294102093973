import * as React from "react";
import { Loader, Segment, Transition } from "semantic-ui-react";
import "./LoadingSegment.css";

interface LoadingSegmentProps {
    isLoading: boolean;
    showLoadingDelay?: number;
}

interface LoadingSegmentState {
    showLoading: boolean;
}

class LoadingSegment extends React.Component<LoadingSegmentProps, LoadingSegmentState> {

    public static defaultProps: Partial<LoadingSegmentProps> = {
        showLoadingDelay: 400
    };

    private timeout: any = null;

    constructor(props: LoadingSegmentProps) {
        super(props);
        this.state = {
            showLoading: false
        };
    }

    public componentDidMount() {
        if (this.props.isLoading) {
            this.timeout = setTimeout(() => this.setState({ showLoading: true }), this.props.showLoadingDelay);
        }
    }

    public componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    public render() {
        return (
            <React.Fragment>
                {this.renderLoadingComponent()}
                {this.showChildren()}
            </React.Fragment>
        );
    }

    public renderLoadingComponent() {
        const component = (
            <Segment className="loader-segment">
                <Loader active={true}>Loading</Loader>
            </Segment>
        );

        return this.state.showLoading && this.props.isLoading ? component : null;
    }

    public showChildren() {
        const speed: number = !this.props.isLoading ? 1000 : 0;
        return (
            <Transition visible={!this.props.isLoading} animation="fade" duration={speed}>
                <div>
                    {this.props.children}
                </div>
            </Transition>
        );
    }
}

export default LoadingSegment;