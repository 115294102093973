import * as React from "react";
import UserProfile from "../../containers/UserProfile/UserProfile";
import { Component } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { State } from "../../reducers";
import { CommonUser } from "common";
import LoadingSegment from "../../components/LoadingSegment/LoadingSegment";

interface ProfileProps {
    match?: any;
    user: CommonUser;
    dispatch: Dispatch<void>;
}

interface ProfileState {
    id: number;
    loading: boolean;
}

const mapStatetoProps = (state: State) => ({
    user: state.authentication.user,
});

class Profile extends Component<ProfileProps, ProfileState> {
    state = {
        id: 0,
        loading: true
    };

    componentWillReceiveProps(nextProps: ProfileProps) {
        if (nextProps.match.params.id) {
            if (nextProps.match.params.id !== this.state.id) {
                this.setState({id: nextProps.match.params.id, loading: false });
            }
        } else {
            if (this.props.user.id !== this.state.id) {
                this.setState({id: this.props.user.id, loading: false });
            }
        }
    }

    componentWillMount() {
        let id = this.props.match.params.id ? this.props.match.params.id : this.props.user.id;
        this.setState({ id: id, loading: false });
    }

    render() {
        return (
            <LoadingSegment isLoading={this.state.loading}>
                <UserProfile userId={this.state.id.toString()} />
            </LoadingSegment>
        );
    }
}

export default connect(mapStatetoProps)(Profile);