import * as React from "react";
import SignUpPanel from "../../containers/SignUpPanel/SignUpPanel";
import { Grid } from "semantic-ui-react";
import "./SignUp.css";

class SignUp extends React.Component<{}, {}> {
    render() {
        return (
            <Grid centered={true} verticalAlign="middle" className="signup-page">
                <Grid.Column>
                    <SignUpPanel/>
                </Grid.Column>
            </Grid>
        );
    }
}

export default SignUp;
