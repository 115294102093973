import "./ImageUpload.css";
import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Button, Image } from "semantic-ui-react";
import { uploadImage } from "../../actions/settings.actions";
import { State } from "../../reducers";

interface ImageUploadProps {
    dispatch: Dispatch<void>;
    imageUrl: string;
}

const mapStateToProps = (state: State) => {
    return { imageUrl: state.authentication.user.imageUrl };
};

class ImageUpload extends React.Component<ImageUploadProps, {}> {
    // Used to trigger a click on a hidden input field
    _input: HTMLInputElement | null = null;

    render() {
        return (
            <div className="image-upload">
                <Image className="profile-picture" src={this.props.imageUrl} circular={true} />
                <Button className="input-button" content="Choose image" secondary={true} onClick={this.clickInput} />
                <input type="file" hidden={true} ref={input => this._input = input} onChange={this.uploadImage}/>
            </div>
        );
    }

    clickInput = () => {
        if (this._input) {
            this._input.click();
        }
    }

    uploadImage = () => {
        if (!this._input) { return; }

        const files = this._input.files;
        if (!files || files.length === 0) { return; }

        const image = files[0];
        if (image == null) {
          return alert("No file selected.");
        }
        this.props.dispatch(uploadImage(image));
    }
}

export default connect(mapStateToProps)(ImageUpload);
