import * as React from "react";
import { Table } from "semantic-ui-react";
import { CommonInterval } from "common";
import { IntervalTableRow } from "./IntervalTableRow";

interface ActivityTableRowProps {
    intervals?: CommonInterval[];
    weightUnit: string;
}

export class IntervalTable extends React.Component<ActivityTableRowProps, {}> {
    public render() {
        if (!this.props.intervals) {
            return null;
        }

        const intervals = this.props.intervals.map((interval) =>
            <IntervalTableRow key={interval.id} interval={interval} />
        );

        return (
            <Table color="orange" size="small" celled={true} unstackable={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Start time (seconds)</Table.HeaderCell>
                        <Table.HeaderCell>Duration (seconds)</Table.HeaderCell>
                        <Table.HeaderCell>Maximum Strength {this.props.weightUnit}</Table.HeaderCell>
                        <Table.HeaderCell>Average Strength {this.props.weightUnit}</Table.HeaderCell>
                        <Table.HeaderCell>Standard Deviation {this.props.weightUnit}</Table.HeaderCell>
                        <Table.HeaderCell>Strain ({this.props.weightUnit} * minutes)</Table.HeaderCell>
                        <Table.HeaderCell>Rate of Force Development 200ms</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {intervals}
                </Table.Body>
            </Table>
        );
    }
}
