import * as React from "react";
import { Grid, Item } from "semantic-ui-react";
import { CommonGym, CommonAddress, GymStatus } from "common";
import "./GymListItem.css";
import { withRouter, RouteComponentProps } from "react-router";
import GymStatusLabel from "../GymStatusLabel/GymStatusLabel";

interface GymListItemProps extends RouteComponentProps<void> {
    gym: CommonGym;
}

class GymListItem extends React.Component<GymListItemProps> {
    public render() {
        const gym = this.props.gym;
        return (
            <Grid stackable={false} className={this.getCSSClass()}>
                <Grid.Column width={13}>
                    <Item.Group unstackable={true} link={!this.isDisabled()} onClick={this.navigateToGym} >
                        <Item>
                            <Item.Image className="gym-picture" src={gym.icon} />
                            <Item.Content>
                                <Item.Header as="h4">{gym.name}</Item.Header>
                                <div>{CommonAddress.toString(gym.address)}</div>
                                <div>{`Number of systems: ${gym.systemCount}`}</div>
                            </Item.Content>
                        </Item>
                    </Item.Group>
                </Grid.Column>
                <Grid.Column width={3} verticalAlign="middle">
                    <GymStatusLabel status={gym.status} size="small"/>
                </Grid.Column>
            </Grid>
        );
    }

    private navigateToGym = () => {
        if (!this.isDisabled()) {
            const path = "/gyms/" + this.props.gym.id;
            this.props.history.push(path);
        }
    }

    private isDisabled = () => {
        return this.props.gym.status === GymStatus.CLOSING;
    }

    private getCSSClass() {
        const className: string[] = ["gym-list-item"];
        if (this.isDisabled()) {
            className.push("disabled");
        } else {
            className.push("hoverable");
        }
        return className.join(" ");
    }     
}

export default withRouter(GymListItem);