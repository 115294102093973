import * as React from "react";
import { Container } from "semantic-ui-react";
import NavBar from "../../containers/NavBar/NavBar";
import "./MainLayout.css";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { navBarConstants } from "../../constants/navBar.constants";
import { NavBarState } from "../../reducers/navBar.reducer";
import { State } from "../../reducers";
import BrowserAlert from "../../components/BrowserAlert/BrowserAlert";

interface MainLayoutProps {
    dispatch: Dispatch<void>;
    navBar: NavBarState;
}

const mapStatetoProps = (state: State) => {
    return {
        navBar: state.navBar
    };
};

class MainLayout extends React.Component<MainLayoutProps, { showBrowserAlert: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = {showBrowserAlert: true};
        this.closeBrowserAlert = this.closeBrowserAlert.bind(this);
    }

    closeSideNavBar = () => {
        if (this.props.navBar.showSideBar) {
            this.props.dispatch({
                type: navBarConstants.NAV_BAR_CLOSE_SIDEBAR,
            });
        }
    }

    closeBrowserAlert = () => {
        this.setState({showBrowserAlert: false})
    }

    render() {
        return (
            <React.Fragment>
                <BrowserAlert visible={this.state.showBrowserAlert} onClick={this.closeBrowserAlert}/>
                <NavBar />
                <Container className="main-layout-container" onClick={this.closeSideNavBar}>
                    {this.props.children}
                </Container>
            </React.Fragment>
        );
    }
}

export default connect(mapStatetoProps)(MainLayout);