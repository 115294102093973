import { workoutsConstants } from "./../constants/workouts.constants";
import { CommonAcquisition } from "common";


export interface WorkoutsState {
    requesting: boolean;
    received: boolean;
    workouts: Array<CommonAcquisition>;
}

const defaultState = {
    requesting: false,
    received: false,
    workouts: [],
};

export const workouts = (state: WorkoutsState = defaultState, action: any) => {
    switch (action.type) {
        case workoutsConstants.WORKOUTS_ALL_REQUEST:
            return { ...state, requesting: true, received: false };
        case workoutsConstants.WORKOUTS_ALL_SUCCESS:
            return {
                ...state,
                requesting: false,
                received: true,
                workouts: action.data.workouts,
            };
        case workoutsConstants.WORKOUTS_ALL_FAILURE:
            return { ...defaultState };
        default:
            return { ...state };    
    }
};

export const getWorkoutsSuccess = state => state.workouts;
export const getWorkoutsPending = state => state.pending;
export const getWorkoutsError = state => state.error;