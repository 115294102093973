import { userConstants } from "./../constants/user.constants";
import { SignUpFormErrors } from "../actions/signup.actions";

export interface SignUpState {
    requesting: boolean;
    errors: SignUpFormErrors;
}

const defaultState: SignUpState = {
    requesting: false,
    errors: {},
};

export const signup = (state: SignUpState = defaultState, action: any) => {
    let newState: SignUpState = {...state};

    switch (action.type) {
        case userConstants.SIGNUP_REQUEST:
            return {
                ...state,
                requesting: true,
            };

        case userConstants.SIGNUP_SUCCESS: 
            return {
                ...state,
                requesting: false,
            };

        case userConstants.SIGNUP_FAILURE:
            newState = {...state, requesting: false};
            newState.errors.email = "Email is already taken";
            return newState;

        case userConstants.SIGNUP_UPDATE_ERRORS:
            return {...state, errors: action.errors};
        default: 
            return {...state};
    }
};