import * as React from "react";
import GymListMenu from "../../components/GymList/GymListMenu";
import GymList from "../../components/GymList/GymList";
import { Segment } from "semantic-ui-react";

class GymListing extends React.Component {
    public render() {
        return (
            <Segment>
                <GymListMenu />
                <GymList />
            </Segment>
        );
    }
}

export default GymListing;