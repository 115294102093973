import React, { useState } from 'react';
import { Card, Button, Item } from 'semantic-ui-react';
import { IRestBloc } from '../classes';
import './Bloc.css';
import {formatTime} from "../../../utils/Formatter";
import { withTranslation, WithTranslation } from 'react-i18next';

interface IRestBlocState{
    restTime: number
}
interface IRestBlocProps extends WithTranslation{
    bloc: IRestBloc
}
const RestBloc =(props: IRestBlocProps) =>{
    return (
        <div className='bloc'>
            <div className='bloc-content'>
            <Item.Group>
                    <Item>
                        <div className='centered-content'>
                            <Item.Content>
                                <Item.Header>
                                    {props.t(props.bloc.guideline)}
                                </Item.Header>
                                <Item.Meta>
                                    {formatTime(props.bloc.time)}
                                </Item.Meta>
                            </Item.Content>
                        </div>    
                    </Item>
                </Item.Group>
            </div>
        </div>
    )
}
export default withTranslation('workout') (RestBloc);