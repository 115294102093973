import * as React from "react";
import { RouteComponentProps } from "react-router";
import { Form, Button, Container, Responsive, Dropdown } from "semantic-ui-react";
import { ResponsiveUtil } from "../../utils/ResponsiveUtil";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { State } from "../../reducers";
import { CommonUser } from "common";
import "./ExerciseSelector.css";
// import NotFound from "../../pages/NotFound/NotFound";
import { Link } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
//import Resistance from "../../assets/images/partial_hang.png";
//import Force from "../../assets/images/lift_hang.png";
//import Endurance from "../../assets/images/portable_hang.png";
// import portable_hang from "../../assets/images/portable_hang.png";
// import lift_hang from "../../assets/images/lift_hang.png";

interface RouteProps {
  id: string;
}

interface ExerciseSelectorProps extends RouteComponentProps<RouteProps>, WithTranslation {
  user: CommonUser;
  firstName: string;
  userNotFound: boolean;
  dispatch: Dispatch<void>;
  strength: "Click to start";
}

const mapStateToProps = (state: State) => ({
  user: state.authentication.user,
  firstName: state.userProfile.firstName,
  userNotFound: state.userProfile.failed
});

class ExerciseSelector extends React.Component<
  ExerciseSelectorProps,
  {
    strength: string;
    selectedWorkout: string;
    selectedProtocol: string;
    selectedHands: string;
    selectedFingers: string;
    selectedGrip: string;
    selectedSetup: string;
    title: string;
    notes: string;
    showResults: boolean;
    graphDisplay: boolean;
    workout: any;
  }
> {
  private workoutOptions = [
    {
      key: "test",
      text: "test",
      value: "test"
      // image: { avatar: true, src: '/images/avatar/small/jenny.jpg' },
    },
    {
      key: "training",
      text: "training",
      value: "training"
      // image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
    },
    {
      key: "raw",
      text: "raw",
      value: "raw"
      // image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
    }
  ];

  private protocolOptions = [
    {
      key: "free mode",
      text: "free mode",
      value: "free mode"
      // image: { avatar: true, src: '/images/avatar/small/jenny.jpg' },
    },
    {
      key: "endurance protocol",
      text: "endurance protocol",
      value: "endurance protocol"
      // image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
    }
  ];
  private hangboardOptions = [
    {
      key: "unknown",
      text: "unknown",
      value: "Unknown hangboard"
      // image: { avatar: true, src: '/images/avatar/small/jenny.jpg' },
    },
    {
      key: "Beastmaker",
      text: "Beastmaker",
      value: "Beastmaker"
      // image: { avatar: true, src: '/images/avatar/small/jenny.jpg' },
    },
    {
      key: "Transgression",
      text: "Transgression",
      value: "Transgression"
      // image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
    }
  ];
  private fingerOptions = [
    {
      key: "all",
      text: "all",
      value: "all"
      // image: { avatar: true, src: '/images/avatar/small/jenny.jpg' },
    },
    {
      key: "index",
      text: "index",
      value: "index"
      // image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
    },
    {
      key: "middle",
      text: "middle",
      value: "middle"
      // image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
    },
    {
      key: "ring",
      text: "ring",
      value: "ring"
      // image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
    },
    {
      key: "pinky",
      text: "pinky",
      value: "pinky"
      // image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
    }
  ];
  private handOptions = [
    {
      key: "both",
      text: "both",
      value: "both"
      // image: { avatar: true, src: '/images/avatar/small/jenny.jpg' },
    },
    {
      key: "left",
      text: "left",
      value: "left"
      // image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
    },
    {
      key: "right",
      text: "right",
      value: "right"
      // image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
    }
  ];

  private setupOptions = [
    {
      key: "hangboard",
      text: "hangboard",
      value: "hangboard"
      // image: { avatar: true, src: '/images/avatar/small/jenny.jpg' },
    },
    {
      key: "lift",
      text: "lift",
      value: "lift"
      // image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
    },
    {
      key: "portable",
      text: "portable",
      value: "portable"
      // image: { avatar: true, src: '/images/avatar/small/elliot.jpg' },
    }
  ];

  handleProtocolChange = ( data: any) => {
    this.setState({ selectedProtocol: data.value });
  };
  handleHangboardChange = ( data: any) => {
    this.setState({ selectedGrip: data.value });
  };
  handleFingersChange = ( data: any) => {
    this.setState({ selectedFingers: data.value });
  };
  handleHandsChange = ( data: any) => {
    this.setState({ selectedHands: data.value });
  };
  handleSetupChange = (e:any, data: any) => {
    this.setState({ selectedSetup: data.value });
  };
  onChange = () => {
    if (this.state.graphDisplay) {
      this.onUnChecked();
    } else {
      this.onChecked();
    }
  };
  onChecked = () => {
    this.setState({ graphDisplay: true });
  };
  onUnChecked = () => {
    this.setState({ graphDisplay: false });
  };
  textChanged = (e: any) => {
    this.setState({ notes: e.target.value });
  };

  constructor(props: ExerciseSelectorProps) {
    super(props);
    this.state = {
      strength: "Click to start",
      selectedWorkout: 'raw',
      selectedProtocol: this.protocolOptions[0].value,
      selectedGrip: this.hangboardOptions[0].value,
      selectedFingers: this.fingerOptions[0].value,
      selectedHands: this.handOptions[0].value,
      selectedSetup: 'hangboard',
      title: this.setupOptions[0].value,
      showResults: false,
      notes: "",
      graphDisplay: true,
      workout: [{
            "time": 3,
            "strength": 0,
            "instruction": "3 seconds preparation",
            "active": false
        },
        {
            "time": 10,
            "strength": "20 %",
            "instruction": "Pull",
            "active": true
        },
        {
            "time": 5,
            "strength": "20 %",
            "instruction": "Rest",
            "active": false
        },
        {
          "time": 10,
          "strength": "20 %",
          "instruction": "Pull",
          "active": true
      },
      {
          "time": 5,
          "strength": "20 %",
          "instruction": "Rest",
          "active": false
      },
      {
        "time": 10,
        "strength": "20 %",
        "instruction": "Pull",
        "active": true
    },
    {
        "time": 5,
        "strength": "20 %",
        "instruction": "Rest",
        "active": false
    },
    {
      "time": 10,
      "strength": "20 %",
      "instruction": "Pull",
      "active": true
  },
  {
      "time": 5,
      "strength": "20 %",
      "instruction": "Rest",
      "active": false
  },
  {
    "time": 10,
    "strength": "20 %",
    "instruction": "Pull",
    "active": true
},
{
    "time": 5,
    "strength": "20 %",
    "instruction": "Rest",
    "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Rest",
  "active": false
},
{
  "time": 10,
  "strength": "20 %",
  "instruction": "Pull",
  "active": true
},
{
  "time": 5,
  "strength": "20 %",
  "instruction": "Finish",
  "active": false
}
]
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="center">
          <p className="exercise-top">
            Exercise Selector
          </p>
          <Container className="exercise-selector-center">
            <Form style={{ width: "100%" }}>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>{this.props.t('0.setup')}</label>
                  <Dropdown
                    fluid
                    selection
                    options={this.setupOptions}
                    defaultValue={this.setupOptions[0].value}
                    onChange={this.handleSetupChange}
                  />
                </Form.Field>
              </Form.Group>
              <Form.TextArea
                label={this.props.t('0.notes')}
                onChange={this.textChanged}
                style={{ flex: 0.8, borderWidth: 1, minHeight: 40 }}
              />
            </Form>
          </Container>
          <Responsive minWidth={ResponsiveUtil.MOBILE_MAX_WIDTH}>
            <Button
              className="exercise-bottom ui button"
              primary={true}
              style={{ width: 150, height: 40 }}
              as={Link}
              to={{ pathname: "/exercise", state: this.state }}
            >
              {this.props.t('common:btn.start')}
            </Button>
          </Responsive>
          <Responsive maxWidth={ResponsiveUtil.MOBILE_MAX_WIDTH}>
            <Button
                className="exercise-bottom ui button"
                primary={true}
                style={{ width: "65vw", height: 40 }}
                as={Link}
                to={{ pathname: "/exercise", state: this.state }}
              >
                {this.props.t('common:btn.start')}
              </Button>
          </Responsive>

        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation('workout')(connect(mapStateToProps)(ExerciseSelector));
