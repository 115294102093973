import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Dispatch } from 'redux';
import { CommonWorkout } from 'common';
import { Button, Icon, Responsive, Grid } from 'semantic-ui-react';
import { hideSideBar, showSideBar } from '../../actions/navBar.actions';
import store from '../../config/store';
import './WorkoutDetails.css';
import { Workout } from '../../classes/Workout';
import { WithTranslation, withTranslation } from 'react-i18next';
import ExerciseContainer from '../exerciseCreator/view/ExerciseContainer';
import { ResponsiveUtil } from "../../utils/ResponsiveUtil";
import { api } from '../../config/axiosConfig';
import { formatTime } from "../../utils/Formatter";

interface RouteProps {
  workout: any;
}

interface WorkoutDetailsProps extends RouteComponentProps<RouteProps>, WithTranslation {
  workout: Workout;
  dispatch: Dispatch<void>;
}

interface WorkoutDetailsState {
  workoutId?: number;
  workout?: Workout;
}


class WorkoutDetails extends React.Component<WorkoutDetailsProps, WorkoutDetailsState> {
  componentWillMount() {
    store.dispatch(hideSideBar());
    const selectedWorkout = this.props.location.state.workout;
    const photo = selectedWorkout.img ? require(`../../assets/images/workout/${selectedWorkout.img}`) : require(`../../assets/images/workout/Workout.png`);
    this.setState({ workout: selectedWorkout, photo });
  }

  private navigate = () => {
    store.dispatch(showSideBar());
    this.props.history.push('/exercise-launcher');
  }

  delete = () => {
    const requestURL = '/v1/workout/delete'
    api()
      .patch(requestURL, { id: this.state.workout.id })
      .then(() => {
        this.props.history.push('/');
      })
      .catch((error) => { console.log('fuckk') });
  }
  edit = () => {
    const params = {
      blocGroupContainer: this.state.workout.blocGroupContainer,
      title: this.state.workout.title,
      desc: this.state.workout.desc,
      subtype: this.state.workout.subtype,
      id: this.state.workout.id,
    }
    this.props.history.push({ pathname: `/exercise-creator/`, state: { ...params } });
  }
  deleteButton = () => {
    if (this.state.workout.type == "mine") {
      return (
        <Icon
          className="clickable"
          name="trash alternate"
          onClick={this.delete}
        />
      )
    }
  }
  editButton = () => {
    if (this.state.workout.type == "mine") {
      return (
        <Icon
          className="clickable"
          name="edit"
          onClick={this.edit}
        />
      )
    }
  }
  public render() {
    let img = null;

    const exerciseHeader = (
      <div className="exerciseHeader">
        <div>
          <h1>{this.props.t(this.state.workout.title)}</h1>
          <body>{this.props.t(this.state.workout.subtitle)}</body>
        </div>
        <div className="workoutDuration">{formatTime(this.state.workout.time)}</div>
      </div>
    )


    return (
      <>
        <div className="backgroundPicture">
          <img src={this.state.photo} alt="Workout" />
        </div>

        <Responsive minWidth={ResponsiveUtil.MOBILE_MAX_WIDTH}>
          <div className="circle exerciseCircleDesktopOutline">
            <span className="centerSpan">
              <Icon
                className="clickable exerciseIcon"
                name="arrow left"
                onClick={this.navigate}
              />
            </span>
          </div>
          <div className="circle exerciseEditDesktopOutline">
            <span className="centerSpan">
              {this.editButton()}
            </span>

          </div>
          <div className="circle exerciseDeleteDesktopOutline">
            <span className="centerSpan">

              {this.deleteButton()}
            </span>
          </div>



          <Grid columns='equal' className="gridLayout desktopGrid">
            <Grid.Column className="column1" stretched>
              {exerciseHeader}
              <ExerciseContainer blocGroupContainer={this.state.workout.blocGroupContainer} />
            </Grid.Column>
            <Grid.Column className="column2">
              <div className="workoutDescriptionDesktop">{this.props.t(this.state.workout.desc)}</div>
              <Button
                className="ui primary exerciseButton button"
                as={Link}
                to={{ pathname: '/exercisemanager', state: { workout: this.state.workout } }}
              >
                <span className="buttontext">{this.props.t('common:btn.start')}</span>
              </Button>
            </Grid.Column>
          </Grid>

          
        </Responsive>

        <Responsive maxWidth={ResponsiveUtil.MOBILE_MAX_WIDTH}>
          <div className="circle exerciseCircleMobileOutline">
            <span className="centerSpan">
              <Icon
                className="clickable exerciseIcon"
                name="arrow left"
                onClick={this.navigate}
              />
            </span>
          </div>
          
            <div className="circle exerciseEditMobileOutline">
              <span className="centerSpan">
                {this.editButton()}
              </span>
            </div>

            <div className="circle exerciseDeleteMobileOutline">
              <span className="centerSpan">
                {this.deleteButton()}
              </span>
            </div>

          <Grid className="exerciseGrid">
            <Grid.Column>
              {exerciseHeader}
              <div className="workoutDescriptionMobile">{this.props.t(this.state.workout.desc)}</div>
              <ExerciseContainer blocGroupContainer={this.state.workout.blocGroupContainer} />
            </Grid.Column>
          </Grid>

          <Button
            className="ui primary exercise-bottom exerciseLaunchButton button"
            as={Link}
            to={{ pathname: '/exercisemanager', state: { workout: this.state.workout } }}
          >
            <span className="buttontext">{this.props.t('common:btn.start')}</span>
          </Button>
        </Responsive>
      </>
    );
  }
}

export default withTranslation('workout')(WorkoutDetails);
