import { userConstants, settingsConstants } from "./../constants";
import { SettingsProfileFormErrors } from "../actions/settings.actions";

export interface SettingsProfileState {
    requesting: boolean;
    success: boolean;
    errors: SettingsProfileFormErrors;
}

const defaultState = {
    requesting: false,
    success: false,
    errors: {}
};

export const settingsProfile = (state: SettingsProfileState = defaultState, action: any) => {
    switch (action.type) {
        case userConstants.MODIFY_REQUEST:
            return { ...state, requesting: true };
        case userConstants.MODIFY_SUCCESS:
            return { ...state, requesting: false, success: true };
        case userConstants.MODIFY_FAILURE:
            return { ...state, requesting: false, success: false, errors: action.data.errors };
        case settingsConstants.UPDATE_ERRORS_PROFILE:
            return { ...state, errors: action.data };
        case settingsConstants.CANCEL_EDIT_PROFILE:
            return defaultState;
        default:
            return { ...state };
    }
};