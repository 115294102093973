import * as React from "react";
import SettingRow from "../../components/SettingRow/SettingRow";
import { Container } from "semantic-ui-react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { State } from "../../reducers";
import { CommonUser } from "common";
import { WithTranslation, withTranslation } from "react-i18next";

interface ViewSettingsAccountProps extends WithTranslation {
    user: CommonUser;
    dispatch: Dispatch<void>;
}

const mapStateToProps = (state: State) => (
    { user: state.authentication.user }
);

class ViewSettingsAccount extends React.Component<ViewSettingsAccountProps> {
    render() {
        return (
            <Container>
                <SettingRow title={this.props.t('account.subEmail')} content={this.props.user.email} />
                <SettingRow title={this.props.t('account.subPassword')} content="************" />
            </Container>
        );
    }
}

export default withTranslation('settings')(connect(mapStateToProps)(ViewSettingsAccount));
