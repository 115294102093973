import React from 'react';
import { Button, Card, Item } from 'semantic-ui-react';
import { ITrainingBloc } from '../classes';
import './Bloc.css';
import * as pullImg from "../../../assets/images/grips/pullTwoHand.jpg";
import { formatTime } from '../../../utils/Formatter';
import Hands from "../../../components/Hands/Hands";
import { withTranslation, WithTranslation } from 'react-i18next';

interface ITrainingBlocProps extends WithTranslation{
    bloc: ITrainingBloc
}

const TrainingBloc = (props: ITrainingBlocProps) => {
    const handsConfig = ['Left', 'Right'];
    const fingers = [ 'Thumb', 'Index', 'Middle', 'Ring', 'Pinky'];
    
    const displayFingers = (hand: string) => {
        const handIndex = hand == 'Left' ? 0 : 1;
        const fingersValues = handIndex == 0 ? [...fingers].reverse() : [...fingers];
        return fingersValues.map((finger) => {
            let fingerIndex = fingersValues.findIndex(x => x == finger);
            const isActive = props.bloc.fingers[handIndex][fingerIndex] == 1 ? true : false;
             //if (finger != 'Thumb')
                return <Button toggle active={isActive} onClick={() => props.bloc.setFingers(handIndex, fingerIndex)}> {finger} </Button>
            //return null;
        }
        );
    }
       
    const displayHands =
        handsConfig.map((hand) =>
            <div>
                    <h4>{hand}</h4>
                    <div>
                        {displayFingers(hand)}
                    </div>
            </div>
        );
    return (
        <div className='bloc'>
            <div className='bloc-content'>
                <Item.Group>
                    <Item>
                        <Hands height={80} fingers={props.bloc.fingers} />
                        <div className='centered-content'>
                            <Item.Content>
                                <Item.Header>
                                    {props.t(props.bloc.guideline)}
                                </Item.Header>
                                <Item.Meta>
                                    {formatTime(props.bloc.time)}
                                </Item.Meta>
                                <Item.Meta>
                                    {props.bloc.intensity} %
                            </Item.Meta>
                            </Item.Content>
                        </div>
                        
                    </Item>
                </Item.Group>


                {/* <div className='hang-fingers'>
                        <h4> Fingers</h4>
                        {displayHands}
                    </div> */}
            </div>
        </div>

    )
}


export default withTranslation('workout') (TrainingBloc);