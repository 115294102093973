import { adminManagement, AdminManagementState } from "./admin/management/management.reducer";
import { combineReducers } from "redux";
import { AdminOrdersState, orders } from "./admin/orders.reducer";

export interface AdminState {
    management: AdminManagementState;
    orders: AdminOrdersState;
}

export const admin = combineReducers({
    management: adminManagement,
    orders: orders
});