import * as React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Table, Header, Form, Button, Message } from "semantic-ui-react";
import { State } from "../../reducers";
import { CommonUser } from "common";
import { getAdminList, removeAdmin, addAdmin, addAdminError, resetAddAdmin } from "../../actions/admin.actions";
import { expect, validateForm } from "../../utils/Validator";
import { isEmpty } from "lodash";
import RemoveAdminRightsModal from "./RemoveAdminModal";
import LoadingSegment from "../../components/LoadingSegment/LoadingSegment";
import { RemoveAdminState } from "../../reducers/admin/management/removeAdmin.reducer";
import "./AdminRights.css";

interface AdminRightsProps {
    admins: Array<CommonUser>;
    receivedAdminList: boolean;
    userId: number;
    total: number;
    dispatch: Dispatch<void>;
    error: string;
    addSuccess: boolean;
    removeAdmin: RemoveAdminState;
}

interface AdminRightsState {
    email: string;
    removeModalOpen: boolean;
    adminToRemove?: CommonUser;
}

const mapStatetoProps = (state: State) => ({
    admins: state.admin.management.adminList.admins,
    receivedAdminList: state.admin.management.adminList.received,
    total: state.admin.management.adminList.total,
    error: state.admin.management.addAdmin.error,
    addSuccess: state.admin.management.addAdmin.added,
    userId: state.authentication.user.id,
    removeAdmin: state.admin.management.removeAdmin
});

class AdminRights extends React.Component<AdminRightsProps, AdminRightsState> {

    private validations = [
        expect("email").isEmail()
    ];

    public componentWillMount() {
        const state = {
            email: "",
            removeModalOpen: false,
        };

        this.setState(state);
        this.fetchAdminList();
    }

    render() {
        const admins = this.props.admins;
        const errorMessage = this.props.error;
        const error = Boolean(errorMessage);
        let email = this.state.email;

        if (this.props.addSuccess) {
            this.clearField();
            this.props.dispatch(resetAddAdmin());
        }

        return (
            <React.Fragment>
                <Header as="h1" dividing={true}>Administrator Rights</Header>
                <Form error={error} className="add-admin-form">
                    <Form.Group unstackable={true}>
                        <Form.Input
                            placeholder="Enter email..."
                            type="text"
                            value={email}
                            onChange={this.handleOnEmailChange}
                            error={error}
                            width={13}
                        />
                        <Form.Button fluid={true} color="blue" onClick={this.handleAddButton} width={2}>Add</Form.Button>
                    </Form.Group>
                    <Message error={true} className="error-message">
                        <Message.Header>Form Error</Message.Header>
                        <p>{errorMessage}</p>
                    </Message>
                </Form>
                <Header as="h2" dividing={true}>List of admins</Header>
                <LoadingSegment isLoading={!this.props.receivedAdminList}>
                    <Table color="orange" celled={true} unstackable={true}>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Last Name</Table.HeaderCell>
                                <Table.HeaderCell>First Name</Table.HeaderCell>
                                <Table.HeaderCell>Email</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {admins.map((admin, i) => {
                                if (admin.id !== this.props.userId) {
                                    return (
                                        <Table.Row key={i}>
                                            <Table.Cell>{admin.lastName}</Table.Cell>
                                            <Table.Cell>{admin.firstName}</Table.Cell>
                                            <Table.Cell>{admin.email}</Table.Cell>
                                            <Table.Cell width={4}>
                                                <Button compact={true} inverted={true} color="red" onClick={() => this.showModal(admin)}>
                                                    Remove admin rights
                                            </Button>
                                            </Table.Cell>
                                        </Table.Row>);
                                } else {
                                    return null;
                                }
                            })}
                        </Table.Body>
                    </Table>
                </LoadingSegment>
                <RemoveAdminRightsModal
                    open={this.state.removeModalOpen}
                    onCancel={this.closeModal}
                    onConfirm={this.removeAdmin}
                    loading={this.props.removeAdmin.requesting}
                    error={this.props.removeAdmin.error}
                />
            </React.Fragment>
        );
    }

    private fetchAdminList() {
        this.props.dispatch(getAdminList());
    }

    private handleOnEmailChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({ ...this.state, email: (e.target as HTMLInputElement).value });
    }

    private handleAddButton = () => {
        let errMsg = validateForm(this.state, this.validations);
        if (isEmpty(errMsg)) {
            this.addAdmin(this.state.email);
        } else {
            this.props.dispatch(addAdminError(errMsg.email));
        }
    }

    private showModal = (admin: CommonUser) => {
        this.setState({ ...this.state, removeModalOpen: true, adminToRemove: admin });
    }

    private closeModal = () => {
        this.setState({ ...this.state, removeModalOpen: false });
    }

    private removeAdmin = () => {
        if (this.state.adminToRemove) {
            let { email } = this.state.adminToRemove;
            let data = { email };
            this.props.dispatch(removeAdmin(data)).then(() => {
                this.closeModal();
            });
        }
    }

    private addAdmin(email: string) {
        let data = { email };
        this.props.dispatch(addAdmin(data));
    }

    private clearField = () => {
        this.setState({ ...this.state, email: "" });
    }
}

export default connect(mapStatetoProps)(AdminRights);