import * as React from "react";
import { Segment, Header, Statistic, Label, SemanticCOLORS, Button } from "semantic-ui-react";
import { CommonAcquisition, AcquisitionType, AcquisitionSubType } from "common";
import "./ActivityGeneral.css";
import { capitalize } from "lodash";
import ActivitySubTypeLabel from "../ActivityTypeLabel/ActivityTypeLabel";
import { ShareButton } from "../../shareButton/ShareButton";

interface ActivityGeneralProps {
    activity: CommonAcquisition;
    privacySettings: string;
    weightUnit: string;
}

class ActivityGeneral extends React.Component<ActivityGeneralProps> {
    public textStyle = {fontFamily: "Open Sans", fontWeight: 400, color: "rgba(243,243,243,1)"};
    public textStyleLabel = {fontFamily: "Open Sans", fontWeight: 400, color: "rgba(243,243,243,.65)"};

    public static getTypeColor(type: AcquisitionType): SemanticCOLORS {
        switch (type) {
            case "test":
                return "green";
            case "training":
                return "yellow";
            case "raw":
            default:
                return "red";
        }
    }

    private averageStrength (result) {
        let averageStrength = 0
        result.intervals.forEach(interval => {
            averageStrength += 60*interval.strain/((interval.end - interval.start));
        });
        return averageStrength/result.intervalCount;
    }

    constructor(props: ActivityGeneralProps) {
        super(props);
        this.StatisticSection = this.StatisticSection.bind(this);
    }
    
    public StatisticSection(result: any) {
        let hidden = false;
        if (!hidden) {
            return  (
                <Segment attached="bottom" widths="five"  className="color-change">
                    <Statistic.Group className="activity-statistics" size="tiny">
                    <Statistic size="tiny">
                        <Statistic.Value style={this.textStyle} className="no-uppercase">{result.intervalCount ? result.intervalCount : "-"}
                        </Statistic.Value>
                        <Statistic.Label style={this.textStyleLabel}  className="colorchange">Hangs</Statistic.Label>
                    </Statistic>
                    <Statistic size="tiny">
                        <Statistic.Value style={this.textStyle} className="no-uppercase">
                            {result.avgIntervalDuration ? result.avgIntervalDuration.toFixed(1) + " s" : "-"}
                        </Statistic.Value>
                        <Statistic.Label style={this.textStyleLabel}>Average duration</Statistic.Label>
                    </Statistic>
                    <Statistic size="tiny">
                        <Statistic.Value style={this.textStyle} className="no-uppercase">
                            {(result.intervalCount>0 ? this.averageStrength(result).toFixed(1) +  this.props.weightUnit : "-")}
                        </Statistic.Value>
                        <Statistic.Label style={this.textStyleLabel}>Average strength</Statistic.Label>
                    {/*</Statistic>
                     <Statistic size="tiny">
                        <Statistic.Value style={this.textStyle} className="no-uppercase">{result.durationVariation} s</Statistic.Value>
                        <Statistic.Label style={this.textStyleLabel}>Duration variation</Statistic.Label>
                    </Statistic>
                    <Statistic size="tiny">
                        <Statistic.Value style={this.textStyle} className="no-uppercase">{result.strengthVariation} kg</Statistic.Value>
                        <Statistic.Label style={this.textStyleLabel}>Strength variation</Statistic.Label> */}
                    </Statistic>
                </Statistic.Group>
            </Segment>
        );
        } else {
            return ;
        }
    }
    public render() {
        let { tag, type, subtype, title, result, date } = this.props.activity;
        let color = ActivityGeneral.getTypeColor(type as AcquisitionType);
        let meta = {
            title: `${title} | ${this.formatDate(date)}`,
            description: `${result.intervalCount} hangs | ${result.avgIntervalDuration.toFixed(1)} seconds | ${this.averageStrength(result).toFixed(1)} ${this.props.weightUnit}`,
            image: "https://entralpi.s3.us-east-2.amazonaws.com/Sharing.jpg" 
        }

        return (
            <React.Fragment>
                <Segment attached="top" color={color}>
                    <Label color={color} ribbon={true}>{capitalize(type)}</Label>
                    <Header as="h1" className="activity-name-header">
                        {capitalize(title)}
                        <ActivitySubTypeLabel subtype={subtype as AcquisitionSubType} />
                        <Header.Subheader color={color}>
                            <div className="activity-date">{this.formatDate(date)}</div>
                            <div className="activity-tag">{tag}</div>
                        </Header.Subheader>
                    </Header>
                    <ShareButton activityId={this.props.activity.id} privacySettings={this.props.privacySettings} meta={meta}/>
                </Segment>
                {this.StatisticSection(result)}
            </React.Fragment>
        );
    }

    private formatDate(date: Date): string {
        var dateOptions = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
        return new Date(date).toLocaleDateString("en-US", dateOptions);
    }
}

export default ActivityGeneral;