import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Route, Redirect } from 'react-router';
import { State } from '../reducers/index';
import { setRedirectURL, clearRedirectURL } from '../actions/user.actions';

interface PrivateRouteProps {
    dispatch: Dispatch<void>;
    login: boolean;
    redirectUrl: any;
    routeProps: any;
}

const mapStatetoProps = (state: State, ownProps: any) => ({
  login: state.authentication.login,
  redirectUrl: state.authentication.redirectURL,
  routeProps: ownProps,
});

class PrivateRoute extends React.Component<PrivateRouteProps, {}> {
  render() {
    console.log('holaaaaaaaaaaa');
    if (!this.props.login && this.props.routeProps.location.pathname) {
      this.props.dispatch(setRedirectURL(this.props.routeProps.location.pathname));
    } else if (this.props.login && this.props.redirectUrl) {
      const redirectUrl = this.props.redirectUrl;
      this.props.dispatch(clearRedirectURL());
      return <Redirect to={`${redirectUrl}`} />;
    }

    const { component: Component, ...rest } = this.props.routeProps;
    return (
      <Route
        {...rest}
        render={(props: any) => (this.props.login ? <Component {...props} /> : <Redirect to="/login" />)}
      />
    );
  }
}

export default connect(mapStatetoProps)(PrivateRoute);
