import React from 'react';
import { Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import Timer from '../../components/Timer/Timer';
import Guidelines from '../../components/Guidelines/Guidelines';
import Bluetooth from '../../components/Bluetooth/Bluetooth';
import LiveGraph from '../../components/Exercise/LiveGraph/LiveGraphExercises';
import { hideSideBar, showSideBar } from '../../actions/navBar.actions';
import * as sensorDataAnalysis from '../../utils/sensorDataAnalysis';
import * as bluetooth from '../../utils/bluetooth';
import { uploadActivity, handleActivityStatus, handleCurrentRepUpdate, handleTimerStatus, handleCalibrationStatus } from '../../actions/activities.actions';
import './ExerciseManager.css';
import { Dimmer, Header, Modal, Loader } from 'semantic-ui-react';
import Navigation from '../../components/Exercise/Navigation/Navigation';
import { connect } from "react-redux";
import { State } from '../../reducers';
import { handleBluetoothState, bluetoothConnection } from '../../actions/bluetooth.actions';
import { api } from '../../config/axiosConfig';
import Activity from '../../classes/Activity';
import {Workout, Rep} from '../../classes/Workout';
import soundHang from '../../assets/sounds/hang_sound.ogg';
import soundRest from '../../assets/sounds/rest_sound.ogg';
import Calibration from '../../components/calibration/Calibration';
import { WithTranslation, withTranslation } from 'react-i18next';

interface ExerciseManagerProps extends RouteComponentProps, WithTranslation{
    workout: Workout
    dispatch: Dispatch<void>
    uploadedId: number
    activityStatus: string
    timerStatus: string
    calibrationStatus:string
    bluetoothConnected: boolean
    currentRep: Rep
    weightUnit: string
    bluetoothStatus: string
}
interface ExerciseManagerState{
  activity: Activity
  guidelineRep: Rep
  calibrationData:Array<number>
  done: boolean
}
const mapStateToProps = (state: State) => ({
  uploadedId: state.activities.uploadedId,
  activityStatus: state.activities.activityStatus,
  timerStatus: state.activities.timerStatus,
  calibrationStatus: state.activities.calibrationStatus,
  currentRep: state.activities.currentRep,
  bluetoothConnected: state.bluetooth.bluetoothConnected,
  weightUnit: state.authentication.user.displaySettings.weightUnit,
  bluetoothStatus: state.bluetooth.bluetoothStatus
});
let weightUnitFactor;
let userStrength;
//#region Inital state
//#endregion
class ExerciseManager extends React.Component<ExerciseManagerProps, ExerciseManagerState> {
  //#region Constructor
  private managerActivity:Activity;
  private activityType:any;
  private audioHang:any; 
  private audioRest:any;
  constructor(props: ExerciseManagerProps) {
    super(props);
    this.activityType = {
      title: this.props.t(this.props.location.state.workout.title),
      type: this.props.location.state.workout.subtype,
      selectedWorkout: this.props.location.state.workout.type,
      notes: '',
    }
    this.audioHang = new Audio(soundHang);
    this.audioRest = new Audio(soundRest);
    
    this.managerActivity= new Activity(this.activityType, this.props.location.state.workout);
    //
    this.props.dispatch(handleCurrentRepUpdate(this.managerActivity.currentRep));
    this.state = {
      activity: this.managerActivity,
      guidelineRep: new Rep(),
      calibrationData: new Array(),
      done: false
    };
    this.getDetailJsonWorkout();
    this.fetchUserStats();
    //#region Bind methods to context
    this.nextStep = this.nextStep.bind(this);
    this.nextGuidelineRep = this.nextGuidelineRep.bind(this);
    this.startActivity = this.startActivity.bind(this);
    this.pauseActivity = this.pauseActivity.bind(this);
    this.restartActivity = this.restartActivity.bind(this);
    this.finishActivity = this.finishActivity.bind(this);
    this.updateTime = this.updateTime.bind(this);
    this.updateStrength = this.updateStrength.bind(this);
    this.calibrate = this.calibrate.bind(this);
    this.fetchUserStats = this.fetchUserStats.bind(this);
    this.resetExerciseState = this.resetExerciseState.bind(this);
    this.getDetailJsonWorkout = this.getDetailJsonWorkout.bind(this);
    //#endregion
  }
  //#endregion
  getDetailJsonWorkout(){
    const requestURL = `/v1/workout/getJsonDetailedWorkout`;
    api()
      .get(requestURL, {params:{id:this.props.location.state.workout.id, type:this.props.location.state.workout.type}})
      .then((response)=>{
        this.setState(({activity, guidelineRep})=>{
        activity.workout.content = {reps:response.data.reps};
        activity.currentRep = activity.workout.content.reps[0];
        guidelineRep = activity.currentRep;
        activity.calculateExpectedStrength();
        //const firstRep= activity.workout.getRepByIndex(activity.currentRepIndex);
        //this.props.dispatch(handleCurrentRepUpdate(firstRep));
        return {activity, guidelineRep};
      });
      
    });
  }
  //#region Life cycle methods
  async componentDidMount() {
    this.props.dispatch(hideSideBar());
    this.props.dispatch(handleBluetoothState('off'));
    this.props.dispatch(bluetoothConnection(bluetooth._connected));
    if (bluetooth._connected)
      this.props.dispatch(handleActivityStatus('calibrate'));
    else
      this.props.dispatch(handleActivityStatus('stop'));
    sensorDataAnalysis.freeDataArray();
    weightUnitFactor = this.props.weightUnit == 'kg'?1:2.2;
  }

  componentDidUpdate(prevProps){
    if (this.props.activityStatus !== prevProps.activityStatus) {
      if(prevProps.activityStatus !== 'pause'){
        switch (this.props.activityStatus) {
          case 'wait rest':
            this.audioRest.play();
            break;
          case 'wait start':
          case 'wait hang':
            this.audioHang.play();
            break;
        }
      }
      if(this.props.activityStatus == 'reset'){
        const nextState = this.props.bluetoothStatus == 'off' ? 'stop': 'calibrate'
        this.props.dispatch(handleActivityStatus(nextState));
      }
    }
    else if (this.props.timerStatus !== prevProps.timerStatus) {
      if (this.props.timerStatus == 'done' && prevProps.timerStatus!== 'pause') {
        if (this.state.activity.workout.content.reps.length-1 == this.state.activity.currentRepIndex){
          this.finishActivity();
          return;
        }
        else {
          if (this.props.currentRep.intensity == 0) {
            sensorDataAnalysis.addDataToString(0);
          }
          const nextActivityStatus = this.props.currentRep.intensity == 0 ? 'wait hang' : 'wait rest';
          this.props.dispatch(handleActivityStatus(nextActivityStatus));
          this.nextGuidelineRep();
        }
      }
    }
    else if (this.props.calibrationStatus !== prevProps.calibrationStatus) {
        this.setState(({ calibrationData }) => {
          calibrationData.length = 0;
          return { calibrationData };
        });
    }
  }
  //#endregion 
  fetchUserStats(){
    const requestURL = `/v1/athlete/getStrength`;
    api()
      .get(requestURL)
      .then((response)=>{this.setState(({activity})=>{
        activity.setUserStrength(response.data);
        return {activity};
      },()=>{this.props.dispatch(handleCurrentRepUpdate(this.state.activity.currentRep))})})
      .catch(() =>  {this.setState(({activity})=>{
        activity.setUserStrength(1);
        return {activity};
      },()=>{this.props.dispatch(handleCurrentRepUpdate(this.state.activity.currentRep));})});
  }

  //#region Updates rep
  nextStep() {
    this.setState(({activity})=>{
      activity.updateIntervals();
      activity.updateCurrentRep();
      return {activity};
    }, ()=>{
      sensorDataAnalysis.addDataToString(0);
      this.props.dispatch(handleActivityStatus(this.state.activity.currentRep.intensity == 0 ? 'rest' : 'hang'));
      this.props.dispatch(handleTimerStatus('reset'));
      this.props.dispatch(handleCurrentRepUpdate(this.state.activity.currentRep));
    });
  }
  nextGuidelineRep(){
    const nextIndex = this.state.activity.currentRepIndex + 1;
    const nextRep = this.state.activity.workout.getRepByIndex(nextIndex);
    this.setState({guidelineRep:nextRep })
  }
  //#endregion
  
  //#region Activity states methods
  startActivity() {
    let nextActivityState;
    this.setState(({activity}, props)=>{
      nextActivityState= activity.start();
      return {activity};
    }, ()=>{
      this.props.dispatch(handleActivityStatus(nextActivityState));  
      this.props.dispatch(handleTimerStatus('working'));});
  }

  pauseActivity() {
    this.props.dispatch(handleActivityStatus('pause'));
    this.props.dispatch(handleTimerStatus('pause'));
    this.setState(({activity}, props)=>{
      activity.pause(props.activityStatus);
      return {activity};
    });    
  }
  restartActivity() {
    this.resetExerciseState();
    this.props.dispatch(handleActivityStatus('stop'));
    sensorDataAnalysis.freeDataArray();
  }

  async finishActivity() {
    this.setState({done:true});
    this.props.dispatch(handleTimerStatus('stop')); 
    if (sensorDataAnalysis._weightDataArray.length >0) {
      const archive = await this.state.activity.finish();
      // @ts-ignore
      this.props.dispatch(uploadActivity(archive)).then(()=>{
        this.resetExerciseState();
        this.props.history.push(`/activities/${this.props.uploadedId}`);
      });
    }
    else
      this.props.history.push(`/`);
    this.resetExerciseState();
    this.props.dispatch(showSideBar());
  }
  resetExerciseState(){
    this.setState(({activity})=>{
      activity.reset();
      return {
        activity,
        guidelineRep: activity.workout.getRepByIndex(0)
      }
    });
    this.props.dispatch(handleCurrentRepUpdate(this.state.activity.currentRep));
    this.props.dispatch(handleActivityStatus('reset'));
    this.props.dispatch(handleTimerStatus('stop')); 
    this.props.dispatch(handleBluetoothState('off'));
    this.props.dispatch(handleCalibrationStatus('stop')); 
  }
//#endregion
  
  //#region Updates child components data  
  updateTime(time :number) {
    if (!this.state.activity.paused) {
      let strength = this.state.activity.pulledWeight;
      if (strength == 'Out' && this.props.currentRep.intensity != 0) {
        strength = this.state.activity.userWeight;
    }
    this.setState(({activity},props)=>{
      activity.updateLiveGraphData(time, strength);
      if(props.activityStatus !='rest') sensorDataAnalysis.addDataToString(strength);
      return {activity};
    });
    }
  }
  updateStrength(sensorData: any) {
    this.setState(({activity}) => {
      activity.updatePulledWeigth(sensorData);
      return {activity};
    });
    switch (this.props.activityStatus) {
      case 'rest':
      case 'working':
        break;
      case 'wait hang':
        if (this.state.activity.detectUserAction() =='hang') {
          this.nextStep();
        }
        break;
      case 'wait rest':
        if (this.state.activity.detectUserAction() =='rest')
          this.nextStep();
        break;
      case 'wait start':
        if (this.state.activity.detectUserAction() =='hang') {
          sensorDataAnalysis.onStart();
          this.props.dispatch(handleTimerStatus('start'));
          this.props.dispatch(handleActivityStatus('hang'));
        }
        break;
      case 'calibrate':
        if(this.props.calibrationStatus !== 'pending' && this.props.calibrationStatus !== 'user stepped out'){
          this.setState(({calibrationData})=>{
            calibrationData.push(sensorData);
            return {calibrationData: [].concat(...calibrationData)};
          });
        }  
        break;
    }
  }
  //#endregion
  
  //#region Calibration
  calibrate(userWeight: number, addedWeights:number) {
    this.setState(({activity})=>{
      activity.userWeight = userWeight;
      activity.addedWeights = addedWeights;
      return{activity};
    })
  }
  showPulledStrength() {
    if (this.props.activityStatus && this.props.activityStatus !== 'rest') {
      let html = () =>
        <>
          <span style={{ fontFamily: "open sans", fontWeight: 400, fontSize: "5rem" }}>{Math.floor(this.state.activity.pulledWeight*weightUnitFactor)}</span>
          <span style={{ fontFamily: "open sans", fontWeight: 400, fontSize: "2rem" }}>{Math.floor(10 * (this.state.activity.pulledWeight*weightUnitFactor - Math.floor(this.state.activity.pulledWeight*weightUnitFactor)))}</span>
        </>;
      if (this.state.activity.pulledWeight == 'Out')
        html = () =><span style={{ fontFamily: "open sans", fontWeight: 400, fontSize: "5rem" }}>{this.state.activity.pulledWeight}</span>
      return (
        <span className="strength">
          {html()}
        </span>
      );
    }
  }
  exerciseNotDone(){
    if(this.state.done){
      return (
      <Dimmer active>
        <Loader style={{display:'block'}}>Loading</Loader>
      </Dimmer>);
    }
    else {
      return (
        <>
          <Bluetooth callback={this.updateStrength} />
          <Calibration callback={this.calibrate} sensorDataArray={this.state.calibrationData}/>
        </>)
    }
  }
//#endregion

  render() {
    return (
      <>
      {this.exerciseNotDone()}
        <div className="ui one column grid exerciseManager-container" onClick={this.pauseActivity}>
          <i className="close icon finishExercise" style={{ fontSize: '3.5em', color: '#6d6d6d' }} onClick={this.finishActivity} />
          <div className="timerPosition">
            <Timer
              updateTime={this.updateTime}
            />
          </div>
          <div className="row guidelines centered">
            <Guidelines rep={this.state.guidelineRep} />
          </div>
          
          <div className="livegraph" style={{padding:'0px'}}>
            <LiveGraph
              chartData={this.state.activity.liveGraphData}
              activityStatus = {this.props.activityStatus}
            />
          </div>
          {this.showPulledStrength()}
        </div>

        <Dimmer
          active={this.state.activity.paused}
          verticalAlign='center'
        >
          <Header as='h2' inverted>
            Paused
            </Header>

          <Navigation
            leftIcon={'stop link'}
            leftIconAction={this.finishActivity}
            middleIcon={'play'}
            middleIconAction={this.startActivity}
            rightIcon={'undo link'}
            rightIconAction={this.restartActivity}
            style={{bottom:'auto'}}>
          </Navigation>
        </Dimmer>
  </>
    );
  }
}

export default withTranslation('workout')(connect(mapStateToProps)(ExerciseManager));
