export interface Interval{
    start:number;
    stop:number;
    fingers: [Array<number>, Array<number>];
    grip: string;
    expectedStrength: number;
}
export class Interval implements Interval{
    constructor(props){
        this.start=props.start;
        this.stop=props.stop;
        this.fingers=props.fingers;
        this.grip= props.grip;
        this.expectedStrength = props.expectedStrength;

    }
}