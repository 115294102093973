import * as bluetooth from '../../utils/bluetooth';
import * as React from 'react';
import * as sensorDataAnalysis from "../../utils/sensorDataAnalysis"
import { Modal, Button, Header, Progress, Checkbox, Input, Label } from 'semantic-ui-react';
import "./Calibration.css"
import {connect} from 'react-redux';
import { State } from '../../reducers';
import { bluetoothConnection, handleBluetoothState } from '../../actions/bluetooth.actions';
import { Dispatch } from 'redux';
import { stat } from 'fs';
import { handleActivityStatus, handleCalibrationStatus } from '../../actions/activities.actions';
import {std, mean} from 'mathjs';
import { request } from 'https';
import { render } from 'email-templates';
import { withTranslation, WithTranslation } from 'react-i18next';

interface CalibrationProps extends WithTranslation{    
    dispatch: Dispatch<void>,
    callback: any,
    sensorDataArray:Array<number>,
    calibrationStatus: string,
    activityStatus: string
}
interface CalibrationState{
    calibrationMessage: string;
}
const mapStateToProps = (state: State) => ({
    calibrationStatus: state.activities.calibrationStatus,
    activityStatus: state.activities.activityStatus
});

let userWeight=0;
let addedWeights=0;
let addWeights=false;
class Calibration extends React.Component<CalibrationProps, CalibrationState>{
    constructor(props: CalibrationProps) {
        super(props);
        this.calibration = this.calibration.bind(this);
        this.startCalibration = this.startCalibration.bind(this);
        this.state={
            calibrationMessage: this.props.t('calibration.initHeader'),
        }

        this.addWeights = this.addWeights.bind(this);
        
    }
    componentDidUpdate(prevProps) {
        if(this.props.sensorDataArray !== prevProps.sensorDataArray){
            if (this.props.calibrationStatus == 'request' && this.props.sensorDataArray.length >=100){
                this.props.dispatch(handleCalibrationStatus('pending'));
                this.calibration()
            }
            else if(this.props.calibrationStatus=='error' && this.props.sensorDataArray[this.props.sensorDataArray.length-1] ==0)
                this.props.dispatch(handleCalibrationStatus('user stepped out'));
            else if(this.props.calibrationStatus == 'failed') 
                this.props.dispatch(handleCalibrationStatus('request'));
        }
    }
    calibration() {
        if (std(this.props.sensorDataArray) < 1) {
            if(userWeight ==0){
                userWeight= mean(this.props.sensorDataArray);
            }
            else if(addWeights && userWeight>0){
                addedWeights= mean(this.props.sensorDataArray) - userWeight;
                addWeights=false;
                sensorDataAnalysis.freeDataArray();
            }
            if(!addWeights){
                this.props.dispatch(handleActivityStatus('wait start'));
                this.props.dispatch(handleCalibrationStatus('success'));
                this.props.callback(userWeight, addedWeights);
                userWeight=0;
                addedWeights=0;
            }
            else{
                this.props.dispatch(handleCalibrationStatus('stop'));
            }
            
        } else if (std(this.props.sensorDataArray) > 10) {
            this.props.dispatch(handleCalibrationStatus('error'));
        } else {
            this.props.dispatch(handleCalibrationStatus('failed'));
        }
    }
    startCalibration(){
        this.props.dispatch(handleBluetoothState('on'));
        this.props.dispatch(handleCalibrationStatus('request'));
        sensorDataAnalysis.freeDataArray();
    }
    errorMessage(){
        if(this.props.calibrationStatus == 'error'){
            return (
                <div>
                    <h2 className="errorTitle">{this.props.t('calibration.errorTitle')}</h2>
                    <h3 className="errorMessage">{this.props.t('calibration.errorMsgStepOut')}</h3>
                </div>)
        }
        else if(this.props.calibrationStatus == 'user stepped out'){           
            return (
                <div className="errorMessage">
                    <h2>{this.props.t('calibration.errorTitle')}</h2>
                    <h3>{this.props.t('calibration.errorMsgStepOn')}</h3>
                </div>)
        }
    }
    calibrateButton() {
        if(this.props.calibrationStatus!=='pending' && this.props.calibrationStatus !=='error' && this.props.calibrationStatus!=='request'){
            return (               
                <div
                    className="ui primary exercise-bottom  button"
                    style={{ width: "65vw", height: 40 }}
                    onClick={this.startCalibration}>
                    {this.props.t('common:btn.calibrate')}
                </div>
            )
        }
    }
    progressBar() {
        if (this.props.calibrationStatus == 'request' || this.props.calibrationStatus == 'pending') {
            return (
                <Progress percent={this.props.sensorDataArray.length} active  success></Progress>
            )
        }
    }
    initalMessage(){
        let calibrationMessage = '';
        if(userWeight ==0 && addWeights){
            calibrationMessage = this.props.t('calibration.withoutWeightHeader');
        }
        else if(userWeight >0 && addWeights )
            calibrationMessage = this.props.t('calibration.withWeightHeader');
        else
            calibrationMessage = this.props.t('calibration.initHeader');
        if(this.props.calibrationStatus=='stop')
            return (
                <h2 className='title'>{calibrationMessage}</h2>
            );
    }

    addWeights(){
        addWeights =!addWeights;
        this.forceUpdate();
    }
    

    addWeightsToggle(){
        if (this.props.calibrationStatus == 'stop' && userWeight==0) return (
            <div style={{display:'block'}}>
                <Label className='addWeightsLabel'>{this.props.t('calibration.addWeights')}</Label>
                <Checkbox label='' className='toggle' onChange={this.addWeights} toggle style={{ verticalAlign: 'middle' }} />
            </div>
        );
    }
    render(){
        return(
        <Modal className='overlay fullscreen ui modal' open={this.props.activityStatus=='calibrate'}>
                <Modal.Content>
                    <h1 className='title'>{this.props.t('calibration.title')}</h1>
                    {this.initalMessage()}
                    {this.errorMessage()}
                    {this.calibrateButton()}
                    {this.progressBar()}
                    <div>
                        {this.addWeightsToggle()}
                    </div>
                    
                </Modal.Content>
            </Modal>
            
        )
    }



}

export default withTranslation('workout')(connect(mapStateToProps)(Calibration))