import * as React from "react";
import "./SegmentHeader.css";

class SegmentHeader extends React.Component {
    public render() {
        return (
            <h3 className="segment-header">{this.props.children}</h3>
        );
    }
}

export default SegmentHeader;