import * as React from "react";
import { Form, Input } from "semantic-ui-react";
import { expect, validateForm } from "../../utils/Validator";
import { isEmpty } from "lodash";
import { Plan } from "common";
import Formatter from "../../utils/Formatter";

export interface GymFormOrderFields {
    count: string;
}

interface GymFormOrderState {
    values: GymFormOrderFields;
    errors: Partial<GymFormOrderFields>;
}

interface GymFormOrderProps {
    plan: Plan;
    onChange?: Function;
}

class GymFormOrder extends React.Component<GymFormOrderProps, GymFormOrderState> {
    public static readonly MIN_ORDER_COUNT = 1;
    public static readonly MAX_ORDER_COUNT = 100;

    public static defaultValues: GymFormOrderFields = {
        count: GymFormOrder.MIN_ORDER_COUNT.toString(),
    };

    public validations = [
        expect("count").required(),
        expect("count")
            .inRange(GymFormOrder.MIN_ORDER_COUNT, GymFormOrder.MAX_ORDER_COUNT)
            .withMessage(`must be a count between ${GymFormOrder.MIN_ORDER_COUNT} and ${GymFormOrder.MAX_ORDER_COUNT}`)
    ];

    constructor(props: GymFormOrderProps) {
        super(props);
        this.state = {
            values: GymFormOrder.defaultValues,
            errors: {}
        };
    }

    public handleInputChange = (event: React.SyntheticEvent<HTMLInputElement>, data: any) => {
        const { value } = event.target as HTMLInputElement;
        const values = { ...this.state.values, count: value };
        this.setState({ values });
        if (this.props.onChange) {
            this.props.onChange(values);
        }
    }

    public validate(): boolean {
        const errors = validateForm(this.state.values, this.validations);
        this.updateErrors(errors);
        return isEmpty(errors);
    }

    public updateErrors(errors: Partial<GymFormOrderFields>) {
        this.setState({ errors });
    }

    public render() {
        const { errors, values } = this.state;
        const plan = this.props.plan;
        const amount: string = Formatter.formatPrice(plan.amount / 100, plan.currency);
        return (
            <React.Fragment>
                <Form.Group widths="equal">
                    <Form.Field>
                        <label>Unit Price (per {plan.interval})</label>
                        <Input defaultValue={amount} readOnly={true} />
                    </Form.Field>
                    <Form.Field onChange={this.handleInputChange} error={"count" in errors}>
                        <label>Number of systems</label>
                        <Input type="number" value={values.count} />
                        <p>{errors.count}</p>
                    </Form.Field>
                </Form.Group>
            </React.Fragment>
        );
    }
}

export default GymFormOrder;