import * as React from "react";
import { Omit } from "common";

interface ZipInputProps extends Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, "onChange"> {
    value?: string;
    onChange?(event: React.ChangeEvent<HTMLInputElement>, value: string): void;
}

interface ZipInputState {
    value: string;
}

class ZipInput extends React.Component<ZipInputProps, ZipInputState> {
    constructor(props: ZipInputProps) {
        super(props);
        const value = props.value || "";
        this.state = {
            value:  this.formatValue(value)
        };
    }
    
    public componentWillReceiveProps(nextProps: ZipInputProps) {
        if (this.props.value !== nextProps.value) {
            const value = nextProps.value || "";
            this.setState({ value: this.formatValue(value) });
        }
    }

    public handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value: string = event.target.value;
        let result: string = this.formatValue(value);
        event.target.value = result;
        
        this.setState({ value: result });
        
        if (this.props.onChange && this.state.value !== result) {
            this.props.onChange(event, result);
        }
    }

    public formatValue = (value: string): string => {
        value = value.replace(" ", "");
        let result: string = "";

        for (let i = 0; i < Math.min(value.length, 6); ++i) {
            const char = value[i];
            if ((this.isEven(i) && this.isNumber(char)) || (!this.isEven(i) && !this.isNumber(char))) {
                break;
            }
            result += char;
        }

        // Add Space
        if (result.length > 3) {
            result = result.slice(0, 3) + " " + result.slice(3);
        }

        return result.toUpperCase();
    }

    public isEven(value: number) {
        return value % 2 === 0;
    }

    public isNumber(value: string | number) {
        return !Number.isNaN(Number(value));
    }

    public render() {
        const { onChange, ...props } = this.props;
        return <input {...props} onChange={this.handleChange} value={this.state.value} />;
    }
}

export default ZipInput;