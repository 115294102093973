import * as React from "react";
import { Table, Label } from "semantic-ui-react";
import { CommonOrder, CommonAddress, OrderStatus } from "common";
import OrderStatusSelect from "./OrderStatusSelect";

interface OrderTableRowProps {
    order: CommonOrder;
    editableStatus?: boolean;
}

class OrderTableRow extends React.Component<OrderTableRowProps> {

    public render() {
        const order = this.props.order;
        return (
            <Table.Row>
                <Table.Cell>{this.formatDate(order.createdDate)}</Table.Cell>
                <Table.Cell>{order.gym.name}</Table.Cell>
                <Table.Cell>{order.gym.phone}</Table.Cell>
                <Table.Cell>{CommonAddress.toString(order.gym.address)}</Table.Cell>
                <Table.Cell>{this.getType()}</Table.Cell>
                <Table.Cell>{Math.abs(order.count)}</Table.Cell>
                <Table.Cell>{this.renderStatus()}</Table.Cell>
            </Table.Row>
        );
    }

    public renderStatus() {
        const order = this.props.order;
        return this.props.editableStatus ? <OrderStatusSelect order={order} /> : <Label color="blue">{OrderStatus.toString(order.status)}</Label>;
    }

    public getType(): string {
        return this.props.order.count < 0 ? "Return" : "Add";
    }

    public formatDate = (date: Date) => {
        return new Date(date).toLocaleDateString();
    }
}

export default OrderTableRow;