import * as React from "react";
import { Modal, Header, Button, Message } from "semantic-ui-react";

interface RemoveAdminRightsProps {
    open: boolean;
    onCancel: Function;
    onConfirm: Function;
    loading?: boolean;
    error?: string;
}

class RemoveAdminRightsModal extends React.Component<RemoveAdminRightsProps> {
    public render() {
        return (
            <Modal open={this.props.open} basic={true} size="small">
                <Header icon="warning sign" content="Removing Admin Rights" />
                <Modal.Content>
                    <p>Are you sure that you want to remove the administrator rights of this user?</p>
                    {this.renderError()}
                </Modal.Content>
                <Modal.Actions>
                    <Button basic={true} color="red" onClick={() => this.props.onCancel()} inverted={true}>No</Button>
                    <Button color="green" loading={this.props.loading} onClick={() => this.props.onConfirm()} inverted={true}>Yes</Button>
                </Modal.Actions>
            </Modal>
        );
    }

    public renderError = () => {
        if (!this.props.error) {
            return null;
        }

        return (
            <Message error={true}>
                <p>{this.props.error}</p>
            </Message>
        );
    }
}

export default RemoveAdminRightsModal;