import { userConstants } from '../constants';
import { api } from '../config/axiosConfig';
import { AuthenticationCredentials } from 'nodemailer/lib/smtp-connection';

export interface Credentials {
    email: string;
    password: string;
}

export interface SignUpInfos {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    comeFromEmail:boolean
}

export interface ProfileInfos {
    firstName: string;
    lastName: string;
    weight: string;
}

export const OAuthSuccess = (user: any, token: string) => (dispatch: any) => {
  dispatch({ type: userConstants.LOGIN_SUCCESS, user, token });
};


export const login = (credentials: Credentials) => {
  const request = () => ({ type: userConstants.LOGIN_REQUEST });
  const success = (user: any, token: string) => (
    {
      type: userConstants.LOGIN_SUCCESS,
      user,
      token,
    }
  );

  const failure = (response: any) => (
    {
      type: userConstants.LOGIN_FAILURE,
      errors: response,
    }
  );

  const requestURL = '/v1/auth/local';

  return (dispatch: any) => {
    dispatch(request());
    api()
      .post(requestURL, credentials)
      .then((response) => { dispatch(success(response.data.user, response.data.token)); })
      .catch((error) => { dispatch(failure(error.response.data)); });
  };
};

export const facebookLogin=(access_token:any)=>{
  const request = () => ({ type: userConstants.LOGIN_REQUEST });
  const success = (user: any, token: string) => {
    return{
      type: userConstants.LOGIN_SUCCESS,
      user,
      token,
    }
  }

  const failure = (response: any) => {
    return{
      type: userConstants.LOGIN_FAILURE,
      errors: response,
    }
  };

  const requestURL = '/v1/auth/facebookLogin';

  return async(dispatch: any) => {
    dispatch(request());
    await api()
      .post(requestURL, access_token)
      .then((response) => { dispatch(success(response.data.user, response.data.token)); })
      .catch((error) => { dispatch(failure(error.response.data)); });
  };
}
export const googleLogin=(id_token:any)=>{
  const request = () => ({ type: userConstants.LOGIN_REQUEST });
  const success = (user: any, token: string) => {
    return{
      type: userConstants.LOGIN_SUCCESS,
      user,
      token,
    }
  };

  const failure = (response: any) => {
    return{
      type: userConstants.LOGIN_FAILURE,
      errors: response,
    }
  };

  const requestURL = '/v1/auth/googleLogin';

  return async(dispatch: any) => {
    dispatch(request());
    await api()
      .post(requestURL, id_token)
      .then((response) => { dispatch(success(response.data.user, response.data.token)); })
      .catch((error) => { dispatch(failure(error.response.data)); });
  };
}
export const socialLogInPending=()=>{
  const request = () => ({ 
    type: userConstants.LOGIN_REQUEST
   });
  return (dispatch: any) => {
    dispatch(request());
  };
}
export const signup = (credentials: SignUpInfos) => {
  const request = () => ({ type: userConstants.SIGNUP_REQUEST });
  const success = () => ({ type: userConstants.SIGNUP_SUCCESS });
  const successLogInUser=(user: any, token: string)=>({
    type: userConstants.LOGIN_SUCCESS,
    user,
    token,
  })

  const failure = (response: any) => (
    {
      type: userConstants.SIGNUP_FAILURE,
      errors: response,
    }
  );

  const requestURL = '/v1/auth/signup';
  return (dispatch: any) => {
    dispatch(request());
    console.log(credentials);
    api()
      .post(requestURL, {credentials})
      .then((response) => { 
          dispatch(success());
          dispatch(successLogInUser(response.data.user, response.data.token)) 
        })
      .catch((error) => { dispatch(failure(error.response.data)); });
  };
};

export const disconnect = () => ({ type: userConstants.DISCONNECT });

export const modifyProfile = ( infos: ProfileInfos) => {
  const request = () => ({ type: userConstants.MODIFY_REQUEST });
  const success = (data: any) => ({ type: userConstants.MODIFY_SUCCESS, data });
  const failure = (data: any) => ({ type: userConstants.MODIFY_FAILURE, data });

  const requestURL = '/v1/athlete/';

  return (dispatch: any) => {
    dispatch(request());
    api()
      .patch(requestURL, infos)
      .then((response) => { dispatch(success(response.data)); })
      .catch((error) => { dispatch(failure(error.response.data)); });
  };
};

export const setRedirectURL = (url: string) => ({
  type: userConstants.REDIRECT_URL,
  url,
});

export const fetchProfileInfo = (id: string) => {
  const numId = +id;
  const request = () => ({ type: userConstants.USER_PROFILE_REQUEST });
  const success = (data: any) => ({ type: userConstants.USER_PROFILE_SUCCESS, data });
  const failure = (data: any) => ({ type: userConstants.USER_PROFILE_FAILURE, data });

  const requestURL = `/v1/athletes/${numId}/profile`;

  return (dispatch: any) => {
    dispatch(request());
    api()
      .get(requestURL)
      .then((response) => { dispatch(success(response.data)); })
      .catch((error) => { dispatch(failure(error.data)); });
  };
};


export const clearRedirectURL = () => setRedirectURL('');

