import { workoutsConstants } from "../constants/workouts.constants";
import { api } from "../config/axiosConfig";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";

export const getAllWorkouts = () => {
    const request = () => (
        { type: workoutsConstants.WORKOUTS_ALL_REQUEST }
    );

    const success = (data: any) => (
        { type: workoutsConstants.WORKOUTS_ALL_SUCCESS, data }
    );

    const failure = (data: any) => (
        { type: workoutsConstants.WORKOUTS_ALL_FAILURE, data }
    );
    const data = require('../assets/workouts/workouts.json');
    const requestURL = `/v1/workout/getWorkouts`;
    return (dispatch: Dispatch<void>) => {
        dispatch(request());
        api()
        .get(requestURL)
        .then((response) => { dispatch(success(response.data)); })
        .catch((error) => { dispatch(failure(error.data)); });
        dispatch(success(data));
    };
};

