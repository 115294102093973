import * as React from "react";
import { Header, Container } from "semantic-ui-react";
import PageMenu from "./PageMenu";
import { Switch, RouteComponentProps, Route, Redirect } from "react-router";
import GymSection from "./GymSection";
import OrderSection from "./OrderSection";

class AdminGymsPage extends React.Component<RouteComponentProps<void>> {
    public render() {
        return (
            <Container>
                <Header as="h1">
                    Manage Gyms
                    <Header.Subheader>
                        Manage all gyms on the platform
                    </Header.Subheader>
                </Header>
                <PageMenu />
                <Switch>
                    <Route exact={true} path={`${this.props.match.url}/`} component={GymSection} />
                    <Route path={`${this.props.match.url}/orders`} component={OrderSection} />
                    <Redirect to={`${this.props.match.url}/`} />
                </Switch>
            </Container>
        );
    }
}

export default AdminGymsPage;