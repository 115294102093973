import { adminList, AdminListState } from "./adminList.reducer";
import { addAdmin, AddAdminState } from "./addAdmin.reducer";
import { removeAdmin, RemoveAdminState } from "./removeAdmin.reducer";
import { combineReducers } from "redux";

export interface AdminManagementState {
    adminList: AdminListState;
    addAdmin: AddAdminState;
    removeAdmin: RemoveAdminState;
}

export const adminManagement = combineReducers({
    adminList: adminList,
    addAdmin: addAdmin,
    removeAdmin: removeAdmin,
});