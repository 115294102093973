
import {Workout, Rep} from './Workout';
import * as sensorDataAnalysis from '../utils/sensorDataAnalysis';
import {std, mean} from 'mathjs';
import { CommonInterval } from 'common';
import { Interval } from './Interval';
import { isEqual } from 'lodash';

interface Activity {
    activityType: ActivityType,
    paused: boolean,
    calibrated:boolean,
    beforePausedState: string,
    intervals: Array<Interval>,
    intervalStart: number,
    liveGraphData: { x: number, y: number },
    currentRepIndex: number,
    currentRep: Rep,
    userWeight: any,
    userStrength:number,
    addedWeights:number,
    pulledWeight:any,
    lastPulledWeight:any,
    workout: Workout,

    updateCurrentRep(),
    updateLiveGraphData(time:number, strength:number),
    updateIntervals(),

    updatePulledWeigth(forcePlateData:number),

    start(),
    pause(activityState:string),
    restart(),
    stop(),
    finish(),
    reset(),
    calibrate(forcePlateData:number),
    detectUserAction()
}
interface ActivityType{
    title: string,
    type: string,
    selectedWorkout: string,
    notes: string,
}
class Activity implements Activity{
    constructor(activityTypeParam: ActivityType, workout: Workout){
        this.activityType = activityTypeParam;
        this.paused=false;
        this.calibrated=false;
        this.intervals=new Array();
        this.liveGraphData={x:0, y:0};
        this.beforePausedState=null;
        this.intervalStart=0;
        this.currentRepIndex=0;
        this.userWeight=[];
        this.workout = new Workout(workout);
        this.pulledWeight=0;
        this.lastPulledWeight=0;
        this.addedWeights = 0;
        this.userStrength=0;
        this.currentRep = new Rep();
        
        this.updateCurrentRep=()=>{
            this.currentRepIndex=this.currentRepIndex + 1;
            this.currentRep =  this.workout.getRepByIndex(this.currentRepIndex);
            this.pulledWeight=null;
            this.calculateExpectedStrength();
        }
        this.updateIntervals=()=>{
            const index = sensorDataAnalysis._weightDataArray.length-1;
            if(this.intervalStart != null){
                const repIndex = this.currentRepIndex ==0 ? 0 : this.currentRepIndex-1;
                const currrentFingers = this.workout.getRepByIndex(repIndex).fingers;
                this.intervals.push(new Interval({start:this.intervalStart, stop:index, fingers: currrentFingers, grip: this.workout.getRepByIndex(repIndex).grip}));
                this.intervalStart=null;
            }
            else if(this.workout.content.reps.length-1 != this.currentRepIndex && this.workout.getRepByIndex(this.currentRepIndex+1).intensity !==0)
                this.intervalStart=index;
        }
    
        this.updateLiveGraphData=(time:number, strength:number)=>{        
            this.liveGraphData={
                x:time,
                y: strength,
            };
            return this.liveGraphData;
        }
        this.detectUserAction=()=>{
            const intervThreshold = 3; // [kg]
            if(/*this.lastPulledWeight &&*/ this.pulledWeight >intervThreshold /*&& this.lastPulledWeight <=intervThreshold*/)
                return 'hang';
            else if(this.pulledWeight < intervThreshold /*&& this.lastPulledWeight >=intervThreshold*/)
                return "rest";
        }
    
        this.start=()=>{
            this.paused=false;
            return this.beforePausedState ? this.beforePausedState: 'start'
        }
        this.pause=(activityState:string)=>{
            this.paused=true;
            this.beforePausedState=activityState;
        }
        this.reset=()=>{
            this.paused=false;
            this.intervals=new Array();
            this.liveGraphData={x:0,y:0};
            this.beforePausedState=null;
            this.intervalStart=0;
            this.currentRepIndex=0;
            this.userWeight=null;
            this.currentRep = {...this.workout.getRepByIndex(0), expectedStrength: 0};
            this.calculateExpectedStrength();
        }

        this.finish=async()=>{
            this.updateIntervals();
            return await sensorDataAnalysis.onEnd(this.userWeight, this.activityType,this.addedWeights, this.intervals)
            .then((response)=>{
                this.reset();
                return response});
        }
    

        this.calibrate=(forcePlateData: number)=>{
            if(this.userWeight.length<  50)
                this.userWeight.push(forcePlateData);
            else{
                if(std(this.userWeight)<1){
                    this.userWeight = mean(this.userWeight);
                    this.calibrated=true;
                    return true;
                }else{
                    this.userWeight.length=0;
                    this.calibrated=false;
                }    
            }
            return false;
        }
        this.updatePulledWeigth=(forcePlateData:number)=>{
            let weight:any= Math.round(10 * (this.userWeight + this.addedWeights - forcePlateData)) / 10;
            if (this.userWeight + this.addedWeights - forcePlateData < 0.15)
                weight= 0;
            else if (forcePlateData < 0.15)
                weight= 'Out';
            this.lastPulledWeight=this.pulledWeight;
            this.pulledWeight= weight;

        }
    }
    setUserStrength(value: number){
        this.userStrength = value!== null ? value : 60
        this.calculateExpectedStrength();   
    }
    calculateExpectedStrength(){
        let maxValue = 0;
        if (this.currentRep.intensity !== 0) {
            const intensity = this.currentRep.intensity;
            const numberOfHands = isEqual(this.currentRep.fingers, [[1, 1, 1, 1, 0], [0, 1, 1, 1, 1]]) ? 2 : 1;
            maxValue = (intensity / 100) * this.userStrength * numberOfHands;
        }
        this.currentRep.expectedStrength = maxValue;
    }
}

export default Activity;