import React from 'react';
import { Button, Card, Input } from 'semantic-ui-react';
import { ITrainingBloc } from '../classes';

interface ITrainingBlocProps{
    handleDelete: Function,
    bloc: ITrainingBloc,
    isAdmin:boolean
}
const TrainingBloc = (props: ITrainingBlocProps) => {
    const handsConfig = ['Left', 'Right'];
    const fingers = ['Thumb', 'Index', 'Middle', 'Ring', 'Pinky'];
    const displayFingers = (hand: string) => {
        const handIndex = hand == 'Left' ? 0 : 1;
        const fingersValues = handIndex == 0 ? [...fingers].reverse() : [...fingers];
        return fingersValues.map((finger) => {
            let fingerIndex = fingersValues.findIndex(x => x == finger);
            const isActive = props.bloc.fingers[handIndex][fingerIndex] == 1 ? true : false;
            /*AjoutPouce if (finger != 'Thumb') {}*/
            return <Button toggle active={isActive} onClick={() => props.bloc.setFingers(handIndex, fingerIndex)}> {finger} </Button>
            /*AjoutPouce return null;*/
        }
        );
    }

    const displayHands =
        handsConfig.map((hand) =>
            <Card>
                <Card.Content>
                    <Card.Header>{hand}</Card.Header>
                    <Card.Description>
                        {displayFingers(hand)}
                    </Card.Description>
                </Card.Content>
            </Card>
        );
    const guideline = () => {
        if (props.isAdmin)
            return (
                <Card.Content>
                    <Card.Header> Guideline</Card.Header>
                    <Input id='guideline' placeholder='Enter the guideline' onChange={props.bloc.setGuideline} />
                </Card.Content>
            )
    }
    return (
        <div>
            <Card>
                <Card.Content>
                    <Card.Header> Hang</Card.Header>
                    <Button negative onClick={() => props.handleDelete(props.bloc.id)}> X </Button>
                </Card.Content>

                <Card.Content>
                    <Card.Header>Time</Card.Header>
                    <Card.Description><Button onClick={() => props.bloc.setTime('+')}> + </Button>{props.bloc.time}<Button onClick={() => props.bloc.setTime('-')}> - </Button></Card.Description>
                </Card.Content>
                {guideline()}
                <Card.Content>
                    <Card.Header> Intensity</Card.Header>
                    <Card.Description><Button onClick={() => props.bloc.setIntensity('+')}> + </Button>{props.bloc.intensity}<Button onClick={() => props.bloc.setIntensity('-')}> - </Button></Card.Description>
                </Card.Content>
                <Card.Content>
                    <Card.Header> Fingers</Card.Header>
                    {displayHands}
                </Card.Content>
            </Card>
        </div>

    )
}


export default TrainingBloc;