import { navBarConstants } from "../constants/navBar.constants";

export interface NavBarState {
    showSideBar: boolean;
    visibility: boolean;
}

const defaultState = {
    showSideBar: false,
    visibility: true,
};

export const navBar = (state: NavBarState = defaultState, action: any) => {
    switch (action.type) {
        case navBarConstants.NAV_BAR_TOGGLE_SIDEBAR:
            return {
                ...state,
                showSideBar: !state.showSideBar,
            };
        case navBarConstants.NAV_BAR_CLOSE_SIDEBAR:
            return {
                ...state,
                showSideBar: false,
            };
        case navBarConstants.NAV_BAR_SHOW_BAR:
            return {
                ...state,
                visibility: true,
            };
        case navBarConstants.NAV_BAR_HIDE_BAR:
            return {
                ...state,
                visibility: false,
            };
            default:
            return { ...state };
    }
};
