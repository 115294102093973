import * as React from "react";
import { Form, Input } from "semantic-ui-react";
import { expect, validateForm } from "../../utils/Validator";
import { isEmpty, isEqual } from "lodash";
import ZipInput from "../Form/ZipInput";
import PhoneInput from "../Form/PhoneInput";

export interface GymFormGeneralFields {
    name: string;
    phone: string;
    line: string;
    city: string;
    province: string;
    zip: string;
    country: string;
}

interface GymFormGeneralState {
    values: GymFormGeneralFields;
    errors: Partial<GymFormGeneralFields>;
}

interface GymFormGeneralProps {
    defaultValues?: GymFormGeneralFields;
    onChange?: Function;
}

class GymFormGeneral extends React.Component<GymFormGeneralProps, GymFormGeneralState> {

    public static defaultValues: GymFormGeneralFields = {
        name: "",
        phone: "",
        line: "",
        city: "Montréal",
        province: "QC",
        zip: "",
        country: "Canada"
    };

    public validations = [
        expect("name").required(),
        expect("phone").required(),
        expect("line").required(),
        expect("city").required(),
        expect("province").required(),
        expect("zip").required()
    ];

    constructor(props: GymFormGeneralProps) {
        super(props);
        const values = this.props.defaultValues || GymFormGeneral.defaultValues;
        this.state = {
            values,
            errors: {}
        };
    }

    componentWillReceiveProps(nextProps: GymFormGeneralProps) {
        if (nextProps.defaultValues && !isEqual(this.props.defaultValues, nextProps.defaultValues)) {
            this.setState({ values: nextProps.defaultValues });
        }
    }

    public handleInputChange = (event: React.SyntheticEvent<HTMLInputElement>, data: any) => {
        const { name, value } = event.target as HTMLInputElement;
        const values = { ...this.state.values };
        // values[name] = value;
        this.setState({ ...this.state, values });
        if (this.props.onChange) {
            this.props.onChange(values);
        }
    }

    public validate(): boolean {
        const errors = validateForm(this.state.values, this.validations);
        this.updateErrors(errors);
        return isEmpty(errors);
    }

    public updateErrors(errors: Partial<GymFormGeneralFields>) {
        this.setState({ errors });
    }

    public render() {
        const { errors, values } = this.state;
        return (
            <React.Fragment>
                <Form.Field onChange={this.handleInputChange} error={"name" in errors}>
                    <label>Gym Name</label>
                    <Input name="name" value={values.name} />
                    <p>{errors.name}</p>
                </Form.Field>
                <Form.Field onChange={this.handleInputChange} error={"phone" in errors}>
                    <label>Phone Number</label>
                    <PhoneInput name="phone" value={values.phone} />
                    <p>{errors.phone}</p>
                </Form.Field>
                <Form.Field onChange={this.handleInputChange} error={"line" in errors}>
                    <label>Address</label>
                    <Input name="line" value={values.line} />
                    <p>{errors.line}</p>
                </Form.Field>
                <Form.Group widths="equal">
                    <Form.Field>
                        <label>City</label>
                        <Input name="city" value={values.city} readOnly={true} />
                        <p>{errors.city}</p>
                    </Form.Field>
                    <Form.Field>
                        <label>Province</label>
                        <Input name="province" value={values.province} />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                    <Form.Field onChange={this.handleInputChange} error={"zip" in errors}>
                        <label>Zip Code</label>
                        <ZipInput name="zip" value={values.zip} />
                        <p>{errors.zip}</p>
                    </Form.Field>
                    <Form.Field >
                        <label>Country</label>
                        <Input name="country" value={values.country} readOnly={true} />
                    </Form.Field>
                </Form.Group>
            </React.Fragment>
        );
    }
}

export default GymFormGeneral;