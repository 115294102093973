export const activitiesConstants = {
    ACTIVITIES_REQUEST: "ACTIVITIES_REQUEST",
    ACTIVITIES_SUCCESS: "ACTIVITIES_SUCCESS",
    ACTIVITIES_FAILURE: "ACTIVITIES_FAILURE",

    ARCHIVE_REQUEST: "ARCHIVE_ACTIVITY_REQUEST",
    ARCHIVE_SUCCESS: "ARCHIVE_ACTIVITY_SUCCESS",
    ARCHIVE_FAILURE: "ARCHIVE_ACTIVITY_FAILURE",

    UPLOAD_SUCCESS: "UPLOAD_ACTIVITY_SUCESS",
    UPLOAD_FAILURE: "UPLOAD_ACTIVITY_FAILURE",

    UPDATE_FILTERS: "UPDATE_ACTIVITIES_FILTERS",
    SET_PAGE: "SET_ACTIVITIES_PAGE",
    
    ACTIVITIES_RESET_STATE: "ACTIVITIES_RESET_STATE",

    ACTIVITY_STATUS: "UPDATE_ACTIVITY_STATUS",
    TIMER_STATUS: "UPDATE_TIMER_STATUS",
    CALIBRATION_SATUS: "UPDATE_CALIBRATION_STATUS",
    CURRENT_REP: "UPDATE_CURRENT_REP_STATUS",
};