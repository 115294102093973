import * as React from "react";
import { Menu } from "semantic-ui-react";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";

enum GymPageTab {
    GENERAL,
    RENTALS,
    BILLING
}

interface GymPageMenuState {
    activeTab: GymPageTab;
}

class GymPageMenu extends React.Component<RouteComponentProps<void>, GymPageMenuState> {

    constructor(props: RouteComponentProps<void>) {
        super(props);
        this.state = {
            activeTab: this.findActiveTab()
        };
    }

    render() {
        const activeTab = this.state.activeTab;
        return (
            <Menu pointing={true} secondary={true} color="orange" size="large">
                <Menu.Item
                    as={Link}
                    to={`${this.props.match.url}/general`}
                    name="General Infos"
                    active={activeTab === GymPageTab.GENERAL}
                    onClick={() => this.setActiveTab(GymPageTab.GENERAL)}
                />
                <Menu.Item 
                    as={Link} 
                    to={`${this.props.match.url}/rentals`} 
                    name="Rentals" 
                    active={activeTab === GymPageTab.RENTALS} 
                    onClick={() => this.setActiveTab(GymPageTab.RENTALS)}
                />
                <Menu.Item 
                    as={Link} 
                    to={`${this.props.match.url}/billing`} 
                    name="Billing" 
                    active={activeTab === GymPageTab.BILLING} 
                    onClick={() => this.setActiveTab(GymPageTab.BILLING)}    
                />
            </Menu>
        );
    }

    private setActiveTab = (activeTab: GymPageTab) => {
        this.setState({ activeTab });
    }

    private findActiveTab = () => {
        const path = this.props.location.pathname;
        const isRentals: boolean = path.search("rentals") >= 0;
        if (isRentals) {
            return GymPageTab.RENTALS;
        }

        const isBilling: boolean = path.search("billing") >= 0;
        if (isBilling) {
            return GymPageTab.BILLING;
        }

        return GymPageTab.GENERAL;
    }

}

export default withRouter(GymPageMenu);