import * as React from "react";
import { connect } from "react-redux";
import { Container, Form, Grid } from "semantic-ui-react";
import { modifyProfile } from "../../actions/user.actions";
import { cancelEditProfile, updateErrorsProfile } from "../../actions/settings.actions";
import { SettingsProfileState} from "../../reducers/settingsProfile.reducer";
import { State} from "../../reducers";
import { expect, validateForm } from "../../utils/Validator";
import { isEmpty } from "lodash";
import ImageUpload from "../ImageUpload/ImageUpload";
import { CommonUser } from "common";
import { WithTranslation, withTranslation } from "react-i18next";

interface EditSettingsProfileProps extends WithTranslation {
    user: CommonUser;
    status: SettingsProfileState;
    dispatch: any;
    endEditingCallback: () => void;
}

interface EditSettingsProfileState {
    firstName: string;
    lastName: string;
    weight: string;
}

const mapStateToProps = (state: State) => {
    return {
        user: state.authentication.user,
        status: state.settings.profile
    };
};

class EditSettingsProfile extends React.Component<EditSettingsProfileProps, EditSettingsProfileState> {

    // Properties
    private validations = [
        expect("firstName").required(),
        expect("lastName").required(),
    ];

    constructor(props: EditSettingsProfileProps) {
        super(props);
        this.state = {
            firstName: this.props.user.firstName || "",
            lastName: this.props.user.lastName || "",
            weight: this.props.user.weight.toString() || "0"
        };
    }

    // Functions
    componentWillReceiveProps(nextProps: EditSettingsProfileProps) {
        if (nextProps.status.success) {
            this.props.dispatch(cancelEditProfile());
            this.props.endEditingCallback();
        }
    }

    public handleInputChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        const { name, value } = event.target as HTMLInputElement;
        this.setState({ ...this.state, [name]: value });
    }

    public handleSudmitButton = () => {
        const errors = validateForm(this.state, this.validations);

        if (isEmpty(errors)) {
            this.props.dispatch(modifyProfile(this.state));
        } else {
            this.props.dispatch(updateErrorsProfile(errors));
        }
    }

    public render() {
        const errors = this.props.status.errors;
        return (
            <Container className="profile-settings">
                <Form as={Grid}>
                    <Grid.Column width={6} verticalAlign="middle" textAlign="center">
                        <ImageUpload />
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Form.Group widths="equal">
                            <Form.Input
                                label={this.props.t('profile.fName')}
                                name="firstName"
                                placeholder={this.props.t('profile.fName')}
                                value={this.state.firstName}
                                onChange={this.handleInputChange}
                                width={8}
                                error={"firstName" in errors}
                            />
                            <Form.Input
                                label={this.props.t('profile.lName')}
                                name="lastName"
                                placeholder={this.props.t('profile.lName')}
                                value={this.state.lastName}
                                onChange={this.handleInputChange}
                                width={8}
                                error={"lastName" in errors}
                            />
                        </Form.Group>
                        <Form.Group className="right">
                            <Form.Button primary={true} onClick={this.handleSudmitButton}>{this.props.t('common:btn.save')}</Form.Button>
                            <Form.Button onClick={this.props.endEditingCallback}>{this.props.t('common:btn.cancel')}</Form.Button>
                        </Form.Group>
                    </Grid.Column>
                </Form>
            </Container>
        );
    }
}

export default withTranslation('settings')(connect(mapStateToProps)(EditSettingsProfile));
