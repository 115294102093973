import * as React from "react";
import { RouteComponentProps, Redirect } from "react-router";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Button, Segment } from "semantic-ui-react";
import { api } from "../../config/axiosConfig";
import { Scatter } from "react-chartjs-2";
import { AxiosResponse } from "axios";
import { State } from "../../reducers";
import { CommonAcquisition } from "common";
import LoadingOverlay from "../../components/LoadingOverlay";
import { IntervalTable } from "../../components/ActivityDetails";
import * as JSZip from "jszip";
import * as FileSaver from "file-saver";
import SegmentHeader from "../../components/SegmentHeader/SegmentHeader";
import ActivityGeneral from "../../components/ActivityDetails/ActivityGeneral/ActivityGeneral";
import "./ActivityDetails.css";
import IntervalChart from "../../components/ActivityDetails/IntervalChart/IntervalChart";
import { ChartOptions } from "chart.js";
import { stat } from "fs";
import { isUndefined } from "util";

const MAX_GRAPH_POINTS = 2000;

interface RouteProps {
    id: string;
}

interface ActivityDetailsProps extends RouteComponentProps<RouteProps> {
    userId: number;
    userPrivacy: string;
    dispatch: Dispatch<void>;
    weightUnit: string;
}

interface ActivityDetailsState {
    redirect: boolean;
    activityId?: number;
    activity?: CommonAcquisition;
    chartData?: any;
    csvRaw?: any;
}

const mapStateToProps = (state: State) => ({
    userId: state.authentication.user.id,
    userPrivacy: state.authentication.user.privacySettings.privacy,
    weightUnit: state.authentication.user.displaySettings.weightUnit
});
let weightUnitFactor;
class ActivityDetails extends React.Component<ActivityDetailsProps, ActivityDetailsState> {
    constructor(props: ActivityDetailsProps) {
        super(props);

        const activityId = Number(this.props.match.params.id);
        this.state = { redirect: false, activityId: activityId };

        if (!activityId) {
            this.state = { redirect: true };
        }
    }

    componentDidMount() {
        api()
            .get(`/v1/activities/${this.state.activityId}`)
            .then((response: AxiosResponse) => { this.setState({ redirect: false, activity: response.data }); })
            .catch(() => { this.setState({ redirect: true }); });
        weightUnitFactor = this.props.weightUnit == 'lbs'? 2.2 : 1;
    }
    render() {
        if (this.state.redirect) { return <Redirect to="/" />; }
        if (!this.state.activity) { return <LoadingOverlay />; }
        if (!this.state.chartData) { this.getChartData(this.state.activity.data); }

        const chartData = this.generateChartData();
        const chartOptions = this.generateChartOptions();

        return (
            <React.Fragment>
                <ActivityGeneral activity={this.state.activity} privacySettings={this.props.userPrivacy} weightUnit={this.props.weightUnit}/>
                <Segment className="with-header">
                    <SegmentHeader>Force applied over time</SegmentHeader>
                    <div><Scatter height={400} data={chartData} options={chartOptions} /></div>
                    <Button className="export-activity" content="Export data" onClick={this.downloadCSV} />
                </Segment>
                {this.renderIntervalAnalysis()}
            </React.Fragment>
        );
    }

    public renderIntervalAnalysis = () => {
        const activity: CommonAcquisition = this.state.activity as CommonAcquisition;
        const chartDimensions = { height: 300 };
        return (
            <Segment className="with-header">
                <SegmentHeader>Analysis per interval</SegmentHeader>
                <IntervalChart dimensions={chartDimensions} intervals={activity.result.intervals} weightUnit = {this.props.weightUnit}/>
                <div style={{width:"100%",display: "inline-block",overflow:"scroll"}}>
                    <IntervalTable intervals={activity.result.intervals} weightUnit = {this.props.weightUnit}/>
                </div>
            </Segment>
        );
    }

    private downloadCSV = () => {
        if (this.state.csvRaw) {
            FileSaver.saveAs(new Blob([this.state.csvRaw]), "activity.csv");
        }
    }

    private generateChartData() {
        return {
            labels: ["Scatter"],
            datasets: [{
                label: "Effort",
                fill: true,
                showLine: true,
                backgroundColor: "rgba(192,89,54,0.4)",
                pointBorderColor: "rgba(192,89,54,1)",
                borderColor: "rgba(192,89,54,.8)",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#6D6D6D",
                pointHoverBorderColor: "#323232",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 9,
                data: this.state.chartData ? this.state.chartData : []
            }]
        };
    }

    private generateChartOptions(): ChartOptions {
        return {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false,
                labels: {
                    fontColor: "rgba(255, 255, 255,0.2)"
                }
            },
            elements: {
                line: {
                    tension: 0 // disables bezier curves
                }
            },
            scales: {
                display: true,
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: `Force applied (${this.props.weightUnit})`,
                        fontColor: "rgba(255, 255, 255,0.95)",
                    },
                    gridLines: {
                        color: "rgba(255, 255, 255,0.1)"
                    },
                    ticks: {
                        fontColor: "rgba(255, 255, 255,0.6)"
                  }
                }],
                xAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: "Time (seconds)",
                        fontColor: "rgba(255, 255, 255,0.95)",
                    },
                    gridLines: {
                        color: "rgba(255, 255, 255,0.1)"
                    },
                    ticks: {
                        fontColor: "rgba(255, 255, 255,0.6)"
                  }
                }],
            },
            tooltips: {
                callbacks: {
                    // tslint:disable-next-line: typedef
                    label: function(tooltipItem) {
                        return tooltipItem.yLabel;
                    }
                },
                backgroundColor: "#fff",
                titleFontSize: 16,
                titleFontColor: "#0066ff",
                bodyFontColor: "#000",
                bodyFontSize: 14,
                displayColors: false
              }
        };
    }

    private async getChartData(base64Data: string) {
        // Convert base64 string back to a zip archive
        const archive = atob(base64Data);
        // Open the archive
        JSZip.loadAsync(archive).then((zip: any) => {
            // Get the actual file
            Object.keys(zip.files).forEach((filename: string) => {
                zip.files[filename].async("string").then((fileData: any) => {
                    // Convert the file to usable csv
                    const csv = fileData.replace(/\r?\n|\r/g, ",").split(/,/);
                    // Uniformly select a maximum of MAX_GRAPH_POINTS from the csv for performance reasons
                    const compressionRatio = Math.ceil((csv.length / 2) / MAX_GRAPH_POINTS);
                    let selectedData = [];
                    for (let i = 0; i < csv.length; i += 2 * compressionRatio) {
                        selectedData.push({ x: csv[i + 1], y: csv[i]*weightUnitFactor });
                    }
                    if(this.state.activity.type!== 'raw')
                        selectedData = this.getAllZeros(selectedData, csv);
                    this.setState({ ...this.state, chartData: selectedData, csvRaw: fileData });
                });
            });
        });
    }
    private getAllZeros(selectedData: any, csv){
        csv.forEach((data,index) => {
            if(data === '0'){
                const i = selectedData.findIndex((x)=>parseFloat(x.x) >  parseFloat(csv[index+1]));               
                if(i==-1)
                    selectedData.push({x:csv[index+1], y:0}); 
                else if(i!=0 && selectedData[i+1].y!="0")
                    selectedData.splice(i,0, {x: csv[index+1], y: csv[index]});
            } 
        });  
        return selectedData;
    }
}

export default connect(mapStateToProps)(ActivityDetails);
