import * as React from "react";
import { Table } from "semantic-ui-react";
import { CommonOrder } from "common";
import OrderTableRow from "./OrderTableRow";

interface OrderTableProps {
    orders: CommonOrder[];
    actions?: boolean;
    onCancel?: (orderId: number) => void;
}

class OrderTable extends React.Component<OrderTableProps> {
    public render() {
        const rows = this.props.orders.map((order) =>
            <OrderTableRow key={order.id} order={order} actions={this.props.actions} onCancel={this.props.onCancel} />
        );
        return (
            <Table color="orange" size="small" singleLine={true} unstackable={true}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Creation Date</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Count</Table.HeaderCell>
                        <Table.HeaderCell>Status</Table.HeaderCell>
                        {this.renderActions()}
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {rows}
                </Table.Body>
            </Table>
        );
    }

    public renderActions = () => {
        return this.props.actions ? <Table.HeaderCell>Actions</Table.HeaderCell> : null;
    }
}

export default OrderTable;