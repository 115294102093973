import stringValidators from "validator";

export type Rule = (value: string) => boolean;
export type FormError = { [key: string]: string };
export type OnlyString<T> = { [P in keyof T]: string };

export const expect = (fieldName: string) => {
    return new ChainedValidator(fieldName);
};

export const validateForm = <T>(formValues: any, validators: ChainedValidator[]): FormError => {
    const reducer = (accumulator: FormError, currentValidator: ChainedValidator) => {
        if (!(currentValidator.fieldName in accumulator)) {
            const value = formValues[currentValidator.fieldName].toString();
            const results = currentValidator.applyRules(value);

            if (!results.isValid) {
                const error = { [currentValidator.fieldName]: currentValidator.message };
                accumulator = Object.assign(accumulator, error);
            }
        }
        return accumulator;
    };

    return validators.reduce(reducer, {});
};

export class ChainedValidator {
    public rules: Rule[] = [];
    public message: string = "";
    public fieldName: string = "";

    constructor(fieldName: string) {
        this.fieldName = fieldName;
    }

    public applyRules(value: string): { isValid: boolean, message: string | null } {
        for (let i = 0; i < this.rules.length; ++i) {
            let isRuleCorrect = this.rules[i](value);
            if (!isRuleCorrect) {
                return { isValid: false, message: this.message };
            }
        }
        return { isValid: true, message: null };
    }

    public withMessage(message: string = "") {
        this.message = message;
        return this;
    }

    public required() {
        this.setDefaultMessage("is required");
        const rule: Rule = (value: string) => !stringValidators.isEmpty(value);
        this.addRule(rule);
        return this;
    }

    public isEmail() {
        this.setDefaultMessage("must be an email");
        this.addRule(stringValidators.isEmail);
        return this;
    }

    public isLength(min: number = 0, max?: number) {
        this.setDefaultMessage("must be in range");
        const rule: Rule = (value: string) => stringValidators.isLength(value, { min, max });
        this.addRule(rule);
        return this;
    }

    public inRange(min: number, max: number) {
        this.setDefaultMessage(`Must be between ${min} and ${max}`);
        const rule: Rule = (value: string) => {
            let isInt = stringValidators.isInt(value);
            if (!isInt) {
                return false;
            }
            const num = parseInt(value, 10);
            return min <= num && num <= max;
        };
        this.addRule(rule);
        return this;
    }

    public isInt() {
        this.setDefaultMessage("Must be a number");
        const rule: Rule = (value: string) => stringValidators.isInt(value);
        this.addRule(rule);
        return this;
    }

    public hasPattern(pattern: RegExp) {
        this.setDefaultMessage("doest not match pattern");
        const rule: Rule = (value: string) => { 
            return pattern.test(value);
        };
        this.addRule(rule);
        return this;
    }

    private setDefaultMessage(message: string) {
        if (stringValidators.isEmpty(this.message)) {
            this.message = message;
        }
    }

    private addRule(rule: Rule) {
        this.rules.push(rule);
    }
}