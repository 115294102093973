import * as React from "react";
import { Modal, Button, Form, Input } from "semantic-ui-react";
import { OrderService } from "../../services/orders";
import { CommonGym } from "common";
import AxiosUtils from "../../utils/AxiosUtils";

type ModifySystemModalType = "add" | "return";

interface ModifySystemModalProps {
    gym: CommonGym;
    type: ModifySystemModalType;
    open: boolean;
    maximum: number;
    onSuccess?: any;
    onCancel?: any;
}

interface ModifySystemModalState {
    requesting: boolean;
    count: string;
    errors: any;
}

class ModifySystemModal extends React.Component<ModifySystemModalProps, ModifySystemModalState> {

    private readonly defaultState: ModifySystemModalState = {
        requesting: false,
        count: "1",
        errors: {}
    };

    constructor(props: ModifySystemModalProps) {
        super(props);
        this.state = this.defaultState;
    }

    public componentWillReceiveProps(nextProps: ModifySystemModalProps) {
        if (this.props.open && !nextProps.open) {
            this.setState(this.defaultState);
        }
    }

    public handleChange = (event: any) => {
        let count = this.sanitizeCount(event.target.value);
        this.setState({ count });
    }

    public handleSubmit = () => {
        this.sendRequest();
    }

    public render() {
        const { errors, count } = this.state;
        const headerText = this.isType("add") ? "Add Systems" : "Return Systems";
        const contentText = this.isType("add") ? "How many more systems do you want?" : "How many systems do you want to return?";
        const buttonText = this.isType("add") ? "Add" : "Return";
        return (
            <Modal open={this.props.open} size="mini">
                <Modal.Header>
                    {headerText}
                    </Modal.Header>
                <Modal.Content>
                    {contentText}
                    <Form>
                        <Form.Field onChange={this.handleChange} error={"count" in errors}>
                            <Input name="count" type="number" value={count} />
                            <p>{errors.count}</p>
                        </Form.Field>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="orange" onClick={this.handleSubmit}>{buttonText}</Button>
                    <Button color="blue" onClick={this.props.onCancel}>Cancel</Button>
                </Modal.Actions>
            </Modal>
        );
    }

    public isType(type: ModifySystemModalType) {
        return this.props.type === type;
    }

    public get count(): number {
        return Number(this.state.count);
    }

    public sanitizeCount = (value: string): string => {
        if (value) {
            const count = Number(value);
            if (count < 1) {
                value = "1";
            } else if (count > this.props.maximum) {
                value = this.props.maximum.toString();
            }
        }
        return value;
    }

    public sendRequest = () => {
        this.setState({ requesting: true });
        if (!this.state.requesting) {
            const count = this.isType("add") ? this.count : -this.count;
            OrderService.create(this.props.gym.id, count)
                .then(() => {
                    this.setState({ requesting: false });
                    this.props.onSuccess();
                })
                .catch((error) => {
                    const errors = AxiosUtils.getErrors(error);
                    this.setState({ requesting: false, errors });
                });
        }
    }
}

export default ModifySystemModal;