import * as React from "react";
import ActivityTable from "../../components/ActivityTable/ActivityTable";
import { ActivitiesState } from "../../reducers/activities.reducer";
import { Button } from "semantic-ui-react";
import "./ActivityPreview.css";
import { Link } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";

interface ActivityPreviewProps extends WithTranslation {
    activities: ActivitiesState;
    userId: string;
}

class ActivityPreview extends React.Component<ActivityPreviewProps> {
    public render() {
        const { activities } = this.props.activities;
        const numId = +this.props.userId;
        return (
            <React.Fragment>
                <ActivityTable activities={activities} />
                <div className="activity-preview-see-all-activities">
                    <Button as={Link} to={`/athletes/${numId}/activities/`}>
                    <p>{this.props.t('activityTable.seeAll')}</p>
                    </Button>
                </div>
            </React.Fragment>
        );
    }
}

export default withTranslation('profile')(ActivityPreview);
