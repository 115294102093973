import * as React from "react";
import { Line } from "react-chartjs-2";
import { StrengthGraphData } from "common";
import { Component } from "react";
import moment from "moment";
import { ChartData, ChartDataSets } from "chart.js";

export interface StrengthProgressionChartProps {
    strengthGraphData: StrengthGraphData;
}

export class StrengthProgressionChart extends Component<StrengthProgressionChartProps> {
    readonly CHART_STYLE: ChartDataSets = {
        lineTension: 0,
        fill: false,
        borderColor: "#4bc0c0"
    };
    
    render() {
        const strengthGraphData = this.getStrengthGraphData();
        const strengthGraphOptions = this.getStrengthGraphOptions();
        return (
            <React.Fragment>
                <Line legend={{ display: false }} data={strengthGraphData} options={strengthGraphOptions} />
            </React.Fragment>
        );
    }

    private getStrengthGraphData() {
        const dataFormatted = this.props.strengthGraphData.data.map((element: { x: Date, y: number }) => {
            const formattedDate = moment(element.x.toString()).format("LLLL");
            return { x: formattedDate, y: element.y };
        });

        const strengthGraphData: ChartData = {
            datasets: [
                {
                    ...this.CHART_STYLE,
                    data: dataFormatted,
                }
            ]
        };
        return strengthGraphData;
    }
    
    private getStrengthGraphOptions() {
        const data = this.props.strengthGraphData.data;
        let endDate = "";
        let startDate = "";
        if (data.length > 0) {
            endDate = moment().format();
            startDate = moment().subtract(1, "year").format();
        }
        const strengthGraphOptions = {
            responsive: true,
            title: {
                display: false,
            },
            scales: {
                xAxes: [{
                    type: "time",
                    time: {
                        min: startDate,
                        max: endDate
                    },
                    scaleLabel: {
                        display: true,
                        labelString: "Date"
                    }
                }],
                yAxes: [{
                    scaleLabel: {
                        display: true,
                        labelString: "Strength (kg)"
                    }
                }]
            }
        };
        return strengthGraphOptions;
    }
}
