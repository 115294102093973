import * as React from "react";
import { RouteComponentProps } from "react-router";
import ActivityTable from "../../components/ActivityTable/ActivityTable";
import { Header, Pagination, Grid, PaginationProps } from "semantic-ui-react";
import ActivityFilters from "../../components/ActivityFilters/ActivityFilters";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { State } from "../../reducers";
import { getActivities, updateActivitiesFilters, setActivitiesPage, resetActivitiesState } from "../../actions/activities.actions";
import { ActivitiesState, ActivityFiltersState } from "../../reducers/activities.reducer";
import { CommonUser } from "common";
import { fetchProfileInfo } from "../../actions/user.actions";
import NotFound from "../../pages/NotFound/NotFound";
import { WithTranslation, withTranslation } from 'react-i18next';

interface RouteProps {
    id: string;
}

interface ActivityListingProps extends RouteComponentProps<RouteProps>, WithTranslation
{
    activities: ActivitiesState;
    user: CommonUser;
    firstName: string;
    userNotFound: boolean;
    dispatch: Dispatch<void>;
}

const mapStateToProps = (state: State) => ({
    activities: state.activities,
    user: state.authentication.user,
    firstName: state.userProfile.firstName,
    userNotFound: state.userProfile.failed
});

class ActivityListing extends React.Component<ActivityListingProps> {
    readonly ACTIVITIES_PER_PAGE = 20;

    public componentDidMount() {
        this.fetchActivities(this.props.activities);
        this.props.dispatch(fetchProfileInfo(this.props.match.params.id));;
    }

    public componentWillUnmount() {
        this.props.dispatch(resetActivitiesState());
    }

    public handlePageChange = (event: React.SyntheticEvent<HTMLElement>, data: PaginationProps) => {
        this.changePage(data.activePage as number);
    }

    public updateFilters = (filters: ActivityFiltersState) => {
        this.changeFilters(filters);
    }

    public render() {
        const { activities, total } = this.props.activities;
        const isOwnProfile: boolean = Number(this.props.match.params.id) === this.props.user.id;
        return (
            <React.Fragment>
                {this.props.userNotFound ? (
                    <NotFound />
                ) : (
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Header as="h1">{isOwnProfile ? this.props.t('activityTable.ownActivitiesHeader') : this.props.t('activityTable.otherUserActivitiesHeader', {name: this.props.firstName})}</Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <ActivityFilters onSubmit={this.updateFilters} />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column>
                                <p>{this.props.t('profileStats.activityCount', {count: total})}</p>
                                <ActivityTable activities={activities} showActions={isOwnProfile} />
                            </Grid.Column>
                        </Grid.Row>
                        {this.renderPagination(total)}
                    </Grid>
                )}
            </React.Fragment>
        );
    }

    private renderPagination(count: number) {
        const pagination = (
            <Grid.Row centered={true}>
                <Pagination
                    totalPages={Math.ceil(count / this.ACTIVITIES_PER_PAGE)}
                    firstItem={false}
                    lastItem={false}
                    activePage={this.props.activities.page}
                    onPageChange={this.handlePageChange}
                />
            </Grid.Row>
        );
        return this.props.activities.total <= this.ACTIVITIES_PER_PAGE ? null : pagination;
    }

    private fetchActivities(activities: ActivitiesState) {
        const { filters, page } = activities;
        const query = { page, ...filters, activitiesPerPage: this.ACTIVITIES_PER_PAGE };
        this.props.dispatch(getActivities(this.props.match.params.id, query));
    }

    private changePage(page: number) {
        const sequence = (dispatch: Dispatch<void>, getState: any) => {
            this.props.dispatch(setActivitiesPage(page));
            this.fetchActivities(getState().activities);
        };

        this.props.dispatch(sequence);
    }

    private changeFilters(filters: ActivityFiltersState) {
        const sequence = (dispatch: Dispatch<void>, getState: any) => {
            this.props.dispatch(setActivitiesPage(1));
            this.props.dispatch(updateActivitiesFilters(filters));
            this.fetchActivities(getState().activities);
        };

        this.props.dispatch(sequence);
    }

}

export default withTranslation('profile')(connect(mapStateToProps)(ActivityListing));
