/* eslint-disable react/jsx-filename-extension */
import * as React from 'react';
import LoginPanel from '../../containers/LoginPanel/LoginPanel';
import './Login.css';


class Login extends React.Component {
  render() {
    return (
        <div className="ui two column centered grid login-page">
            <div className="column">
                <LoginPanel/>      
            </div>
            
        </div>
    );
  }
}

export default Login;
