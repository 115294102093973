import * as React from "react";
import { Modal, Button, Message } from "semantic-ui-react";

interface DeleteGymModalProps {
    open: boolean;
    loading?: boolean;
    error?: string;
    onCancel?: Function;
    onDelete?: Function;
}

class DeleteGymModal extends React.Component<DeleteGymModalProps> {
    public handleCancel = () => {
        if (this.props.onCancel) {
            this.props.onCancel();
        }
    }

    public handleDelete = () => {
        if (this.props.onDelete) {
            this.props.onDelete();
        }
    }

    public render() {
        return (
            <Modal open={this.props.open} size="mini">
                <Modal.Header>
                    Delete Gym
                </Modal.Header>
                <Modal.Content>
                    <p style={{textAlign: "justify"}}>
                        Are you sure you want to delete this gym? Be aware that this action is irreversible. 
                        Once deleted, you won't be able to access the gym page. If there is any systems left at the gym,
                        the adminitrators will be notified to reclaim them. 
                        The gym will still be list in your gyms with a "deleted" status until the systems are reclaimed.
                    </p>
                    <Message error={true} hidden={!Boolean(this.props.error)}>
                        <Message.Header>Error</Message.Header>
                        <Message.Content>{this.props.error}</Message.Content>
                    </Message>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="blue" onClick={this.handleCancel}>Cancel</Button>
                    <Button color="red"loading={this.props.loading} onClick={this.handleDelete}>Delete</Button>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default DeleteGymModal;