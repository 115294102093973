import * as React from "react";
import { Container, Form, InputOnChangeData } from "semantic-ui-react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { State } from "../../reducers";
import { CommonUser } from "common";
import { api } from "../../config/axiosConfig";
import { expect, validateForm } from "../../utils/Validator";
import { isEmpty } from "lodash";
import "./EditSettingsAccount.css";
import { withTranslation, WithTranslation } from "react-i18next";

interface EditSettingsAccountProps extends WithTranslation{
    user: CommonUser;
    dispatch: Dispatch<void>;
    endEditingCallback: () => void;
}

interface EditSettingsAccountState {
    requesting: boolean;
    errors: any;
    form: {
        currentPassword: string;
        newPassword: string;
        confirmPassword: string;
    };
}

const mapStateToProps = (state: State) => (
    { user: state.authentication.user }
);

class EditSettingsAccount extends React.Component<EditSettingsAccountProps, EditSettingsAccountState> {

    private validations = [
        expect("newPassword").required(),
        expect("confirmPassword").required()
    ];

    constructor(props: EditSettingsAccountProps) {
        super(props);
        this.state = {
            requesting: false,
            errors: {},
            form: {
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
            }
        };
    }

    public handleInputChange = (event: React.SyntheticEvent<HTMLInputElement>, data: InputOnChangeData) => {
        const { name, value } = event.target as HTMLInputElement;
        const form = { ...this.state.form, [name]: value };
        this.setState({ form });
    }

    public handleSubmit = () => {
        if (!this.state.requesting) {
            const errors = this.validateForm();
            this.setState({ errors });
            if (isEmpty(errors)) {
                this.resetPassword();
            }
        }
    }

    render() {
        const { currentPassword, newPassword, confirmPassword } = this.state.form;
        const errors = this.state.errors;
        return (
            <Container>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Input
                        label={this.props.t('account.subEmail')}
                        value={this.props.user.email}
                        disabled={true}
                    />
                    <Form.Field onChange={this.handleInputChange} error={"currentPassword" in errors}>
                        <label>{this.props.t('account.subCurrentPassword')}</label>
                        <input type="password" name="currentPassword" value={currentPassword} />
                        <label>{errors.currentPassword}</label>
                    </Form.Field>
                    <Form.Field onChange={this.handleInputChange} error={"newPassword" in errors}>
                        <label>{this.props.t('account.subNewPassword')}</label>
                        <input type="password" name="newPassword" value={newPassword} />
                        <label>{errors.newPassword}</label>
                    </Form.Field>
                    <Form.Field onChange={this.handleInputChange} error={"confirmPassword" in errors}>
                        <label>{this.props.t('account.subConNewPassword')}</label>
                        <input type="password" name="confirmPassword" value={confirmPassword} />
                        <label>{errors.confirmPassword}</label>
                    </Form.Field>
                    <Form.Group>
                        <Form.Button primary={true}>Submit</Form.Button>
                        <Form.Button onClick={this.props.endEditingCallback}>Cancel</Form.Button>
                    </Form.Group>
                </Form>
            </Container>
        );
    }

    private validateForm() {
        const errors = validateForm(this.state.form, this.validations);
        if (!this.hasSamePassword()) {
            errors.confirmPassword = "is not the same";
        }
        return errors;
    }

    private hasSamePassword(): boolean {
        const { newPassword, confirmPassword } = this.state.form;
        return newPassword === confirmPassword;
    }

    private resetPassword() {
        const { currentPassword, newPassword } = this.state.form;
        const requestURL = "/v1/auth/password/reset";
        this.setState({ requesting: true });
        api()
            .post(requestURL, { currentPassword, newPassword })
            .then(response => {
                this.setState({ requesting: false, errors: {} });
                this.props.endEditingCallback();
            })
            .catch(error => {
                const errors = error.response.data.errors ? error.response.data.errors : {};
                this.setState({ requesting: false, errors });
            });
    }
}

export default withTranslation('settings')(connect(mapStateToProps)(EditSettingsAccount));
