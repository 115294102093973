import * as React from "react";
import { State } from "../reducers/index";
import { connect } from "react-redux";
import { Route, Redirect, RouteProps } from "react-router";
import { CommonUser, CommonRole } from "common";
import { RoleEnum } from "common";
import { capitalize } from "lodash";

interface RestrictedRouteProps {
    user: CommonUser;
    routeProps: any;
}

interface OwnProps extends RouteProps {
    requiredRole: RoleEnum;
}

const mapStatetoProps = (state: State, ownProps: OwnProps) => {
    return {
        user: state.authentication.user,
        routeProps: ownProps
    };
};

class RestrictedRoute extends React.Component<RestrictedRouteProps, {}> {
    render() {
        const {component: Component, requiredRole, ...rest} = this.props.routeProps;
        return ( 
            <Route 
                {...rest} 
                render={(props: any) => {
                    return this.hasRole(requiredRole) ? <Component {...props}/> : <Redirect to="/"/>;
                }}
            />
        );
    }

    private hasRole(role: RoleEnum) {
        const key: string = this.getRoleKey(role);
        // @ts-ignore
        return this.props.user.role[key];
    }

    private getRoleKey(role: RoleEnum) {
        return `is${capitalize(role)}` as keyof CommonRole;
    }
}

export default connect(mapStatetoProps)(RestrictedRoute);