import * as React from "react";
import SettingRow from "../../components/SettingRow/SettingRow";
import { Container, Form, ButtonProps, Header, Segment, Dropdown } from "semantic-ui-react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { State } from "../../reducers";
import { CommonUser, Privacy, CommonPrivacySettings } from "common";
import EditableSection from "../../components/EditableSection/EditableSection";
import { modifyPrivacySettings } from "../../actions/settings.actions";
import { WithTranslation, withTranslation } from "react-i18next";

interface SettingsPrivacyProps extends WithTranslation {
    user: CommonUser;
    dispatch: Dispatch<void>;
}
interface SettingsPrivacyState {
    editingPrivacySettings: boolean;
    privacySettings: CommonPrivacySettings;
}

const mapStateToProps = (state: State) => (
    { user: state.authentication.user }
);

class SettingsPrivacy extends React.Component<SettingsPrivacyProps, SettingsPrivacyState> {

    constructor(props: any) {
        super(props);
        this.state = { 
            editingPrivacySettings: false, 
            privacySettings: this.props.user.privacySettings 
        };
    }

    render() {
        const options: { value: Privacy, text: string }[] = [
            { value: "private", text: this.props.t('privacy.public') },
            { value: "public", text: this.props.t('privacy.private') },
        ];


        let privacy = options.find(option => option.value === this.props.user.privacySettings.privacy);
        let privacyText = "";
        if (privacy !== undefined) {
            privacyText = privacy.text;
        }

        const editingPrivacySettings = this.state.editingPrivacySettings;

        return (
            <Segment>
                <EditableSection editing={editingPrivacySettings} onClick={this.onClickCallback} iconOffset={{right: 0, top: 8}}>
                    <EditableSection.Content>
                        <Container>
                            <Header as="h1">{this.props.t('privacy.header')}</Header>
                            <SettingRow title={this.props.t('privacy.header')} content={privacyText} />
                        </Container>
                    </EditableSection.Content>
                    <EditableSection.Form>
                        <Container>
                            <Form>
                                <Header as="h1">{this.props.t('privacy.header')}</Header>
                                <Form.Field>
                                    <Dropdown
                                        placeholder={this.props.t('privacy.header')}
                                        selection={true}
                                        options={options}
                                        onChange={this.handlePrivacyChange}
                                        value={this.state.privacySettings.privacy}
                                    />
                                </Form.Field>
                                <Form.Group >
                                    <Form.Button primary={true} onClick={this.handleSubmitButton}>{this.props.t('common:btn.save')}</Form.Button>
                                    <Form.Button onClick={this.handleCancelButton}>{this.props.t('common:btn.cancel')}</Form.Button>
                                </Form.Group>
                            </Form>
                        </Container>
                    </EditableSection.Form>
                </EditableSection >
            </Segment >
        );
    }
    public handlePrivacyChange = (event: any, data: any) => {
        console.log(data.value);
        const privacySettings = { ...this.state.privacySettings, privacy: (data.value as Privacy) };
        this.setState({ privacySettings: privacySettings });    
    }

    onClickCallback = (e: Event) => {
        this.setState({ editingPrivacySettings: !this.state.editingPrivacySettings });
    }

    handleSubmitButton = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        this.props.dispatch(modifyPrivacySettings(this.state.privacySettings));
        this.setState({ ...this.state, editingPrivacySettings: false });
    }

    handleCancelButton = (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => {
        this.setState({ ...this.state, editingPrivacySettings: false });
    }
}

export default withTranslation('settings')(connect(mapStateToProps)(SettingsPrivacy));
