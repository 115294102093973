import * as React from "react";
import { Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router";
import { withTranslation, WithTranslation } from "react-i18next";

interface SettingsMenuProps extends RouteComponentProps<void>, WithTranslation{}

class SettingsMenu extends React.Component<SettingsMenuProps , {}> {

    render() {
        const match = this.props.match;
        const path = this.props.location.pathname;

        return (
            <Menu color="orange" vertical={true} >
                <Menu.Item
                    as={Link}
                    to={`${match.url}/profile`}
                    name="profile"
                    link={true}
                    active={path.includes("profile")}
                >
                    {this.props.t('profile.title')}
                </Menu.Item>
                <Menu.Item
                    as={Link}
                    to={`${match.url}/account`}
                    name="account"
                    link={true}
                    active={path.includes("account")}
                >
                    {this.props.t('account.title')}
                </Menu.Item>
                <Menu.Item
                    as={Link}
                    to={`${match.url}/display`}
                    name="display"
                    link={true}
                    active={path.includes("display")}
                >
                    {this.props.t('display.title')}
                </Menu.Item>
                <Menu.Item
                    as={Link}
                    to={`${match.url}/privacy`}
                    name="privacy"
                    link={true}
                    active={path.includes("privacy")}
                >
                    {this.props.t('privacy.title')}
                </Menu.Item>
            </Menu>
        );
    }
}

export default withTranslation('settings')(withRouter(SettingsMenu));