import * as React from "react";
import { Scatter } from "react-chartjs-2";
import { CommonAcquisition } from "common";
import { ChartOptions } from "chart.js";
import { Container } from "semantic-ui-react";

interface LiveGraphProps {
    chartData: any;
    maxTime: any;
    maxValue: any;
}

interface LiveGraphState {
    redirect: boolean;
    chartData?: any;
}

class LiveGraph extends React.PureComponent<LiveGraphProps, LiveGraphState> {
    constructor(props: LiveGraphProps) {
        super(props);
        this.state = { redirect: false };

    }

    render() {
        const chartData = this.generateChartData();
        const chartOptions = this.generateChartOptions();

        return (
            <Container style={{position: "fixed", left:0,top:"0%", width:"100%", height:"100%"}}>
            <Container style={{position: "absolute",height:"100%",width:"100%",margin:"0 0"}}>
                <Scatter data={chartData} options={chartOptions}/>    
            </Container>
            </Container>
        );
    }

    private generateChartData() {
        return {
            labels: ["Scatter"],
            datasets: [{
                label: "Effort",
                fill: false,
                showLine: true,
                backgroundColor: "rgba(192,89,54,1)",
                pointBorderColor: "rgba(192,89,54,1)",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 0,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "rgba(192,89,54,1)",
                pointHoverBorderColor: "rgba(220,220,220,1)",
                pointHoverBorderWidth: 0,
                pointRadius: 0,
                pointHitRadius: 9,
                borderColor: "rgba(192,89,54,1)",
                data:  this.props.chartData ? this.props.chartData : [],
                lineTension: 0,
                spanGaps: false
            }]
        };
    }

    private generateChartOptions(): ChartOptions {
        return {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
                display: false,
                labels: {
                    fontColor: "rgba(255, 255, 255,0.2)"
                }
            },
            elements: {
                line: {
                    tension: 0 // disables bezier curves
                }
            },
            scales: {
                display: true,
                yAxes: [{
                    scaleLabel: {
                        display: false,
                        labelString: "Force",
                        fontColor: "rgba(255, 255, 255,0.95)"
                    },
                    gridLines: {
                        display: false,
                        color: "rgba(255, 255, 255,0.1)"
                    },
                    ticks: {
                        display: true,
                        autoSkip: true,
                        min: 0,
                        max: this.props.maxValue,
                        fontColor: "rgba(255, 255, 255,0.6)"
                  }
                }],
                xAxes: [{ 
                    position: "bottom",
                    scaleLabel: {
                        display: false,
                        labelString: "seconds",
                        fontColor: "rgba(255, 255, 255,0.95)",
                    },
                    gridLines: {
                        display: false,
                        color: "rgba(255, 255, 255,0.1)"
                    },
                    ticks: {
                        autoSkip: true,
                        maxRotation: 0,
                        display: true,
                        min: this.props.maxTime > 10 ? this.props.maxTime - 10  : 0,
                        max: this.props.maxTime > 10 ? this.props.maxTime  : 10,
                        fontColor: "rgba(255, 255, 255,0.6)"
                  }
                }],
            },
            animation: {
                duration: 0
            },
            hover: {
                animationDuration: 0
            },
            responsiveAnimationDuration: 0,
            spanGaps: false,
            tooltips: {
                callbacks: {
// tslint:disable-next-line: typedef
                    label: function(tooltipItem) {
                        return Number(tooltipItem.yLabel) + " kg";
                    }
                },
                backgroundColor: "#fff",
                titleFontSize: 16,
                titleFontColor: "#0066ff",
                bodyFontColor: "#000",
                bodyFontSize: 14,
                displayColors: false
              }
        };
    }
}

export default LiveGraph;
