import { AxiosError, AxiosResponse } from "axios";
import * as HttpStatus from "http-status-codes";

class AxiosUtils {
    public static getErrors(error: AxiosError) {
        let errors: any = {};
        if (error && error.response && error.response.data && error.response.data.errors) {
            errors = error.response.data.errors;
        }
        return errors;
    }

    public static getStatus(response?: AxiosResponse) {
        return response ? response.status : HttpStatus.INTERNAL_SERVER_ERROR;
    }
}

export default AxiosUtils;